/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
export type Response<T> =
  | {
      data: T;
      hasErrors: false;
      errors: Error[];
    }
  | {
      data: T | null;
      hasErrors: true;
      errors: Error[];
    };

export interface Error {
  propertyName: string;
  errorMessage: string;
}

export interface AdvancedQueryPage<T> {
  items: T[];
  page: number;
  pageSize: number;
  pageCount: number;
  totalItemCount: number;
}

export class AcceptedCardBrands {
  /**  */
  'amex': boolean;

  /**  */
  'discover': boolean;

  /**  */
  'mastercard': boolean;

  /**  */
  'visa': boolean;

  constructor(data: undefined | any = {}) {
    this['amex'] = data['amex'];
    this['discover'] = data['discover'];
    this['mastercard'] = data['mastercard'];
    this['visa'] = data['visa'];
  }
}

export class AccountAutopaySettingsDto {
  /**  */
  'id': number;

  /**  */
  'createdByUserId': number;

  /**  */
  'accountId': number;

  /**  */
  'enrollmentDate': Date;

  /**  */
  'notificationEmailAddress': string;

  /**  */
  'enrollmentType': string;

  /**  */
  'schedulingType': string;

  /**  */
  'paymentSourceId': number;

  /**  */
  'maximumBalanceAllowed': number;

  /**  */
  'customerId': number;

  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'processingDayOfMonth': number;

  /**  */
  'cancellationDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['createdByUserId'] = data['createdByUserId'];
    this['accountId'] = data['accountId'];
    this['enrollmentDate'] = data['enrollmentDate'];
    this['notificationEmailAddress'] = data['notificationEmailAddress'];
    this['enrollmentType'] = data['enrollmentType'];
    this['schedulingType'] = data['schedulingType'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['customerId'] = data['customerId'];
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['processingDayOfMonth'] = data['processingDayOfMonth'];
    this['cancellationDate'] = data['cancellationDate'];
  }
}

export class AccountAutopayUpdateEnrollmentRequest {
  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'emailAddress': string;

  /**  */
  'paymentSourceId': number;

  /**  */
  'maximumBalanceAllowed': number;

  /**  */
  'termsAcknowledged': boolean;

  /**  */
  'isEmbedded': boolean;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['emailAddress'] = data['emailAddress'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['termsAcknowledged'] = data['termsAcknowledged'];
    this['isEmbedded'] = data['isEmbedded'];
  }
}

export class AccountCharge {
  /**  */
  'accountId': number;

  /**  */
  'paymentAmount': number;

  /**  */
  'description': string;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['paymentAmount'] = data['paymentAmount'];
    this['description'] = data['description'];
  }
}

export class AccountConfig {
  /**  */
  'enabled': boolean;

  /**  */
  'freeFormPaymentEnabled': boolean;

  /**  */
  'minimumBalanceRequired': boolean;

  /**  */
  'multipleAccountPaymentEnabled': boolean;

  /**  */
  'multipleAccountQuickpayEnabled': boolean;

  /**  */
  'multipleAccountPosEnabled': boolean;

  /**  */
  'accountFields': AccountFieldGetDto[];

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['freeFormPaymentEnabled'] = data['freeFormPaymentEnabled'];
    this['minimumBalanceRequired'] = data['minimumBalanceRequired'];
    this['multipleAccountPaymentEnabled'] = data['multipleAccountPaymentEnabled'];
    this['multipleAccountQuickpayEnabled'] = data['multipleAccountQuickpayEnabled'];
    this['multipleAccountPosEnabled'] = data['multipleAccountPosEnabled'];
    this['accountFields'] = data['accountFields'];
  }
}

export class AccountDetailCustomerDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'lateFee': number;

  /**  */
  'pastDueAmountMinor': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'note': string;

  /**  */
  'meta': string;

  /**  */
  'createMethod': string;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'blockCreditCardPayment': boolean;

  /**  */
  'blockAchPayment': boolean;

  /**  */
  'paperlessBilling': boolean;

  /**  */
  'id': number;

  /**  */
  'autopaySettingsId': number;

  /**  */
  'isArchived': boolean;

  /**  */
  'accountSyncDate': Date;

  /**  */
  'blockedByAllPaymentTypes': boolean;

  /**  */
  'accountLocked': boolean;

  /**  */
  'addressStreetAddress': string;

  /**  */
  'businessUnitName': string;

  /**  */
  'businessUnitSlug': string;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'modifiedDate': Date;

  /**  */
  'autopayEnrollmentType': string;

  /**  */
  'autopayCustomerId': number;

  /**  */
  'latestBillId': number;

  /**  */
  'lastPaymentDate': Date;

  /**  */
  'hasAutopayActivity': boolean;

  /**  */
  'hasPaperlessBillingActivity': boolean;

  /**  */
  'hasReadings': boolean;

  /**  */
  'businessUnitCurrencyCode': string;

  /**  */
  'hasBillHistory': boolean;

  /**  */
  'isRollover': boolean;

  /**  */
  'pastDue': boolean;

  /**  */
  'lateFeeApplicable': boolean;

  /**  */
  'totalOutstandingBalance': number;

  /**  */
  'enrolledInAutopay': boolean;

  /**  */
  'customerAccounts': CustomerAccountGetDto[];

  /**  */
  'upcomingPayments': ScheduledPaymentCustomerSummaryDto[];

  /**  */
  'emailAddress': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['pastDueAmountMinor'] = data['pastDueAmountMinor'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['phoneNumber'] = data['phoneNumber'];
    this['address'] = data['address'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['note'] = data['note'];
    this['meta'] = data['meta'];
    this['createMethod'] = data['createMethod'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['blockCreditCardPayment'] = data['blockCreditCardPayment'];
    this['blockAchPayment'] = data['blockAchPayment'];
    this['paperlessBilling'] = data['paperlessBilling'];
    this['id'] = data['id'];
    this['autopaySettingsId'] = data['autopaySettingsId'];
    this['isArchived'] = data['isArchived'];
    this['accountSyncDate'] = data['accountSyncDate'];
    this['blockedByAllPaymentTypes'] = data['blockedByAllPaymentTypes'];
    this['accountLocked'] = data['accountLocked'];
    this['addressStreetAddress'] = data['addressStreetAddress'];
    this['businessUnitName'] = data['businessUnitName'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['modifiedDate'] = data['modifiedDate'];
    this['autopayEnrollmentType'] = data['autopayEnrollmentType'];
    this['autopayCustomerId'] = data['autopayCustomerId'];
    this['latestBillId'] = data['latestBillId'];
    this['lastPaymentDate'] = data['lastPaymentDate'];
    this['hasAutopayActivity'] = data['hasAutopayActivity'];
    this['hasPaperlessBillingActivity'] = data['hasPaperlessBillingActivity'];
    this['hasReadings'] = data['hasReadings'];
    this['businessUnitCurrencyCode'] = data['businessUnitCurrencyCode'];
    this['hasBillHistory'] = data['hasBillHistory'];
    this['isRollover'] = data['isRollover'];
    this['pastDue'] = data['pastDue'];
    this['lateFeeApplicable'] = data['lateFeeApplicable'];
    this['totalOutstandingBalance'] = data['totalOutstandingBalance'];
    this['enrolledInAutopay'] = data['enrolledInAutopay'];
    this['customerAccounts'] = data['customerAccounts'];
    this['upcomingPayments'] = data['upcomingPayments'];
    this['emailAddress'] = data['emailAddress'];
  }
}

export class AccountDetailDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'lateFee': number;

  /**  */
  'pastDueAmountMinor': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'emailAddress': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'note': string;

  /**  */
  'meta': string;

  /**  */
  'createMethod': string;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'blockCreditCardPayment': boolean;

  /**  */
  'blockAchPayment': boolean;

  /**  */
  'paperlessBilling': boolean;

  /**  */
  'id': number;

  /**  */
  'autopaySettingsId': number;

  /**  */
  'isArchived': boolean;

  /**  */
  'accountSyncDate': Date;

  /**  */
  'blockedByAllPaymentTypes': boolean;

  /**  */
  'accountLocked': boolean;

  /**  */
  'addressStreetAddress': string;

  /**  */
  'businessUnitName': string;

  /**  */
  'businessUnitSlug': string;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'modifiedDate': Date;

  /**  */
  'autopayEnrollmentType': string;

  /**  */
  'autopayCustomerId': number;

  /**  */
  'latestBillId': number;

  /**  */
  'lastPaymentDate': Date;

  /**  */
  'hasAutopayActivity': boolean;

  /**  */
  'hasPaperlessBillingActivity': boolean;

  /**  */
  'hasReadings': boolean;

  /**  */
  'businessUnitCurrencyCode': string;

  /**  */
  'hasBillHistory': boolean;

  /**  */
  'isRollover': boolean;

  /**  */
  'pastDue': boolean;

  /**  */
  'lateFeeApplicable': boolean;

  /**  */
  'totalOutstandingBalance': number;

  /**  */
  'enrolledInAutopay': boolean;

  /**  */
  'customerAccounts': CustomerAccountGetDto[];

  /**  */
  'upcomingPayments': ScheduledPaymentCustomerSummaryDto[];

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['pastDueAmountMinor'] = data['pastDueAmountMinor'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['phoneNumber'] = data['phoneNumber'];
    this['emailAddress'] = data['emailAddress'];
    this['address'] = data['address'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['note'] = data['note'];
    this['meta'] = data['meta'];
    this['createMethod'] = data['createMethod'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['blockCreditCardPayment'] = data['blockCreditCardPayment'];
    this['blockAchPayment'] = data['blockAchPayment'];
    this['paperlessBilling'] = data['paperlessBilling'];
    this['id'] = data['id'];
    this['autopaySettingsId'] = data['autopaySettingsId'];
    this['isArchived'] = data['isArchived'];
    this['accountSyncDate'] = data['accountSyncDate'];
    this['blockedByAllPaymentTypes'] = data['blockedByAllPaymentTypes'];
    this['accountLocked'] = data['accountLocked'];
    this['addressStreetAddress'] = data['addressStreetAddress'];
    this['businessUnitName'] = data['businessUnitName'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['modifiedDate'] = data['modifiedDate'];
    this['autopayEnrollmentType'] = data['autopayEnrollmentType'];
    this['autopayCustomerId'] = data['autopayCustomerId'];
    this['latestBillId'] = data['latestBillId'];
    this['lastPaymentDate'] = data['lastPaymentDate'];
    this['hasAutopayActivity'] = data['hasAutopayActivity'];
    this['hasPaperlessBillingActivity'] = data['hasPaperlessBillingActivity'];
    this['hasReadings'] = data['hasReadings'];
    this['businessUnitCurrencyCode'] = data['businessUnitCurrencyCode'];
    this['hasBillHistory'] = data['hasBillHistory'];
    this['isRollover'] = data['isRollover'];
    this['pastDue'] = data['pastDue'];
    this['lateFeeApplicable'] = data['lateFeeApplicable'];
    this['totalOutstandingBalance'] = data['totalOutstandingBalance'];
    this['enrolledInAutopay'] = data['enrolledInAutopay'];
    this['customerAccounts'] = data['customerAccounts'];
    this['upcomingPayments'] = data['upcomingPayments'];
  }
}

export class AccountFieldGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'type': string;

  /**  */
  'label': string;

  /**  */
  'description': string;

  /**  */
  'isQuickPayLookupField': boolean;

  /**  */
  'isIvrLookupField': boolean;

  /**  */
  'isDisplayField': boolean;

  /**  */
  'isRequired': boolean;

  /**  */
  'order': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['type'] = data['type'];
    this['label'] = data['label'];
    this['description'] = data['description'];
    this['isQuickPayLookupField'] = data['isQuickPayLookupField'];
    this['isIvrLookupField'] = data['isIvrLookupField'];
    this['isDisplayField'] = data['isDisplayField'];
    this['isRequired'] = data['isRequired'];
    this['order'] = data['order'];
    this['id'] = data['id'];
  }
}

export class AccountFieldUpdateDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'type': string;

  /**  */
  'label': string;

  /**  */
  'description': string;

  /**  */
  'isQuickPayLookupField': boolean;

  /**  */
  'isIvrLookupField': boolean;

  /**  */
  'isDisplayField': boolean;

  /**  */
  'isRequired': boolean;

  /**  */
  'order': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['type'] = data['type'];
    this['label'] = data['label'];
    this['description'] = data['description'];
    this['isQuickPayLookupField'] = data['isQuickPayLookupField'];
    this['isIvrLookupField'] = data['isIvrLookupField'];
    this['isDisplayField'] = data['isDisplayField'];
    this['isRequired'] = data['isRequired'];
    this['order'] = data['order'];
    this['id'] = data['id'];
  }
}

export class AccountFileUploadedEventPayload {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountFileUpload_DueDateOverride': Date;

  /**  */
  'accountFileUpload_IssueDateOverride': Date;

  /**  */
  'accountFileUpload_BlobUrl': string;

  /**  */
  'accountFileUpload_DownloadBlobUrl': string;

  /**  */
  'accountFileName': string;

  /**  */
  'userEmail': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountFileUpload_DueDateOverride'] = data['accountFileUpload_DueDateOverride'];
    this['accountFileUpload_IssueDateOverride'] = data['accountFileUpload_IssueDateOverride'];
    this['accountFileUpload_BlobUrl'] = data['accountFileUpload_BlobUrl'];
    this['accountFileUpload_DownloadBlobUrl'] = data['accountFileUpload_DownloadBlobUrl'];
    this['accountFileName'] = data['accountFileName'];
    this['userEmail'] = data['userEmail'];
  }
}

export class AccountGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'lateFee': number;

  /**  */
  'pastDueAmountMinor': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'emailAddress': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'note': string;

  /**  */
  'meta': string;

  /**  */
  'createMethod': string;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'blockCreditCardPayment': boolean;

  /**  */
  'blockAchPayment': boolean;

  /**  */
  'paperlessBilling': boolean;

  /**  */
  'id': number;

  /**  */
  'autopaySettingsId': number;

  /**  */
  'isArchived': boolean;

  /**  */
  'accountSyncDate': Date;

  /**  */
  'blockedByAllPaymentTypes': boolean;

  /**  */
  'accountLocked': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['pastDueAmountMinor'] = data['pastDueAmountMinor'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['phoneNumber'] = data['phoneNumber'];
    this['emailAddress'] = data['emailAddress'];
    this['address'] = data['address'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['note'] = data['note'];
    this['meta'] = data['meta'];
    this['createMethod'] = data['createMethod'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['blockCreditCardPayment'] = data['blockCreditCardPayment'];
    this['blockAchPayment'] = data['blockAchPayment'];
    this['paperlessBilling'] = data['paperlessBilling'];
    this['id'] = data['id'];
    this['autopaySettingsId'] = data['autopaySettingsId'];
    this['isArchived'] = data['isArchived'];
    this['accountSyncDate'] = data['accountSyncDate'];
    this['blockedByAllPaymentTypes'] = data['blockedByAllPaymentTypes'];
    this['accountLocked'] = data['accountLocked'];
  }
}

export class AccountImportErrorsFoundEventPayload {
  /**  */
  'errorCount': number;

  /**  */
  'errorListDownloadUrl': string;

  /**  */
  'businessUnitHeader': string;

  /**  */
  'businessUnitSubHeader': string;

  /**  */
  'uploaderEmail': string;

  /**  */
  'fileName': string;

  constructor(data: undefined | any = {}) {
    this['errorCount'] = data['errorCount'];
    this['errorListDownloadUrl'] = data['errorListDownloadUrl'];
    this['businessUnitHeader'] = data['businessUnitHeader'];
    this['businessUnitSubHeader'] = data['businessUnitSubHeader'];
    this['uploaderEmail'] = data['uploaderEmail'];
    this['fileName'] = data['fileName'];
  }
}

export class AccountImportStagedEventPayload {
  /**  */
  'totalStagedAccounts': number;

  /**  */
  'totalSkippedAccounts': number;

  constructor(data: undefined | any = {}) {
    this['totalStagedAccounts'] = data['totalStagedAccounts'];
    this['totalSkippedAccounts'] = data['totalSkippedAccounts'];
  }
}

export class AccountImportSyncEventPayload {
  /**  */
  'totalStagedAccounts': number;

  /**  */
  'totalExistingAccounts': number;

  /**  */
  'stagedNewAccounts': number;

  /**  */
  'stagedMatchedAccounts': number;

  /**  */
  'existingAccountsNotStaged': number;

  /**  */
  'aggregateTotal': number;

  /**  */
  'stagedArchivedAccounts': number;

  /**  */
  'archivedAccountsNotStaged': number;

  /**  */
  'cancelledScheduledPayments': number;

  /**  */
  'description': string;

  constructor(data: undefined | any = {}) {
    this['totalStagedAccounts'] = data['totalStagedAccounts'];
    this['totalExistingAccounts'] = data['totalExistingAccounts'];
    this['stagedNewAccounts'] = data['stagedNewAccounts'];
    this['stagedMatchedAccounts'] = data['stagedMatchedAccounts'];
    this['existingAccountsNotStaged'] = data['existingAccountsNotStaged'];
    this['aggregateTotal'] = data['aggregateTotal'];
    this['stagedArchivedAccounts'] = data['stagedArchivedAccounts'];
    this['archivedAccountsNotStaged'] = data['archivedAccountsNotStaged'];
    this['cancelledScheduledPayments'] = data['cancelledScheduledPayments'];
    this['description'] = data['description'];
  }
}

export class AccountMergeSearchDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'createMethod': string;

  /**  */
  'isArchived': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['createMethod'] = data['createMethod'];
    this['isArchived'] = data['isArchived'];
  }
}

export class AccountOnboardingDto {
  /**  */
  'id': number;

  /**  */
  'isArchived': boolean;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'accountSyncDate': Date;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'emailAddress': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountLocked': boolean;

  /**  */
  'blockCreditCardPayment': boolean;

  /**  */
  'blockAchPayment': boolean;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'isRollover': boolean;

  /**  */
  'pastDueAmountMinor': number;

  /**  */
  'paperlessBilling': boolean;

  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'maximumBalanceAllowed': number;

  /**  */
  'autopayPaymentSource': PaymentSourceSummaryDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['isArchived'] = data['isArchived'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['accountSyncDate'] = data['accountSyncDate'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['phoneNumber'] = data['phoneNumber'];
    this['emailAddress'] = data['emailAddress'];
    this['address'] = data['address'];
    this['accountLocked'] = data['accountLocked'];
    this['blockCreditCardPayment'] = data['blockCreditCardPayment'];
    this['blockAchPayment'] = data['blockAchPayment'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['isRollover'] = data['isRollover'];
    this['pastDueAmountMinor'] = data['pastDueAmountMinor'];
    this['paperlessBilling'] = data['paperlessBilling'];
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['autopayPaymentSource'] = data['autopayPaymentSource'];
  }
}

export class AccountPaperlessBillingStatusResponse {
  /**  */
  'isEnrolled': boolean;

  /**  */
  'hasPreviousActivity': boolean;

  /**  */
  'emailAddress': string;

  constructor(data: undefined | any = {}) {
    this['isEnrolled'] = data['isEnrolled'];
    this['hasPreviousActivity'] = data['hasPreviousActivity'];
    this['emailAddress'] = data['emailAddress'];
  }
}

export class AccountPaymentProcessedEventPayload {
  /**  */
  'sessionIdentifier': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'businessUnitName': string;

  /**  */
  'accountId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'accountPaymentAmountMinor': number;

  /**  */
  'accountPaymentAmount': string;

  /**  */
  'accountPaymentItems': number;

  /**  */
  'dueDate': Date;

  /**  */
  'gatewayErrorMessage': string;

  /**  */
  'accountCreateMethod': string;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'serviceFeeAmountMinor': number;

  /**  */
  'currencyCode': string;

  /**  */
  'amount': number;

  /**  */
  'amountFormatted': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'paymentCreatedDateUtc': Date;

  /**  */
  'channel': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'status': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'accountNumberLast4': string;

  /**  */
  'paymentSourceAccountType': string;

  constructor(data: undefined | any = {}) {
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['businessUnitId'] = data['businessUnitId'];
    this['businessUnitName'] = data['businessUnitName'];
    this['accountId'] = data['accountId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['accountPaymentAmountMinor'] = data['accountPaymentAmountMinor'];
    this['accountPaymentAmount'] = data['accountPaymentAmount'];
    this['accountPaymentItems'] = data['accountPaymentItems'];
    this['dueDate'] = data['dueDate'];
    this['gatewayErrorMessage'] = data['gatewayErrorMessage'];
    this['accountCreateMethod'] = data['accountCreateMethod'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['serviceFeeAmountMinor'] = data['serviceFeeAmountMinor'];
    this['currencyCode'] = data['currencyCode'];
    this['amount'] = data['amount'];
    this['amountFormatted'] = data['amountFormatted'];
    this['createdByUserName'] = data['createdByUserName'];
    this['paymentCreatedDateUtc'] = data['paymentCreatedDateUtc'];
    this['channel'] = data['channel'];
    this['referenceNumber'] = data['referenceNumber'];
    this['status'] = data['status'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
    this['paymentSourceAccountType'] = data['paymentSourceAccountType'];
  }
}

export class AccountPaymentsDto {
  /**  */
  'accountId': number;

  /**  */
  'paymentMetaJson': string;

  /**  */
  'paymentId': number;

  /**  */
  'lineItemCount': number;

  /**  */
  'accountPaymentAmountMinor': number;

  /**  */
  'accountName': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'dueDate': Date;

  /**  */
  'sessionIdentifier': string;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'currencyCode': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'paymentStatus': string;

  /**  */
  'channel': string;

  /**  */
  'paymentCreatedDateUtc': Date;

  /**  */
  'paymentCreatedDateLocal': string;

  /**  */
  'paymentType': string;

  /**  */
  'serviceFeeAmountMinor': number;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'paymentSourceAccountType': string;

  /**  */
  'accountNumberLast4': string;

  /**  */
  'businessUnitName': string;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'fullName': string;

  /**  */
  'createdByUserName': string;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['paymentMetaJson'] = data['paymentMetaJson'];
    this['paymentId'] = data['paymentId'];
    this['lineItemCount'] = data['lineItemCount'];
    this['accountPaymentAmountMinor'] = data['accountPaymentAmountMinor'];
    this['accountName'] = data['accountName'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['dueDate'] = data['dueDate'];
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['currencyCode'] = data['currencyCode'];
    this['businessUnitId'] = data['businessUnitId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['paymentStatus'] = data['paymentStatus'];
    this['channel'] = data['channel'];
    this['paymentCreatedDateUtc'] = data['paymentCreatedDateUtc'];
    this['paymentCreatedDateLocal'] = data['paymentCreatedDateLocal'];
    this['paymentType'] = data['paymentType'];
    this['serviceFeeAmountMinor'] = data['serviceFeeAmountMinor'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['paymentSourceAccountType'] = data['paymentSourceAccountType'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
    this['businessUnitName'] = data['businessUnitName'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['fullName'] = data['fullName'];
    this['createdByUserName'] = data['createdByUserName'];
  }
}

export class AccountSearchDto {
  /**  */
  'id': string;

  /**  */
  'name': string;

  /**  */
  'isArchived': boolean;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'dueDate': string;

  /**  */
  'address_StreetAddress': string;

  /**  */
  'address_StreetAddress2': string;

  /**  */
  'address_City': string;

  /**  */
  'address_State': string;

  /**  */
  'address_ZipCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['isArchived'] = data['isArchived'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['dueDate'] = data['dueDate'];
    this['address_StreetAddress'] = data['address_StreetAddress'];
    this['address_StreetAddress2'] = data['address_StreetAddress2'];
    this['address_City'] = data['address_City'];
    this['address_State'] = data['address_State'];
    this['address_ZipCode'] = data['address_ZipCode'];
  }
}

export class AccountStateDto {
  /**  */
  'account': AccountDetailDto;

  /**  */
  'customerId': number;

  /**  */
  'autopaySettings': AccountAutopaySettingsDto;

  /**  */
  'paymentSources': PaymentSourceSummaryDto[];

  /**  */
  'feeTiers': ServiceFeeTierGetDto[];

  constructor(data: undefined | any = {}) {
    this['account'] = data['account'];
    this['customerId'] = data['customerId'];
    this['autopaySettings'] = data['autopaySettings'];
    this['paymentSources'] = data['paymentSources'];
    this['feeTiers'] = data['feeTiers'];
  }
}

export class AccountSummaryDto {
  /**  */
  'id': number;

  /**  */
  'isArchived': boolean;

  /**  */
  'businessUnitId': number;

  /**  */
  'businessUnitName': string;

  /**  */
  'businessUnitCurrencyCode': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'lateFee': number;

  /**  */
  'totalOutstandingBalance': number;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'accountSyncDate': Date;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'addressStreetAddress': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountLocked': boolean;

  /**  */
  'blockCreditCardPayment': boolean;

  /**  */
  'blockAchPayment': boolean;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'pastDue': boolean;

  /**  */
  'lateFeeApplicable': boolean;

  /**  */
  'isRollover': boolean;

  /**  */
  'pastDueAmountMinor': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['isArchived'] = data['isArchived'];
    this['businessUnitId'] = data['businessUnitId'];
    this['businessUnitName'] = data['businessUnitName'];
    this['businessUnitCurrencyCode'] = data['businessUnitCurrencyCode'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['totalOutstandingBalance'] = data['totalOutstandingBalance'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['accountSyncDate'] = data['accountSyncDate'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['phoneNumber'] = data['phoneNumber'];
    this['addressStreetAddress'] = data['addressStreetAddress'];
    this['address'] = data['address'];
    this['accountLocked'] = data['accountLocked'];
    this['blockCreditCardPayment'] = data['blockCreditCardPayment'];
    this['blockAchPayment'] = data['blockAchPayment'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['pastDue'] = data['pastDue'];
    this['lateFeeApplicable'] = data['lateFeeApplicable'];
    this['isRollover'] = data['isRollover'];
    this['pastDueAmountMinor'] = data['pastDueAmountMinor'];
  }
}

export class AccountSyncLogSummaryDto {
  /**  */
  'id': number;

  /**  */
  'type': string;

  /**  */
  'description': string;

  /**  */
  'metaJson': string;

  /**  */
  'eventDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['type'] = data['type'];
    this['description'] = data['description'];
    this['metaJson'] = data['metaJson'];
    this['eventDate'] = data['eventDate'];
  }
}

export class AccountSyncPreview {
  /**  */
  'totalStagedAccounts': number;

  /**  */
  'totalExistingAccounts': number;

  /**  */
  'stagedNewAccounts': number;

  /**  */
  'stagedMatchedAccounts': number;

  /**  */
  'existingAccountsNotStaged': number;

  /**  */
  'aggregateTotal': number;

  /**  */
  'stagedArchivedAccounts': number;

  /**  */
  'archivedAccountsNotStaged': number;

  /**  */
  'cancelledScheduledPayments': number;

  constructor(data: undefined | any = {}) {
    this['totalStagedAccounts'] = data['totalStagedAccounts'];
    this['totalExistingAccounts'] = data['totalExistingAccounts'];
    this['stagedNewAccounts'] = data['stagedNewAccounts'];
    this['stagedMatchedAccounts'] = data['stagedMatchedAccounts'];
    this['existingAccountsNotStaged'] = data['existingAccountsNotStaged'];
    this['aggregateTotal'] = data['aggregateTotal'];
    this['stagedArchivedAccounts'] = data['stagedArchivedAccounts'];
    this['archivedAccountsNotStaged'] = data['archivedAccountsNotStaged'];
    this['cancelledScheduledPayments'] = data['cancelledScheduledPayments'];
  }
}

export class AchConfig {
  /**  */
  'enabled': boolean;

  /**  */
  'paymentMerchantAccountId': number;

  /**  */
  'feeMerchantAccountId': number;

  /**  */
  'gatewayType': string;

  /**  */
  'tokenizer': TokenizerConfig;

  /**  */
  'plaidAccountLinkEnabled': boolean;

  /**  */
  'plaidPayerAccountLinkRequiredAch': boolean;

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['paymentMerchantAccountId'] = data['paymentMerchantAccountId'];
    this['feeMerchantAccountId'] = data['feeMerchantAccountId'];
    this['gatewayType'] = data['gatewayType'];
    this['tokenizer'] = data['tokenizer'];
    this['plaidAccountLinkEnabled'] = data['plaidAccountLinkEnabled'];
    this['plaidPayerAccountLinkRequiredAch'] = data['plaidPayerAccountLinkRequiredAch'];
  }
}

export class AddBillingCycleInsertRequest {
  /**  */
  'fileName': string;

  /**  */
  'insertBlobUrl': string;

  /**  */
  'cycleDateKey': string;

  /**  */
  'billingCycleConfigId': number;

  /**  */
  'billingCycleId': number;

  constructor(data: undefined | any = {}) {
    this['fileName'] = data['fileName'];
    this['insertBlobUrl'] = data['insertBlobUrl'];
    this['cycleDateKey'] = data['cycleDateKey'];
    this['billingCycleConfigId'] = data['billingCycleConfigId'];
    this['billingCycleId'] = data['billingCycleId'];
  }
}

export class Address {
  /**  */
  'streetAddress': string;

  /**  */
  'streetAddress2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['streetAddress'] = data['streetAddress'];
    this['streetAddress2'] = data['streetAddress2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class AdjustmentDetailDto {
  /**  */
  'id': number;

  /**  */
  'adjustmentDate': string;

  /**  */
  'adjustmentType': string;

  /**  */
  'adjustmentCode': string;

  /**  */
  'amount': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'batchId': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'transactionId': number;

  /**  */
  'paymentId': number;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'transactionDate': Date;

  /**  */
  'settlementType': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['adjustmentDate'] = data['adjustmentDate'];
    this['adjustmentType'] = data['adjustmentType'];
    this['adjustmentCode'] = data['adjustmentCode'];
    this['amount'] = data['amount'];
    this['merchantNumber'] = data['merchantNumber'];
    this['batchId'] = data['batchId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['transactionId'] = data['transactionId'];
    this['paymentId'] = data['paymentId'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['transactionDate'] = data['transactionDate'];
    this['settlementType'] = data['settlementType'];
  }
}

export class AdvancedQueryColumnFilter {
  /**  */
  'fieldName': string;

  /**  */
  'fieldValue': string;

  /**  */
  'exactMatch': boolean;

  constructor(data: undefined | any = {}) {
    this['fieldName'] = data['fieldName'];
    this['fieldValue'] = data['fieldValue'];
    this['exactMatch'] = data['exactMatch'];
  }
}

export class AdvancedQueryColumnSort {
  /**  */
  'fieldName': string;

  /**  */
  'descending': boolean;

  constructor(data: undefined | any = {}) {
    this['fieldName'] = data['fieldName'];
    this['descending'] = data['descending'];
  }
}

export class AgencyGetDto {
  /**  */
  'name': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['id'] = data['id'];
  }
}

export class AgencySummaryDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class ApiClientLineItemDto {
  /**  */
  'slug': string;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'sessionIdentifier': string;

  /**  */
  'lineItemType': string;

  /**  */
  'description': string;

  /**  */
  'priceMinor': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'productSku': string;

  /**  */
  'productName': string;

  constructor(data: undefined | any = {}) {
    this['slug'] = data['slug'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['lineItemType'] = data['lineItemType'];
    this['description'] = data['description'];
    this['priceMinor'] = data['priceMinor'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['productSku'] = data['productSku'];
    this['productName'] = data['productName'];
  }
}

export class ApiClientPaymentDto {
  /**  */
  'slug': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'sessionIdentifier': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'channel': string;

  /**  */
  'amountMinor': number;

  /**  */
  'serviceFeeAmountMinor': number;

  /**  */
  'status': string;

  /**  */
  'paymentDate': Date;

  constructor(data: undefined | any = {}) {
    this['slug'] = data['slug'];
    this['referenceNumber'] = data['referenceNumber'];
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['channel'] = data['channel'];
    this['amountMinor'] = data['amountMinor'];
    this['serviceFeeAmountMinor'] = data['serviceFeeAmountMinor'];
    this['status'] = data['status'];
    this['paymentDate'] = data['paymentDate'];
  }
}

export class ApiClientPaymentSessionDetailDto {
  /**  */
  'paymentSession': ApiClientPaymentSessionDetailDto_PaymentSessionDto;

  /**  */
  'paymentFields': object;

  /**  */
  'payment': ApiClientPaymentSessionDetailDto_PaymentDto;

  /**  */
  'paymentSource': ApiClientPaymentSessionDetailDto_PaymentSourceDto;

  /**  */
  'lineItems': ApiClientPaymentSessionDetailDto_LineItemDto[];

  /**  */
  'canProcessSession': boolean;

  constructor(data: undefined | any = {}) {
    this['paymentSession'] = data['paymentSession'];
    this['paymentFields'] = data['paymentFields'];
    this['payment'] = data['payment'];
    this['paymentSource'] = data['paymentSource'];
    this['lineItems'] = data['lineItems'];
    this['canProcessSession'] = data['canProcessSession'];
  }
}

export class ApiClientPaymentSessionDetailDto_LineItemDto {
  /**  */
  'lineItemType': string;

  /**  */
  'description': string;

  /**  */
  'priceMinor': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'productSku': any | null;

  /**  */
  'productName': any | null;

  constructor(data: undefined | any = {}) {
    this['lineItemType'] = data['lineItemType'];
    this['description'] = data['description'];
    this['priceMinor'] = data['priceMinor'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['productSku'] = data['productSku'];
    this['productName'] = data['productName'];
  }
}

export class ApiClientPaymentSessionDetailDto_PaymentDto {
  /**  */
  'status': string;

  /**  */
  'paymentDate': Date;

  /**  */
  'amountMinor': number;

  /**  */
  'serviceFeeAmountMinor': number;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'authCode': string;

  /**  */
  'gatewayResponseDescription': string;

  constructor(data: undefined | any = {}) {
    this['status'] = data['status'];
    this['paymentDate'] = data['paymentDate'];
    this['amountMinor'] = data['amountMinor'];
    this['serviceFeeAmountMinor'] = data['serviceFeeAmountMinor'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['referenceNumber'] = data['referenceNumber'];
    this['authCode'] = data['authCode'];
    this['gatewayResponseDescription'] = data['gatewayResponseDescription'];
  }
}

export class ApiClientPaymentSessionDetailDto_PaymentSessionDto {
  /**  */
  'identifier': string;

  /**  */
  'createdDateUtc': Date;

  /**  */
  'businessUnitSlug': string;

  /**  */
  'channel': string;

  /**  */
  'status': string;

  /**  */
  'subtotal': number;

  /**  */
  'serviceFeeCalculation': ServiceFeeCalculationDto;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['createdDateUtc'] = data['createdDateUtc'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['channel'] = data['channel'];
    this['status'] = data['status'];
    this['subtotal'] = data['subtotal'];
    this['serviceFeeCalculation'] = data['serviceFeeCalculation'];
  }
}

export class ApiClientPaymentSessionDetailDto_PaymentSourceDto {
  /**  */
  'paymentSourceType': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'accountType': string;

  /**  */
  'accountNumberLast4': string;

  constructor(data: undefined | any = {}) {
    this['paymentSourceType'] = data['paymentSourceType'];
    this['accountHolderName'] = data['accountHolderName'];
    this['accountType'] = data['accountType'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
  }
}

export class ApiClientPaymentSessionDto {
  /**  */
  'paymentSessionIdentifier': string;

  /**  */
  'paymentLogInLink': string;

  /**  */
  'status': string;

  constructor(data: undefined | any = {}) {
    this['paymentSessionIdentifier'] = data['paymentSessionIdentifier'];
    this['paymentLogInLink'] = data['paymentLogInLink'];
    this['status'] = data['status'];
  }
}

export class ApiClientSessionResultDto {
  /**  */
  'sessionLink': string;

  /**  */
  'sessionType': string;

  /**  */
  'sessionStatus': string;

  /**  */
  'validUntil': Date;

  constructor(data: undefined | any = {}) {
    this['sessionLink'] = data['sessionLink'];
    this['sessionType'] = data['sessionType'];
    this['sessionStatus'] = data['sessionStatus'];
    this['validUntil'] = data['validUntil'];
  }
}

export class ApiClientUserSessionDto {
  /**  */
  'userLogInLink': string;

  /**  */
  'emailAddress': string;

  /**  */
  'initialAction': string;

  constructor(data: undefined | any = {}) {
    this['userLogInLink'] = data['userLogInLink'];
    this['emailAddress'] = data['emailAddress'];
    this['initialAction'] = data['initialAction'];
  }
}

export class ArchiveDeviceRequest {
  /**  */
  'isArchived': boolean;

  constructor(data: undefined | any = {}) {
    this['isArchived'] = data['isArchived'];
  }
}

export class AutopayActivityLogExportDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountId': number;

  /**  */
  'subAccountNumber': string;

  /**  */
  'activityType': string;

  /**  */
  'accountName': string;

  /**  */
  'createdDate': Date;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountId'] = data['accountId'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['activityType'] = data['activityType'];
    this['accountName'] = data['accountName'];
    this['createdDate'] = data['createdDate'];
  }
}

export class AutopayActivityLogSummaryDto {
  /**  */
  'activityType': string;

  /**  */
  'createdDate': Date;

  /**  */
  'metaJson': string;

  /**  */
  'paymentSource': PaymentSourceSummaryDto;

  constructor(data: undefined | any = {}) {
    this['activityType'] = data['activityType'];
    this['createdDate'] = data['createdDate'];
    this['metaJson'] = data['metaJson'];
    this['paymentSource'] = data['paymentSource'];
  }
}

export class AutopayEnrollEventPayload {
  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'activityType': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'dueDate': string;

  /**  */
  'customerEmailAddress': string;

  /**  */
  'processingLeadTime': string;

  /**  */
  'maximumBalanceAllowed': string;

  /**  */
  'paymentSourceDescription': string;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['activityType'] = data['activityType'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['dueDate'] = data['dueDate'];
    this['customerEmailAddress'] = data['customerEmailAddress'];
    this['processingLeadTime'] = data['processingLeadTime'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['paymentSourceDescription'] = data['paymentSourceDescription'];
  }
}

export class AutopayEnrollmentResponse {
  /**  */
  'enrollmentSettings': AutopayEnrollmentSettings;

  /**  */
  'showMissedWindowNotice': boolean;

  constructor(data: undefined | any = {}) {
    this['enrollmentSettings'] = data['enrollmentSettings'];
    this['showMissedWindowNotice'] = data['showMissedWindowNotice'];
  }
}

export class AutopayEnrollmentSettings {
  /**  */
  'enrolled': boolean;

  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'maximumBalanceAllowed': number;

  /**  */
  'paymentSourceId': number;

  constructor(data: undefined | any = {}) {
    this['enrolled'] = data['enrolled'];
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['paymentSourceId'] = data['paymentSourceId'];
  }
}

export class AutopayUnenrollEventPayload {
  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'dueDate': string;

  /**  */
  'currentBalance': string;

  /**  */
  'customerEmailAddress': string;

  constructor(data: undefined | any = {}) {
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['dueDate'] = data['dueDate'];
    this['currentBalance'] = data['currentBalance'];
    this['customerEmailAddress'] = data['customerEmailAddress'];
  }
}

export class AutopayUnenrollMetaJson {
  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'dueDate': string;

  /**  */
  'currentBalance': string;

  /**  */
  'customerEmailAddress': string;

  /**  */
  'modifiedByEmail': string;

  /**  */
  'modifiedByName': string;

  /**  */
  'businessUnitId': number;

  constructor(data: undefined | any = {}) {
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['dueDate'] = data['dueDate'];
    this['currentBalance'] = data['currentBalance'];
    this['customerEmailAddress'] = data['customerEmailAddress'];
    this['modifiedByEmail'] = data['modifiedByEmail'];
    this['modifiedByName'] = data['modifiedByName'];
    this['businessUnitId'] = data['businessUnitId'];
  }
}

export class AutopayUpdateEnrollmentRequest {
  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'emailAddress': string;

  /**  */
  'paymentSourceId': number;

  /**  */
  'maximumBalanceAllowed': number;

  /**  */
  'termsAcknowledged': boolean;

  /**  */
  'isEmbedded': boolean;

  constructor(data: undefined | any = {}) {
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['emailAddress'] = data['emailAddress'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['termsAcknowledged'] = data['termsAcknowledged'];
    this['isEmbedded'] = data['isEmbedded'];
  }
}

export class BankAccountSourceDto {
  /**  */
  'accountHolderName': string;

  /**  */
  'accountNumberMasked': string;

  /**  */
  'accountType': string;

  /**  */
  'accountNumberLast4': string;

  constructor(data: undefined | any = {}) {
    this['accountHolderName'] = data['accountHolderName'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
    this['accountType'] = data['accountType'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
  }
}

export class BillCycleImportApprovedEventPayload {
  /**  */
  'billingCycleId': number;

  /**  */
  'cycleConfigIdentifier': string;

  /**  */
  'cycleDateKey': string;

  /**  */
  'startDate': Date;

  /**  */
  'endDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'dueDate': Date;

  /**  */
  'billTemplateIdentifier': string;

  /**  */
  'originalBlobUrl': string;

  /**  */
  'parsedBlobUrl': string;

  /**  */
  'billCount': number;

  /**  */
  'billingCycleMetaJson': string;

  constructor(data: undefined | any = {}) {
    this['billingCycleId'] = data['billingCycleId'];
    this['cycleConfigIdentifier'] = data['cycleConfigIdentifier'];
    this['cycleDateKey'] = data['cycleDateKey'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['issueDate'] = data['issueDate'];
    this['dueDate'] = data['dueDate'];
    this['billTemplateIdentifier'] = data['billTemplateIdentifier'];
    this['originalBlobUrl'] = data['originalBlobUrl'];
    this['parsedBlobUrl'] = data['parsedBlobUrl'];
    this['billCount'] = data['billCount'];
    this['billingCycleMetaJson'] = data['billingCycleMetaJson'];
  }
}

export class BillFileUploadedEventPayload {
  /**  */
  'billingCycleId': number;

  /**  */
  'blobUrl': string;

  /**  */
  'downloadBlobUrl': string;

  /**  */
  'userEmail': string;

  /**  */
  'genericMessage': string;

  /**  */
  'customMessage': string;

  /**  */
  'billFileName': string;

  /**  */
  'cycleConfigIdentifier': string;

  /**  */
  'cycleDateKey': string;

  /**  */
  'billInsertUrl': string;

  /**  */
  'billInsertFileName': string;

  /**  */
  'startDate': Date;

  /**  */
  'endDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'dueDate': Date;

  constructor(data: undefined | any = {}) {
    this['billingCycleId'] = data['billingCycleId'];
    this['blobUrl'] = data['blobUrl'];
    this['downloadBlobUrl'] = data['downloadBlobUrl'];
    this['userEmail'] = data['userEmail'];
    this['genericMessage'] = data['genericMessage'];
    this['customMessage'] = data['customMessage'];
    this['billFileName'] = data['billFileName'];
    this['cycleConfigIdentifier'] = data['cycleConfigIdentifier'];
    this['cycleDateKey'] = data['cycleDateKey'];
    this['billInsertUrl'] = data['billInsertUrl'];
    this['billInsertFileName'] = data['billInsertFileName'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['issueDate'] = data['issueDate'];
    this['dueDate'] = data['dueDate'];
  }
}

export class BillLineItemReportDto {
  /**  */
  'id': number;

  /**  */
  'description': string;

  /**  */
  'order': number;

  /**  */
  'cost': number;

  /**  */
  'metaJson': string;

  /**  */
  'data': object;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['description'] = data['description'];
    this['order'] = data['order'];
    this['cost'] = data['cost'];
    this['metaJson'] = data['metaJson'];
    this['data'] = data['data'];
  }
}

export class BillReportDto {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'billingCycleId': number;

  /**  */
  'identifier': string;

  /**  */
  'issueDate': Date;

  /**  */
  'accountId': number;

  /**  */
  'dueDate': Date;

  /**  */
  'balance': number;

  /**  */
  'assetUrl': string;

  /**  */
  'metaJson': string;

  /**  */
  'lineItems': BillLineItemReportDto[];

  /**  */
  'accountFieldMap': object;

  /**  */
  'businessUnit': BusinessUnitGetDto;

  /**  */
  'account': AccountGetDto;

  /**  */
  'accountFields': AccountFieldGetDto[];

  /**  */
  'billingCycle': BillingCycleDetailDto;

  /**  */
  'data': object;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['billingCycleId'] = data['billingCycleId'];
    this['identifier'] = data['identifier'];
    this['issueDate'] = data['issueDate'];
    this['accountId'] = data['accountId'];
    this['dueDate'] = data['dueDate'];
    this['balance'] = data['balance'];
    this['assetUrl'] = data['assetUrl'];
    this['metaJson'] = data['metaJson'];
    this['lineItems'] = data['lineItems'];
    this['accountFieldMap'] = data['accountFieldMap'];
    this['businessUnit'] = data['businessUnit'];
    this['account'] = data['account'];
    this['accountFields'] = data['accountFields'];
    this['billingCycle'] = data['billingCycle'];
    this['data'] = data['data'];
  }
}

export class BillSummaryDto {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'billingCycleId': number;

  /**  */
  'identifier': string;

  /**  */
  'issueDate': Date;

  /**  */
  'accountId': number;

  /**  */
  'dueDate': Date;

  /**  */
  'balance': number;

  /**  */
  'assetUrl': string;

  /**  */
  'billType': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['billingCycleId'] = data['billingCycleId'];
    this['identifier'] = data['identifier'];
    this['issueDate'] = data['issueDate'];
    this['accountId'] = data['accountId'];
    this['dueDate'] = data['dueDate'];
    this['balance'] = data['balance'];
    this['assetUrl'] = data['assetUrl'];
    this['billType'] = data['billType'];
  }
}

export class BillingCycleConfigGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'identifier': string;

  /**  */
  'notificationStrategyType': string;

  /**  */
  'billType': string;

  /**  */
  'allowInserts': boolean;

  /**  */
  'isBillPrinted': boolean;

  /**  */
  'isDisabled': boolean;

  /**  */
  'executionOrder': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['identifier'] = data['identifier'];
    this['notificationStrategyType'] = data['notificationStrategyType'];
    this['billType'] = data['billType'];
    this['allowInserts'] = data['allowInserts'];
    this['isBillPrinted'] = data['isBillPrinted'];
    this['isDisabled'] = data['isDisabled'];
    this['executionOrder'] = data['executionOrder'];
    this['id'] = data['id'];
  }
}

export class BillingCycleDetailDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'billingCycleConfigId': number;

  /**  */
  'cycleDateKey': string;

  /**  */
  'status': string;

  /**  */
  'startDate': Date;

  /**  */
  'endDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'dueDate': Date;

  /**  */
  'metaJson': string;

  /**  */
  'customMessage': string;

  /**  */
  'insertBlobUrl': string;

  /**  */
  'id': number;

  /**  */
  'assetUrl': string;

  /**  */
  'billCount': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['billingCycleConfigId'] = data['billingCycleConfigId'];
    this['cycleDateKey'] = data['cycleDateKey'];
    this['status'] = data['status'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['issueDate'] = data['issueDate'];
    this['dueDate'] = data['dueDate'];
    this['metaJson'] = data['metaJson'];
    this['customMessage'] = data['customMessage'];
    this['insertBlobUrl'] = data['insertBlobUrl'];
    this['id'] = data['id'];
    this['assetUrl'] = data['assetUrl'];
    this['billCount'] = data['billCount'];
  }
}

export class BillingCycleGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'billingCycleConfigId': number;

  /**  */
  'cycleDateKey': string;

  /**  */
  'status': string;

  /**  */
  'startDate': Date;

  /**  */
  'endDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'dueDate': Date;

  /**  */
  'metaJson': string;

  /**  */
  'customMessage': string;

  /**  */
  'insertBlobUrl': string;

  /**  */
  'id': number;

  /**  */
  'assetUrl': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['billingCycleConfigId'] = data['billingCycleConfigId'];
    this['cycleDateKey'] = data['cycleDateKey'];
    this['status'] = data['status'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['issueDate'] = data['issueDate'];
    this['dueDate'] = data['dueDate'];
    this['metaJson'] = data['metaJson'];
    this['customMessage'] = data['customMessage'];
    this['insertBlobUrl'] = data['insertBlobUrl'];
    this['id'] = data['id'];
    this['assetUrl'] = data['assetUrl'];
  }
}

export class BillingCycleUploadDetailsDto {
  /**  */
  'id': number;

  /**  */
  'status': string;

  /**  */
  'cycleDateKey': string;

  /**  */
  'billingCycleConfigIdentifier': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['status'] = data['status'];
    this['cycleDateKey'] = data['cycleDateKey'];
    this['billingCycleConfigIdentifier'] = data['billingCycleConfigIdentifier'];
  }
}

export class BillingCyclesSummaryDto {
  /**  */
  'configs': BillingCycleConfigGetDto[];

  /**  */
  'cycles': BillingCycleDetailDto[];

  constructor(data: undefined | any = {}) {
    this['configs'] = data['configs'];
    this['cycles'] = data['cycles'];
  }
}

export class BillingSystemDto {
  /**  */
  'name': string;

  /**  */
  'notes': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['notes'] = data['notes'];
  }
}

export class BillingSystemGetDto {
  /**  */
  'name': string;

  /**  */
  'notes': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['notes'] = data['notes'];
    this['id'] = data['id'];
  }
}

export class BlobUploadUrlResult {
  /**  */
  'url': string;

  /**  */
  'extension': string;

  /**  */
  'expires': Date;

  constructor(data: undefined | any = {}) {
    this['url'] = data['url'];
    this['extension'] = data['extension'];
    this['expires'] = data['expires'];
  }
}

export class BusinessUnitClientManagementSettingsDto {
  /**  */
  'paymentsDisabled': boolean;

  constructor(data: undefined | any = {}) {
    this['paymentsDisabled'] = data['paymentsDisabled'];
  }
}

export class BusinessUnitClientSummaryDto {
  /**  */
  'slug': string;

  /**  */
  'ivrNumber': string;

  /**  */
  'customerRegistrationEnabled': boolean;

  /**  */
  'notes': string;

  /**  */
  'organizationNotes': string;

  /**  */
  'billingSystemName': string;

  /**  */
  'apiSecret': string;

  /**  */
  'quickPayLookupFields': string[];

  /**  */
  'ivrLookupFields': string[];

  /**  */
  'quickPay': PaymentChannelClientSummaryDto;

  /**  */
  'ivr': PaymentChannelClientSummaryDto;

  /**  */
  'pointOfSale': PaymentChannelClientSummaryDto;

  /**  */
  'autopay': PaymentChannelClientSummaryDto;

  constructor(data: undefined | any = {}) {
    this['slug'] = data['slug'];
    this['ivrNumber'] = data['ivrNumber'];
    this['customerRegistrationEnabled'] = data['customerRegistrationEnabled'];
    this['notes'] = data['notes'];
    this['organizationNotes'] = data['organizationNotes'];
    this['billingSystemName'] = data['billingSystemName'];
    this['apiSecret'] = data['apiSecret'];
    this['quickPayLookupFields'] = data['quickPayLookupFields'];
    this['ivrLookupFields'] = data['ivrLookupFields'];
    this['quickPay'] = data['quickPay'];
    this['ivr'] = data['ivr'];
    this['pointOfSale'] = data['pointOfSale'];
    this['autopay'] = data['autopay'];
  }
}

export class BusinessUnitContextDto {
  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'primaryColor': string;

  /**  */
  'name': string;

  /**  */
  'organizationName': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'address': Address;

  /**  */
  'id': number;

  /**  */
  'ivrNumber': string;

  /**  */
  'slug': string;

  /**  */
  'accountHelpUrl': string;

  /**  */
  'organizationId': number;

  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'billingSystemName': string;

  /**  */
  'billingAddressCollectionType': string;

  /**  */
  'currencyCode': string;

  /**  */
  'timezone': string;

  /**  */
  'autopayAmountLimit': string;

  /**  */
  'channelDisabled': boolean;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'customAccountSyncStrategy': boolean;

  /**  */
  'autoPayEnabled': boolean;

  /**  */
  'checkoutEnabled': boolean;

  /**  */
  'payerLoginMode': string;

  /**  */
  'readingsIdentifier': string;

  /**  */
  'uploadAccountsDueDateDay': number;

  /**  */
  'features': BusinessUnitFeatures;

  /**  */
  'paymentChannel': PaymentChannelSummaryDto;

  /**  */
  'termsOfServices': BusinessUnitTermsOfServices;

  /**  */
  'accountFields': AccountFieldGetDto[];

  /**  */
  'paymentFields': PaymentFieldDetailDto[];

  /**  */
  'messages': MessageDetailDto[];

  constructor(data: undefined | any = {}) {
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['primaryColor'] = data['primaryColor'];
    this['name'] = data['name'];
    this['organizationName'] = data['organizationName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['address'] = data['address'];
    this['id'] = data['id'];
    this['ivrNumber'] = data['ivrNumber'];
    this['slug'] = data['slug'];
    this['accountHelpUrl'] = data['accountHelpUrl'];
    this['organizationId'] = data['organizationId'];
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['billingSystemName'] = data['billingSystemName'];
    this['billingAddressCollectionType'] = data['billingAddressCollectionType'];
    this['currencyCode'] = data['currencyCode'];
    this['timezone'] = data['timezone'];
    this['autopayAmountLimit'] = data['autopayAmountLimit'];
    this['channelDisabled'] = data['channelDisabled'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['customAccountSyncStrategy'] = data['customAccountSyncStrategy'];
    this['autoPayEnabled'] = data['autoPayEnabled'];
    this['checkoutEnabled'] = data['checkoutEnabled'];
    this['payerLoginMode'] = data['payerLoginMode'];
    this['readingsIdentifier'] = data['readingsIdentifier'];
    this['uploadAccountsDueDateDay'] = data['uploadAccountsDueDateDay'];
    this['features'] = data['features'];
    this['paymentChannel'] = data['paymentChannel'];
    this['termsOfServices'] = data['termsOfServices'];
    this['accountFields'] = data['accountFields'];
    this['paymentFields'] = data['paymentFields'];
    this['messages'] = data['messages'];
  }
}

export class BusinessUnitDetailDto {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'address': Address;

  /**  */
  'phoneNumber': string;

  /**  */
  'ivrNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'accountHelpUrl': string;

  /**  */
  'billingAddressCollectionType': string;

  /**  */
  'currencyCode': string;

  /**  */
  'timezone': string;

  /**  */
  'notes': string;

  /**  */
  'primaryColor': string;

  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'billTemplateIdentifier': string;

  /**  */
  'pdfTemplateIdentifier': string;

  /**  */
  'excelTemplateIdentifier': string;

  /**  */
  'accountSyncStrategy': string;

  /**  */
  'accountSyncUrl': string;

  /**  */
  'accountSyncApiKey': string;

  /**  */
  'trustedDomains': string;

  /**  */
  'readingsIdentifier': string;

  /**  */
  'readingsSyncUrl': string;

  /**  */
  'readingsSyncApiKey': string;

  /**  */
  'syncPayments': boolean;

  /**  */
  'paymentSyncUrl': string;

  /**  */
  'paymentSyncSecret': string;

  /**  */
  'paymentSyncPayload': string;

  /**  */
  'paymentSyncOnSucceeded': boolean;

  /**  */
  'paymentSyncOnDeclined': boolean;

  /**  */
  'paymentSyncOnRefunded': boolean;

  /**  */
  'paymentSyncOnVoided': boolean;

  /**  */
  'quickPayConfigId': number;

  /**  */
  'ivrConfigId': number;

  /**  */
  'pointOfSaleConfigId': number;

  /**  */
  'autopayConfigId': number;

  /**  */
  'minPaymentAmount': number;

  /**  */
  'maxPaymentAmount': number;

  /**  */
  'maxRefundAmount': number;

  /**  */
  'agencyId': number;

  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'testAccount': boolean;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'billingSystemId': number;

  /**  */
  'onboardingConfigurationId': number;

  /**  */
  'billingSystemVersion': string;

  /**  */
  'reasonForConfigChange': string;

  /**  */
  'payerLoginMode': string;

  /**  */
  'embeddedHost': string;

  /**  */
  'uploadAccountsDueDateDay': number;

  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'features': BusinessUnitFeatures;

  /**  */
  'termsOfServiceIdentifiers': BusinessUnitTermsOfServices;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['address'] = data['address'];
    this['phoneNumber'] = data['phoneNumber'];
    this['ivrNumber'] = data['ivrNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['accountHelpUrl'] = data['accountHelpUrl'];
    this['billingAddressCollectionType'] = data['billingAddressCollectionType'];
    this['currencyCode'] = data['currencyCode'];
    this['timezone'] = data['timezone'];
    this['notes'] = data['notes'];
    this['primaryColor'] = data['primaryColor'];
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['billTemplateIdentifier'] = data['billTemplateIdentifier'];
    this['pdfTemplateIdentifier'] = data['pdfTemplateIdentifier'];
    this['excelTemplateIdentifier'] = data['excelTemplateIdentifier'];
    this['accountSyncStrategy'] = data['accountSyncStrategy'];
    this['accountSyncUrl'] = data['accountSyncUrl'];
    this['accountSyncApiKey'] = data['accountSyncApiKey'];
    this['trustedDomains'] = data['trustedDomains'];
    this['readingsIdentifier'] = data['readingsIdentifier'];
    this['readingsSyncUrl'] = data['readingsSyncUrl'];
    this['readingsSyncApiKey'] = data['readingsSyncApiKey'];
    this['syncPayments'] = data['syncPayments'];
    this['paymentSyncUrl'] = data['paymentSyncUrl'];
    this['paymentSyncSecret'] = data['paymentSyncSecret'];
    this['paymentSyncPayload'] = data['paymentSyncPayload'];
    this['paymentSyncOnSucceeded'] = data['paymentSyncOnSucceeded'];
    this['paymentSyncOnDeclined'] = data['paymentSyncOnDeclined'];
    this['paymentSyncOnRefunded'] = data['paymentSyncOnRefunded'];
    this['paymentSyncOnVoided'] = data['paymentSyncOnVoided'];
    this['quickPayConfigId'] = data['quickPayConfigId'];
    this['ivrConfigId'] = data['ivrConfigId'];
    this['pointOfSaleConfigId'] = data['pointOfSaleConfigId'];
    this['autopayConfigId'] = data['autopayConfigId'];
    this['minPaymentAmount'] = data['minPaymentAmount'];
    this['maxPaymentAmount'] = data['maxPaymentAmount'];
    this['maxRefundAmount'] = data['maxRefundAmount'];
    this['agencyId'] = data['agencyId'];
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['testAccount'] = data['testAccount'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['billingSystemId'] = data['billingSystemId'];
    this['onboardingConfigurationId'] = data['onboardingConfigurationId'];
    this['billingSystemVersion'] = data['billingSystemVersion'];
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
    this['payerLoginMode'] = data['payerLoginMode'];
    this['embeddedHost'] = data['embeddedHost'];
    this['uploadAccountsDueDateDay'] = data['uploadAccountsDueDateDay'];
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['features'] = data['features'];
    this['termsOfServiceIdentifiers'] = data['termsOfServiceIdentifiers'];
  }
}

export class BusinessUnitDto {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'address': Address;

  /**  */
  'phoneNumber': string;

  /**  */
  'ivrNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'accountHelpUrl': string;

  /**  */
  'billingAddressCollectionType': string;

  /**  */
  'currencyCode': string;

  /**  */
  'timezone': string;

  /**  */
  'notes': string;

  /**  */
  'primaryColor': string;

  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'billTemplateIdentifier': string;

  /**  */
  'pdfTemplateIdentifier': string;

  /**  */
  'excelTemplateIdentifier': string;

  /**  */
  'accountSyncStrategy': string;

  /**  */
  'accountSyncUrl': string;

  /**  */
  'accountSyncApiKey': string;

  /**  */
  'trustedDomains': string;

  /**  */
  'readingsIdentifier': string;

  /**  */
  'readingsSyncUrl': string;

  /**  */
  'readingsSyncApiKey': string;

  /**  */
  'syncPayments': boolean;

  /**  */
  'paymentSyncUrl': string;

  /**  */
  'paymentSyncSecret': string;

  /**  */
  'paymentSyncPayload': string;

  /**  */
  'paymentSyncOnSucceeded': boolean;

  /**  */
  'paymentSyncOnDeclined': boolean;

  /**  */
  'paymentSyncOnRefunded': boolean;

  /**  */
  'paymentSyncOnVoided': boolean;

  /**  */
  'quickPayConfigId': number;

  /**  */
  'ivrConfigId': number;

  /**  */
  'pointOfSaleConfigId': number;

  /**  */
  'autopayConfigId': number;

  /**  */
  'minPaymentAmount': number;

  /**  */
  'maxPaymentAmount': number;

  /**  */
  'maxRefundAmount': number;

  /**  */
  'agencyId': number;

  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'testAccount': boolean;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'billingSystemId': number;

  /**  */
  'onboardingConfigurationId': number;

  /**  */
  'billingSystemVersion': string;

  /**  */
  'reasonForConfigChange': string;

  /**  */
  'payerLoginMode': string;

  /**  */
  'embeddedHost': string;

  /**  */
  'uploadAccountsDueDateDay': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['address'] = data['address'];
    this['phoneNumber'] = data['phoneNumber'];
    this['ivrNumber'] = data['ivrNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['accountHelpUrl'] = data['accountHelpUrl'];
    this['billingAddressCollectionType'] = data['billingAddressCollectionType'];
    this['currencyCode'] = data['currencyCode'];
    this['timezone'] = data['timezone'];
    this['notes'] = data['notes'];
    this['primaryColor'] = data['primaryColor'];
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['billTemplateIdentifier'] = data['billTemplateIdentifier'];
    this['pdfTemplateIdentifier'] = data['pdfTemplateIdentifier'];
    this['excelTemplateIdentifier'] = data['excelTemplateIdentifier'];
    this['accountSyncStrategy'] = data['accountSyncStrategy'];
    this['accountSyncUrl'] = data['accountSyncUrl'];
    this['accountSyncApiKey'] = data['accountSyncApiKey'];
    this['trustedDomains'] = data['trustedDomains'];
    this['readingsIdentifier'] = data['readingsIdentifier'];
    this['readingsSyncUrl'] = data['readingsSyncUrl'];
    this['readingsSyncApiKey'] = data['readingsSyncApiKey'];
    this['syncPayments'] = data['syncPayments'];
    this['paymentSyncUrl'] = data['paymentSyncUrl'];
    this['paymentSyncSecret'] = data['paymentSyncSecret'];
    this['paymentSyncPayload'] = data['paymentSyncPayload'];
    this['paymentSyncOnSucceeded'] = data['paymentSyncOnSucceeded'];
    this['paymentSyncOnDeclined'] = data['paymentSyncOnDeclined'];
    this['paymentSyncOnRefunded'] = data['paymentSyncOnRefunded'];
    this['paymentSyncOnVoided'] = data['paymentSyncOnVoided'];
    this['quickPayConfigId'] = data['quickPayConfigId'];
    this['ivrConfigId'] = data['ivrConfigId'];
    this['pointOfSaleConfigId'] = data['pointOfSaleConfigId'];
    this['autopayConfigId'] = data['autopayConfigId'];
    this['minPaymentAmount'] = data['minPaymentAmount'];
    this['maxPaymentAmount'] = data['maxPaymentAmount'];
    this['maxRefundAmount'] = data['maxRefundAmount'];
    this['agencyId'] = data['agencyId'];
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['testAccount'] = data['testAccount'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['billingSystemId'] = data['billingSystemId'];
    this['onboardingConfigurationId'] = data['onboardingConfigurationId'];
    this['billingSystemVersion'] = data['billingSystemVersion'];
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
    this['payerLoginMode'] = data['payerLoginMode'];
    this['embeddedHost'] = data['embeddedHost'];
    this['uploadAccountsDueDateDay'] = data['uploadAccountsDueDateDay'];
  }
}

export class BusinessUnitFeatures {
  /**  */
  'manualAccountManagement': boolean;

  /**  */
  'uploadAccounts': boolean;

  /**  */
  'uploadAccountsIssueDate': boolean;

  /**  */
  'uploadAccountsDueDate': boolean;

  /**  */
  'disableQuickPayLookup': boolean;

  /**  */
  'accountRollover': boolean;

  /**  */
  'billerAutopayManagement': boolean;

  /**  */
  'paperlessBilling': boolean;

  /**  */
  'suppressBillNotifications': boolean;

  /**  */
  'bills': boolean;

  /**  */
  'uploadBillsStartDate': boolean;

  /**  */
  'uploadBillsEndDate': boolean;

  /**  */
  'uploadBillsIssueDate': boolean;

  /**  */
  'uploadBillsDueDate': boolean;

  /**  */
  'uploadBillsGenericMessage': boolean;

  /**  */
  'uploadBillsCustomMessage': boolean;

  /**  */
  'customerRegistration': boolean;

  /**  */
  'upcomingPaymentNotifications': boolean;

  /**  */
  'smsNotifications': boolean;

  /**  */
  'creditBalanceDisplay': boolean;

  /**  */
  'enableCardSwipe': boolean;

  /**  */
  'useExplicitMsrEncryption': boolean;

  /**  */
  'posReport': boolean;

  /**  */
  'autoGenerateAccountNumber': boolean;

  /**  */
  'products': boolean;

  /**  */
  'outboundIvr': boolean;

  /**  */
  'outboundSms': boolean;

  /**  */
  'outboundEmail': boolean;

  /**  */
  'engageOutboundIvr': boolean;

  /**  */
  'paymentSessionLogin': boolean;

  /**  */
  'userSessionLogin': boolean;

  /**  */
  'accountlessPayments': boolean;

  /**  */
  'accountlessProducts': boolean;

  /**  */
  'disableBusinessUnitAccounts': boolean;

  /**  */
  'externalLinks': boolean;

  /**  */
  'forms': boolean;

  /**  */
  'enablePlaidAccountLink': boolean;

  /**  */
  'payerPlaidAccountLinkRequiredAch': boolean;

  /**  */
  'useBatchIdentifierReport': boolean;

  /**  */
  'newPayerUi': boolean;

  /**  */
  'enableAccountWallets': boolean;

  /**  */
  'externalTransactions': boolean;

  /**  */
  'spanishSupport': boolean;

  /**  */
  'pastDueAccounts': boolean;

  /**  */
  'readings': boolean;

  /**  */
  'uploadReadings': boolean;

  /**  */
  'associatePhoneNumberWithAccount': boolean;

  /**  */
  'enableBatchAutopay': boolean;

  /**  */
  'enableBase64GenericMessage': boolean;

  /**  */
  'enableZeroValueEmbeddedCharges': boolean;

  /**  */
  'multipleAccountPayments': boolean;

  /**  */
  'multiAccountQuickpay': boolean;

  /**  */
  'multiAccountPos': boolean;

  /**  */
  'usePastDueAmountMinor': boolean;

  /**  */
  'scheduleAutopayOnEarlyBilling': boolean;

  /**  */
  'paymentReceiptBarcodes': boolean;

  /**  */
  'enableIvrAccountSync': boolean;

  constructor(data: undefined | any = {}) {
    this['manualAccountManagement'] = data['manualAccountManagement'];
    this['uploadAccounts'] = data['uploadAccounts'];
    this['uploadAccountsIssueDate'] = data['uploadAccountsIssueDate'];
    this['uploadAccountsDueDate'] = data['uploadAccountsDueDate'];
    this['disableQuickPayLookup'] = data['disableQuickPayLookup'];
    this['accountRollover'] = data['accountRollover'];
    this['billerAutopayManagement'] = data['billerAutopayManagement'];
    this['paperlessBilling'] = data['paperlessBilling'];
    this['suppressBillNotifications'] = data['suppressBillNotifications'];
    this['bills'] = data['bills'];
    this['uploadBillsStartDate'] = data['uploadBillsStartDate'];
    this['uploadBillsEndDate'] = data['uploadBillsEndDate'];
    this['uploadBillsIssueDate'] = data['uploadBillsIssueDate'];
    this['uploadBillsDueDate'] = data['uploadBillsDueDate'];
    this['uploadBillsGenericMessage'] = data['uploadBillsGenericMessage'];
    this['uploadBillsCustomMessage'] = data['uploadBillsCustomMessage'];
    this['customerRegistration'] = data['customerRegistration'];
    this['upcomingPaymentNotifications'] = data['upcomingPaymentNotifications'];
    this['smsNotifications'] = data['smsNotifications'];
    this['creditBalanceDisplay'] = data['creditBalanceDisplay'];
    this['enableCardSwipe'] = data['enableCardSwipe'];
    this['useExplicitMsrEncryption'] = data['useExplicitMsrEncryption'];
    this['posReport'] = data['posReport'];
    this['autoGenerateAccountNumber'] = data['autoGenerateAccountNumber'];
    this['products'] = data['products'];
    this['outboundIvr'] = data['outboundIvr'];
    this['outboundSms'] = data['outboundSms'];
    this['outboundEmail'] = data['outboundEmail'];
    this['engageOutboundIvr'] = data['engageOutboundIvr'];
    this['paymentSessionLogin'] = data['paymentSessionLogin'];
    this['userSessionLogin'] = data['userSessionLogin'];
    this['accountlessPayments'] = data['accountlessPayments'];
    this['accountlessProducts'] = data['accountlessProducts'];
    this['disableBusinessUnitAccounts'] = data['disableBusinessUnitAccounts'];
    this['externalLinks'] = data['externalLinks'];
    this['forms'] = data['forms'];
    this['enablePlaidAccountLink'] = data['enablePlaidAccountLink'];
    this['payerPlaidAccountLinkRequiredAch'] = data['payerPlaidAccountLinkRequiredAch'];
    this['useBatchIdentifierReport'] = data['useBatchIdentifierReport'];
    this['newPayerUi'] = data['newPayerUi'];
    this['enableAccountWallets'] = data['enableAccountWallets'];
    this['externalTransactions'] = data['externalTransactions'];
    this['spanishSupport'] = data['spanishSupport'];
    this['pastDueAccounts'] = data['pastDueAccounts'];
    this['readings'] = data['readings'];
    this['uploadReadings'] = data['uploadReadings'];
    this['associatePhoneNumberWithAccount'] = data['associatePhoneNumberWithAccount'];
    this['enableBatchAutopay'] = data['enableBatchAutopay'];
    this['enableBase64GenericMessage'] = data['enableBase64GenericMessage'];
    this['enableZeroValueEmbeddedCharges'] = data['enableZeroValueEmbeddedCharges'];
    this['multipleAccountPayments'] = data['multipleAccountPayments'];
    this['multiAccountQuickpay'] = data['multiAccountQuickpay'];
    this['multiAccountPos'] = data['multiAccountPos'];
    this['usePastDueAmountMinor'] = data['usePastDueAmountMinor'];
    this['scheduleAutopayOnEarlyBilling'] = data['scheduleAutopayOnEarlyBilling'];
    this['paymentReceiptBarcodes'] = data['paymentReceiptBarcodes'];
    this['enableIvrAccountSync'] = data['enableIvrAccountSync'];
  }
}

export class BusinessUnitFileDetailDto {
  /**  */
  'blobUrl': string;

  /**  */
  'jobId': number;

  /**  */
  'jobExecutionStateId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'fileName': string;

  /**  */
  'type': string;

  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'jobName': string;

  /**  */
  'businessUnitName': string;

  /**  */
  'createdByName': string;

  /**  */
  'createdDate': Date;

  constructor(data: undefined | any = {}) {
    this['blobUrl'] = data['blobUrl'];
    this['jobId'] = data['jobId'];
    this['jobExecutionStateId'] = data['jobExecutionStateId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['fileName'] = data['fileName'];
    this['type'] = data['type'];
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['jobName'] = data['jobName'];
    this['businessUnitName'] = data['businessUnitName'];
    this['createdByName'] = data['createdByName'];
    this['createdDate'] = data['createdDate'];
  }
}

export class BusinessUnitGetDto {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'address': Address;

  /**  */
  'phoneNumber': string;

  /**  */
  'ivrNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'accountHelpUrl': string;

  /**  */
  'billingAddressCollectionType': string;

  /**  */
  'currencyCode': string;

  /**  */
  'timezone': string;

  /**  */
  'notes': string;

  /**  */
  'primaryColor': string;

  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'billTemplateIdentifier': string;

  /**  */
  'pdfTemplateIdentifier': string;

  /**  */
  'excelTemplateIdentifier': string;

  /**  */
  'accountSyncStrategy': string;

  /**  */
  'accountSyncUrl': string;

  /**  */
  'accountSyncApiKey': string;

  /**  */
  'trustedDomains': string;

  /**  */
  'readingsIdentifier': string;

  /**  */
  'readingsSyncUrl': string;

  /**  */
  'readingsSyncApiKey': string;

  /**  */
  'syncPayments': boolean;

  /**  */
  'paymentSyncUrl': string;

  /**  */
  'paymentSyncSecret': string;

  /**  */
  'paymentSyncPayload': string;

  /**  */
  'paymentSyncOnSucceeded': boolean;

  /**  */
  'paymentSyncOnDeclined': boolean;

  /**  */
  'paymentSyncOnRefunded': boolean;

  /**  */
  'paymentSyncOnVoided': boolean;

  /**  */
  'quickPayConfigId': number;

  /**  */
  'ivrConfigId': number;

  /**  */
  'pointOfSaleConfigId': number;

  /**  */
  'autopayConfigId': number;

  /**  */
  'minPaymentAmount': number;

  /**  */
  'maxPaymentAmount': number;

  /**  */
  'maxRefundAmount': number;

  /**  */
  'agencyId': number;

  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'testAccount': boolean;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'billingSystemId': number;

  /**  */
  'onboardingConfigurationId': number;

  /**  */
  'billingSystemVersion': string;

  /**  */
  'reasonForConfigChange': string;

  /**  */
  'payerLoginMode': string;

  /**  */
  'embeddedHost': string;

  /**  */
  'uploadAccountsDueDateDay': number;

  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['address'] = data['address'];
    this['phoneNumber'] = data['phoneNumber'];
    this['ivrNumber'] = data['ivrNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['accountHelpUrl'] = data['accountHelpUrl'];
    this['billingAddressCollectionType'] = data['billingAddressCollectionType'];
    this['currencyCode'] = data['currencyCode'];
    this['timezone'] = data['timezone'];
    this['notes'] = data['notes'];
    this['primaryColor'] = data['primaryColor'];
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['billTemplateIdentifier'] = data['billTemplateIdentifier'];
    this['pdfTemplateIdentifier'] = data['pdfTemplateIdentifier'];
    this['excelTemplateIdentifier'] = data['excelTemplateIdentifier'];
    this['accountSyncStrategy'] = data['accountSyncStrategy'];
    this['accountSyncUrl'] = data['accountSyncUrl'];
    this['accountSyncApiKey'] = data['accountSyncApiKey'];
    this['trustedDomains'] = data['trustedDomains'];
    this['readingsIdentifier'] = data['readingsIdentifier'];
    this['readingsSyncUrl'] = data['readingsSyncUrl'];
    this['readingsSyncApiKey'] = data['readingsSyncApiKey'];
    this['syncPayments'] = data['syncPayments'];
    this['paymentSyncUrl'] = data['paymentSyncUrl'];
    this['paymentSyncSecret'] = data['paymentSyncSecret'];
    this['paymentSyncPayload'] = data['paymentSyncPayload'];
    this['paymentSyncOnSucceeded'] = data['paymentSyncOnSucceeded'];
    this['paymentSyncOnDeclined'] = data['paymentSyncOnDeclined'];
    this['paymentSyncOnRefunded'] = data['paymentSyncOnRefunded'];
    this['paymentSyncOnVoided'] = data['paymentSyncOnVoided'];
    this['quickPayConfigId'] = data['quickPayConfigId'];
    this['ivrConfigId'] = data['ivrConfigId'];
    this['pointOfSaleConfigId'] = data['pointOfSaleConfigId'];
    this['autopayConfigId'] = data['autopayConfigId'];
    this['minPaymentAmount'] = data['minPaymentAmount'];
    this['maxPaymentAmount'] = data['maxPaymentAmount'];
    this['maxRefundAmount'] = data['maxRefundAmount'];
    this['agencyId'] = data['agencyId'];
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['testAccount'] = data['testAccount'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['billingSystemId'] = data['billingSystemId'];
    this['onboardingConfigurationId'] = data['onboardingConfigurationId'];
    this['billingSystemVersion'] = data['billingSystemVersion'];
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
    this['payerLoginMode'] = data['payerLoginMode'];
    this['embeddedHost'] = data['embeddedHost'];
    this['uploadAccountsDueDateDay'] = data['uploadAccountsDueDateDay'];
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
  }
}

export class BusinessUnitHeaderDto {
  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'primaryColor': string;

  /**  */
  'name': string;

  /**  */
  'organizationName': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'address': Address;

  constructor(data: undefined | any = {}) {
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['primaryColor'] = data['primaryColor'];
    this['name'] = data['name'];
    this['organizationName'] = data['organizationName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['address'] = data['address'];
  }
}

export class BusinessUnitPaymentChannelSummaryDto {
  /**  */
  'id': number;

  /**  */
  'quickPay': PaymentChannelSummaryDto;

  /**  */
  'ivr': PaymentChannelSummaryDto;

  /**  */
  'pointOfSale': PaymentChannelSummaryDto;

  /**  */
  'autopay': PaymentChannelSummaryDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['quickPay'] = data['quickPay'];
    this['ivr'] = data['ivr'];
    this['pointOfSale'] = data['pointOfSale'];
    this['autopay'] = data['autopay'];
  }
}

export class BusinessUnitSettingsUpdatedEventPayload {
  /**  */
  'oldSettings': string;

  /**  */
  'newSettings': string;

  /**  */
  'userName': string;

  /**  */
  'userEmail': string;

  /**  */
  'businessUnitHeader': string;

  /**  */
  'businessUnitSubHeader': string;

  constructor(data: undefined | any = {}) {
    this['oldSettings'] = data['oldSettings'];
    this['newSettings'] = data['newSettings'];
    this['userName'] = data['userName'];
    this['userEmail'] = data['userEmail'];
    this['businessUnitHeader'] = data['businessUnitHeader'];
    this['businessUnitSubHeader'] = data['businessUnitSubHeader'];
  }
}

export class BusinessUnitSummaryDto {
  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'primaryColor': string;

  /**  */
  'name': string;

  /**  */
  'organizationName': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'address': Address;

  /**  */
  'id': number;

  /**  */
  'ivrNumber': string;

  /**  */
  'slug': string;

  /**  */
  'accountHelpUrl': string;

  /**  */
  'organizationId': number;

  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'billingSystemName': string;

  constructor(data: undefined | any = {}) {
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['primaryColor'] = data['primaryColor'];
    this['name'] = data['name'];
    this['organizationName'] = data['organizationName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['address'] = data['address'];
    this['id'] = data['id'];
    this['ivrNumber'] = data['ivrNumber'];
    this['slug'] = data['slug'];
    this['accountHelpUrl'] = data['accountHelpUrl'];
    this['organizationId'] = data['organizationId'];
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['billingSystemName'] = data['billingSystemName'];
  }
}

export class BusinessUnitTermsOfServices {
  /**  */
  'achTerms': string;

  /**  */
  'achTermsWithServiceFee': string;

  /**  */
  'autoPayEnrollment': string;

  /**  */
  'creditCardTerms': string;

  /**  */
  'creditCardTermsWithServiceFee': string;

  /**  */
  'electronicDebitAuthorization': string;

  /**  */
  'termsOfUse': string;

  /**  */
  'paperlessBillingEnrollment': string;

  constructor(data: undefined | any = {}) {
    this['achTerms'] = data['achTerms'];
    this['achTermsWithServiceFee'] = data['achTermsWithServiceFee'];
    this['autoPayEnrollment'] = data['autoPayEnrollment'];
    this['creditCardTerms'] = data['creditCardTerms'];
    this['creditCardTermsWithServiceFee'] = data['creditCardTermsWithServiceFee'];
    this['electronicDebitAuthorization'] = data['electronicDebitAuthorization'];
    this['termsOfUse'] = data['termsOfUse'];
    this['paperlessBillingEnrollment'] = data['paperlessBillingEnrollment'];
  }
}

export class CardSourceDto {
  /**  */
  'accountHolderName': string;

  /**  */
  'accountNumberMasked': string;

  /**  */
  'accountType': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'accountNumberLast4': string;

  constructor(data: undefined | any = {}) {
    this['accountHolderName'] = data['accountHolderName'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
    this['accountType'] = data['accountType'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
  }
}

export class ChargebackRequest {
  /**  */
  'paymentId': number;

  /**  */
  'chargebackDate': Date;

  /**  */
  'reason': string;

  constructor(data: undefined | any = {}) {
    this['paymentId'] = data['paymentId'];
    this['chargebackDate'] = data['chargebackDate'];
    this['reason'] = data['reason'];
  }
}

export class Charges {
  /**  */
  'openCharges': OpenCharge[];

  /**  */
  'accounts': AccountCharge[];

  /**  */
  'products': ProductCharge[];

  constructor(data: undefined | any = {}) {
    this['openCharges'] = data['openCharges'];
    this['accounts'] = data['accounts'];
    this['products'] = data['products'];
  }
}

export class CheckFtpForFile_Config {
  /**  */
  'directory': string;

  /**  */
  'archiveDirectory': string;

  /**  */
  'fileNameFilter': string;

  /**  */
  'downloadMultiple': boolean;

  constructor(data: undefined | any = {}) {
    this['directory'] = data['directory'];
    this['archiveDirectory'] = data['archiveDirectory'];
    this['fileNameFilter'] = data['fileNameFilter'];
    this['downloadMultiple'] = data['downloadMultiple'];
  }
}

export class CheckoutLinkGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'metaJson': string;

  /**  */
  'referenceId': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['metaJson'] = data['metaJson'];
    this['referenceId'] = data['referenceId'];
    this['id'] = data['id'];
  }
}

export class ClientAccountDto {
  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'name': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'note': string;

  /**  */
  'metaJson': string;

  /**  */
  'address': OptionalAddress;

  constructor(data: undefined | any = {}) {
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['name'] = data['name'];
    this['phoneNumber'] = data['phoneNumber'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['note'] = data['note'];
    this['metaJson'] = data['metaJson'];
    this['address'] = data['address'];
  }
}

export class ClientUserDto {
  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
  }
}

export class Conditional_ConditionResultActions {
  /**  */
  'onResultTrue': string;

  /**  */
  'onResultFalse': string;

  constructor(data: undefined | any = {}) {
    this['onResultTrue'] = data['onResultTrue'];
    this['onResultFalse'] = data['onResultFalse'];
  }
}

export class Conditional_Config {
  /**  */
  'predicateCondition': Conditional_PredicateCondition;

  /**  */
  'codeCondition': string;

  /**  */
  'conditionResultActions': Conditional_ConditionResultActions;

  /**  */
  'conditionalType': string;

  constructor(data: undefined | any = {}) {
    this['predicateCondition'] = data['predicateCondition'];
    this['codeCondition'] = data['codeCondition'];
    this['conditionResultActions'] = data['conditionResultActions'];
    this['conditionalType'] = data['conditionalType'];
  }
}

export class Conditional_FilterCondition {
  /**  */
  'propertyName': string;

  /**  */
  'comparisonOperator': string;

  /**  */
  'comparisonValue': string;

  constructor(data: undefined | any = {}) {
    this['propertyName'] = data['propertyName'];
    this['comparisonOperator'] = data['comparisonOperator'];
    this['comparisonValue'] = data['comparisonValue'];
  }
}

export class Conditional_PredicateCondition {
  /**  */
  'filterConditions': Conditional_FilterCondition[];

  /**  */
  'logicalOperatorType': string;

  constructor(data: undefined | any = {}) {
    this['filterConditions'] = data['filterConditions'];
    this['logicalOperatorType'] = data['logicalOperatorType'];
  }
}

export class CreateAccountPaymentSourceRequest {
  /**  */
  'accountType': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'achAccountNumber': string;

  /**  */
  'achRoutingNumber': string;

  /**  */
  'accountNumberToken': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'cardExpiration': Date;

  /**  */
  'cardEntryMethod': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'achConfirmAccountNumber': string;

  /**  */
  'nickname': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountNumberMasked': string;

  constructor(data: undefined | any = {}) {
    this['accountType'] = data['accountType'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['achAccountNumber'] = data['achAccountNumber'];
    this['achRoutingNumber'] = data['achRoutingNumber'];
    this['accountNumberToken'] = data['accountNumberToken'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['cardExpiration'] = data['cardExpiration'];
    this['cardEntryMethod'] = data['cardEntryMethod'];
    this['accountHolderName'] = data['accountHolderName'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['achConfirmAccountNumber'] = data['achConfirmAccountNumber'];
    this['nickname'] = data['nickname'];
    this['address'] = data['address'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
  }
}

export class CreateAccountRequest {
  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'lateFee': number;

  /**  */
  'pastDueAmountMinor': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'emailAddress': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'note': string;

  /**  */
  'meta': string;

  /**  */
  'createMethod': string;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'blockCreditCardPayment': boolean;

  /**  */
  'blockAchPayment': boolean;

  /**  */
  'paperlessBilling': boolean;

  /**  */
  'metaFields': object;

  constructor(data: undefined | any = {}) {
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['pastDueAmountMinor'] = data['pastDueAmountMinor'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['phoneNumber'] = data['phoneNumber'];
    this['emailAddress'] = data['emailAddress'];
    this['address'] = data['address'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['note'] = data['note'];
    this['meta'] = data['meta'];
    this['createMethod'] = data['createMethod'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['blockCreditCardPayment'] = data['blockCreditCardPayment'];
    this['blockAchPayment'] = data['blockAchPayment'];
    this['paperlessBilling'] = data['paperlessBilling'];
    this['metaFields'] = data['metaFields'];
  }
}

export class CreateAgencyRequest {
  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
  }
}

export class CreateBillingCycleConfigRequest {
  /**  */
  'identifier': string;

  /**  */
  'notificationStrategyType': string;

  /**  */
  'billType': string;

  /**  */
  'allowInserts': boolean;

  /**  */
  'isBillPrinted': boolean;

  /**  */
  'isDisabled': boolean;

  /**  */
  'executionOrder': number;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['notificationStrategyType'] = data['notificationStrategyType'];
    this['billType'] = data['billType'];
    this['allowInserts'] = data['allowInserts'];
    this['isBillPrinted'] = data['isBillPrinted'];
    this['isDisabled'] = data['isDisabled'];
    this['executionOrder'] = data['executionOrder'];
  }
}

export class CreateBillingSystemRequest {
  /**  */
  'name': string;

  /**  */
  'notes': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['notes'] = data['notes'];
  }
}

export class CreateBusinessUnitRequest {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'address': Address;

  /**  */
  'phoneNumber': string;

  /**  */
  'ivrNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'accountHelpUrl': string;

  /**  */
  'billingAddressCollectionType': string;

  /**  */
  'currencyCode': string;

  /**  */
  'timezone': string;

  /**  */
  'notes': string;

  /**  */
  'primaryColor': string;

  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'billTemplateIdentifier': string;

  /**  */
  'pdfTemplateIdentifier': string;

  /**  */
  'excelTemplateIdentifier': string;

  /**  */
  'accountSyncStrategy': string;

  /**  */
  'accountSyncUrl': string;

  /**  */
  'accountSyncApiKey': string;

  /**  */
  'trustedDomains': string;

  /**  */
  'readingsIdentifier': string;

  /**  */
  'readingsSyncUrl': string;

  /**  */
  'readingsSyncApiKey': string;

  /**  */
  'syncPayments': boolean;

  /**  */
  'paymentSyncUrl': string;

  /**  */
  'paymentSyncSecret': string;

  /**  */
  'paymentSyncPayload': string;

  /**  */
  'paymentSyncOnSucceeded': boolean;

  /**  */
  'paymentSyncOnDeclined': boolean;

  /**  */
  'paymentSyncOnRefunded': boolean;

  /**  */
  'paymentSyncOnVoided': boolean;

  /**  */
  'quickPayConfigId': number;

  /**  */
  'ivrConfigId': number;

  /**  */
  'pointOfSaleConfigId': number;

  /**  */
  'autopayConfigId': number;

  /**  */
  'minPaymentAmount': number;

  /**  */
  'maxPaymentAmount': number;

  /**  */
  'maxRefundAmount': number;

  /**  */
  'agencyId': number;

  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'testAccount': boolean;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'billingSystemId': number;

  /**  */
  'onboardingConfigurationId': number;

  /**  */
  'billingSystemVersion': string;

  /**  */
  'reasonForConfigChange': string;

  /**  */
  'payerLoginMode': string;

  /**  */
  'embeddedHost': string;

  /**  */
  'uploadAccountsDueDateDay': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['address'] = data['address'];
    this['phoneNumber'] = data['phoneNumber'];
    this['ivrNumber'] = data['ivrNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['accountHelpUrl'] = data['accountHelpUrl'];
    this['billingAddressCollectionType'] = data['billingAddressCollectionType'];
    this['currencyCode'] = data['currencyCode'];
    this['timezone'] = data['timezone'];
    this['notes'] = data['notes'];
    this['primaryColor'] = data['primaryColor'];
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['billTemplateIdentifier'] = data['billTemplateIdentifier'];
    this['pdfTemplateIdentifier'] = data['pdfTemplateIdentifier'];
    this['excelTemplateIdentifier'] = data['excelTemplateIdentifier'];
    this['accountSyncStrategy'] = data['accountSyncStrategy'];
    this['accountSyncUrl'] = data['accountSyncUrl'];
    this['accountSyncApiKey'] = data['accountSyncApiKey'];
    this['trustedDomains'] = data['trustedDomains'];
    this['readingsIdentifier'] = data['readingsIdentifier'];
    this['readingsSyncUrl'] = data['readingsSyncUrl'];
    this['readingsSyncApiKey'] = data['readingsSyncApiKey'];
    this['syncPayments'] = data['syncPayments'];
    this['paymentSyncUrl'] = data['paymentSyncUrl'];
    this['paymentSyncSecret'] = data['paymentSyncSecret'];
    this['paymentSyncPayload'] = data['paymentSyncPayload'];
    this['paymentSyncOnSucceeded'] = data['paymentSyncOnSucceeded'];
    this['paymentSyncOnDeclined'] = data['paymentSyncOnDeclined'];
    this['paymentSyncOnRefunded'] = data['paymentSyncOnRefunded'];
    this['paymentSyncOnVoided'] = data['paymentSyncOnVoided'];
    this['quickPayConfigId'] = data['quickPayConfigId'];
    this['ivrConfigId'] = data['ivrConfigId'];
    this['pointOfSaleConfigId'] = data['pointOfSaleConfigId'];
    this['autopayConfigId'] = data['autopayConfigId'];
    this['minPaymentAmount'] = data['minPaymentAmount'];
    this['maxPaymentAmount'] = data['maxPaymentAmount'];
    this['maxRefundAmount'] = data['maxRefundAmount'];
    this['agencyId'] = data['agencyId'];
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['testAccount'] = data['testAccount'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['billingSystemId'] = data['billingSystemId'];
    this['onboardingConfigurationId'] = data['onboardingConfigurationId'];
    this['billingSystemVersion'] = data['billingSystemVersion'];
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
    this['payerLoginMode'] = data['payerLoginMode'];
    this['embeddedHost'] = data['embeddedHost'];
    this['uploadAccountsDueDateDay'] = data['uploadAccountsDueDateDay'];
  }
}

export class CreateCheckoutLinkRequest {
  /**  */
  'businessUnitId': number;

  /**  */
  'metaJson': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['metaJson'] = data['metaJson'];
  }
}

export class CreateCheckoutPaymentIntentRequest {
  /**  */
  'businessUnitId': number;

  /**  */
  'amount': number;

  /**  */
  'paymentSource': NewPaymentSource;

  /**  */
  'accounts': AccountCharge[];

  /**  */
  'products': ProductCharge[];

  /**  */
  'paymentFields': object;

  /**  */
  'recaptchaToken': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['amount'] = data['amount'];
    this['paymentSource'] = data['paymentSource'];
    this['accounts'] = data['accounts'];
    this['products'] = data['products'];
    this['paymentFields'] = data['paymentFields'];
    this['recaptchaToken'] = data['recaptchaToken'];
  }
}

export class CreateCustomGatewayMessageRequest {
  /**  */
  'originalMessage': string;

  /**  */
  'replacementMessage': string;

  /**  */
  'transactionStatus': string;

  /**  */
  'gateway': string;

  constructor(data: undefined | any = {}) {
    this['originalMessage'] = data['originalMessage'];
    this['replacementMessage'] = data['replacementMessage'];
    this['transactionStatus'] = data['transactionStatus'];
    this['gateway'] = data['gateway'];
  }
}

export class CreateCustomerPaymentSourceRequest {
  /**  */
  'accountType': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'achAccountNumber': string;

  /**  */
  'achRoutingNumber': string;

  /**  */
  'accountNumberToken': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'cardExpiration': Date;

  /**  */
  'cardEntryMethod': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'achConfirmAccountNumber': string;

  /**  */
  'nickname': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountNumberMasked': string;

  /**  */
  'businessUnitId': number;

  constructor(data: undefined | any = {}) {
    this['accountType'] = data['accountType'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['achAccountNumber'] = data['achAccountNumber'];
    this['achRoutingNumber'] = data['achRoutingNumber'];
    this['accountNumberToken'] = data['accountNumberToken'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['cardExpiration'] = data['cardExpiration'];
    this['cardEntryMethod'] = data['cardEntryMethod'];
    this['accountHolderName'] = data['accountHolderName'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['achConfirmAccountNumber'] = data['achConfirmAccountNumber'];
    this['nickname'] = data['nickname'];
    this['address'] = data['address'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
    this['businessUnitId'] = data['businessUnitId'];
  }
}

export class CreateCustomerRequest {
  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'accountId': number;

  /**  */
  'organizationId': number;

  /**  */
  'customerId': number;

  /**  */
  'role': string;

  /**  */
  'isDeleted': boolean;

  /**  */
  'paymentSourceId': number;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['accountId'] = data['accountId'];
    this['organizationId'] = data['organizationId'];
    this['customerId'] = data['customerId'];
    this['role'] = data['role'];
    this['isDeleted'] = data['isDeleted'];
    this['paymentSourceId'] = data['paymentSourceId'];
  }
}

export class CreateDeviceRequest {
  /**  */
  'serialNumber': string;

  /**  */
  'model': string;

  /**  */
  'manufacturer': string;

  /**  */
  'tokenizationType': string;

  /**  */
  'notes': string;

  /**  */
  'quantity': number;

  /**  */
  'organizationId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'modifiedBy': string;

  /**  */
  'meta': string;

  /**  */
  'businessUnitIds': number[];

  constructor(data: undefined | any = {}) {
    this['serialNumber'] = data['serialNumber'];
    this['model'] = data['model'];
    this['manufacturer'] = data['manufacturer'];
    this['tokenizationType'] = data['tokenizationType'];
    this['notes'] = data['notes'];
    this['quantity'] = data['quantity'];
    this['organizationId'] = data['organizationId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['modifiedBy'] = data['modifiedBy'];
    this['meta'] = data['meta'];
    this['businessUnitIds'] = data['businessUnitIds'];
  }
}

export class CreateEmbeddedPaymentSourceRequest {
  /**  */
  'accountType': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'achAccountNumber': string;

  /**  */
  'achRoutingNumber': string;

  /**  */
  'accountNumberToken': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'cardExpiration': Date;

  /**  */
  'cardEntryMethod': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'achConfirmAccountNumber': string;

  /**  */
  'nickname': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountNumberMasked': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'recaptchaToken': string;

  /**  */
  'customerEmailAddress': string;

  constructor(data: undefined | any = {}) {
    this['accountType'] = data['accountType'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['achAccountNumber'] = data['achAccountNumber'];
    this['achRoutingNumber'] = data['achRoutingNumber'];
    this['accountNumberToken'] = data['accountNumberToken'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['cardExpiration'] = data['cardExpiration'];
    this['cardEntryMethod'] = data['cardEntryMethod'];
    this['accountHolderName'] = data['accountHolderName'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['achConfirmAccountNumber'] = data['achConfirmAccountNumber'];
    this['nickname'] = data['nickname'];
    this['address'] = data['address'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
    this['businessUnitId'] = data['businessUnitId'];
    this['recaptchaToken'] = data['recaptchaToken'];
    this['customerEmailAddress'] = data['customerEmailAddress'];
  }
}

export class CreateExternalLinkConfigRequest {
  /**  */
  'identifier': string;

  /**  */
  'showOnCustomerDashboard': boolean;

  /**  */
  'payloadConfigurationJson': string;

  /**  */
  'url': string;

  /**  */
  'isGeneratedLink': boolean;

  /**  */
  'httpMethod': string;

  /**  */
  'display': string;

  /**  */
  'description': string;

  /**  */
  'functionTriggerId': number;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['showOnCustomerDashboard'] = data['showOnCustomerDashboard'];
    this['payloadConfigurationJson'] = data['payloadConfigurationJson'];
    this['url'] = data['url'];
    this['isGeneratedLink'] = data['isGeneratedLink'];
    this['httpMethod'] = data['httpMethod'];
    this['display'] = data['display'];
    this['description'] = data['description'];
    this['functionTriggerId'] = data['functionTriggerId'];
  }
}

export class CreateInternalAccountNotesRequest {
  /**  */
  'accountId': number;

  /**  */
  'notes': string;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['notes'] = data['notes'];
  }
}

export class CreateIvrPaymentIntentRequest {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountId': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountId'] = data['accountId'];
  }
}

export class CreateJobRequest {
  /**  */
  'description': string;

  /**  */
  'disabled': boolean;

  /**  */
  'businessUnitId': number;

  /**  */
  'jobContextJson': string;

  /**  */
  'tags': string;

  /**  */
  'notes': string;

  /**  */
  'tasks': JobTaskDto[];

  constructor(data: undefined | any = {}) {
    this['description'] = data['description'];
    this['disabled'] = data['disabled'];
    this['businessUnitId'] = data['businessUnitId'];
    this['jobContextJson'] = data['jobContextJson'];
    this['tags'] = data['tags'];
    this['notes'] = data['notes'];
    this['tasks'] = data['tasks'];
  }
}

export class CreateJobScheduleRequest {
  /**  */
  'rRule': string;

  /**  */
  'timeZone': string;

  /**  */
  'nextExecutionTimeUtc': Date;

  /**  */
  'lastExecutionTimeUtc': Date;

  constructor(data: undefined | any = {}) {
    this['rRule'] = data['rRule'];
    this['timeZone'] = data['timeZone'];
    this['nextExecutionTimeUtc'] = data['nextExecutionTimeUtc'];
    this['lastExecutionTimeUtc'] = data['lastExecutionTimeUtc'];
  }
}

export class CreateJobTaskRequest {
  /**  */
  'type': string;

  /**  */
  'description': string;

  /**  */
  'settingsJson': string;

  /**  */
  'executionOrder': number;

  /**  */
  'executionCondition': string;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['description'] = data['description'];
    this['settingsJson'] = data['settingsJson'];
    this['executionOrder'] = data['executionOrder'];
    this['executionCondition'] = data['executionCondition'];
  }
}

export class CreateJobTemplateRequest {
  /**  */
  'name': string;

  /**  */
  'templateJson': string;

  /**  */
  'jobId': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['templateJson'] = data['templateJson'];
    this['jobId'] = data['jobId'];
  }
}

export class CreateMerchantAccountRequest {
  /**  */
  'name': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'currencyCode': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'monthlyProcessingLimit': number;

  /**  */
  'timezone': string;

  /**  */
  'batchTime': TimeSpan;

  /**  */
  'supportsSettlement': boolean;

  /**  */
  'supportsFunding': boolean;

  /**  */
  'feeOnly': boolean;

  /**  */
  'processorId': string;

  /**  */
  'notes': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'nachaConfigId': number;

  /**  */
  'tokenizerConfig': object;

  /**  */
  'acceptedCardBrands': AcceptedCardBrands;

  /**  */
  'organizationIds': number[];

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['merchantNumber'] = data['merchantNumber'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['currencyCode'] = data['currencyCode'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['monthlyProcessingLimit'] = data['monthlyProcessingLimit'];
    this['timezone'] = data['timezone'];
    this['batchTime'] = data['batchTime'];
    this['supportsSettlement'] = data['supportsSettlement'];
    this['supportsFunding'] = data['supportsFunding'];
    this['feeOnly'] = data['feeOnly'];
    this['processorId'] = data['processorId'];
    this['notes'] = data['notes'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['nachaConfigId'] = data['nachaConfigId'];
    this['tokenizerConfig'] = data['tokenizerConfig'];
    this['acceptedCardBrands'] = data['acceptedCardBrands'];
    this['organizationIds'] = data['organizationIds'];
  }
}

export class CreateMessageRequest {
  /**  */
  'type': string;

  /**  */
  'messageBody': string;

  /**  */
  'organizationId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'paymentChannelType': string;

  /**  */
  'showOnLogin': boolean;

  /**  */
  'showOnPayment': boolean;

  /**  */
  'showOnConfirmation': boolean;

  /**  */
  'showOnAdminPortal': boolean;

  /**  */
  'showOnBackOfficeAutopayPayerModal': boolean;

  /**  */
  'showDate': Date;

  /**  */
  'removeDate': Date;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['messageBody'] = data['messageBody'];
    this['organizationId'] = data['organizationId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paymentChannelType'] = data['paymentChannelType'];
    this['showOnLogin'] = data['showOnLogin'];
    this['showOnPayment'] = data['showOnPayment'];
    this['showOnConfirmation'] = data['showOnConfirmation'];
    this['showOnAdminPortal'] = data['showOnAdminPortal'];
    this['showOnBackOfficeAutopayPayerModal'] = data['showOnBackOfficeAutopayPayerModal'];
    this['showDate'] = data['showDate'];
    this['removeDate'] = data['removeDate'];
  }
}

export class CreateNachaConfigRequest {
  /**  */
  'name': string;

  /**  */
  'encryptionType': string;

  /**  */
  'immediateOrigin': string;

  /**  */
  'originatingCompanyId': string;

  /**  */
  'originatingCompanyName': string;

  /**  */
  'destinationBankRoutingNumber': string;

  /**  */
  'destinationBankName': string;

  /**  */
  'companyDiscretionaryData': string;

  /**  */
  'companyEntryDescription': string;

  /**  */
  'effectiveEntryDateCutoffTime': TimeSpan;

  /**  */
  'effectiveEntryDateCutoffTimezone': string;

  /**  */
  'secCodeOverride': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['encryptionType'] = data['encryptionType'];
    this['immediateOrigin'] = data['immediateOrigin'];
    this['originatingCompanyId'] = data['originatingCompanyId'];
    this['originatingCompanyName'] = data['originatingCompanyName'];
    this['destinationBankRoutingNumber'] = data['destinationBankRoutingNumber'];
    this['destinationBankName'] = data['destinationBankName'];
    this['companyDiscretionaryData'] = data['companyDiscretionaryData'];
    this['companyEntryDescription'] = data['companyEntryDescription'];
    this['effectiveEntryDateCutoffTime'] = data['effectiveEntryDateCutoffTime'];
    this['effectiveEntryDateCutoffTimezone'] = data['effectiveEntryDateCutoffTimezone'];
    this['secCodeOverride'] = data['secCodeOverride'];
  }
}

export class CreateNewTermsOfServiceRequest {
  /**  */
  'acknowledgementType': string;

  /**  */
  'identifier': string;

  /**  */
  'description': string;

  /**  */
  'terms': string;

  /**  */
  'isDefault': boolean;

  /**  */
  'businessUnitId': number;

  constructor(data: undefined | any = {}) {
    this['acknowledgementType'] = data['acknowledgementType'];
    this['identifier'] = data['identifier'];
    this['description'] = data['description'];
    this['terms'] = data['terms'];
    this['isDefault'] = data['isDefault'];
    this['businessUnitId'] = data['businessUnitId'];
  }
}

export class CreateOrganizationRequest {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'agencyId': number;

  /**  */
  'notes': string;

  /**  */
  'paystarAccountManagerId': number;

  /**  */
  'paystarAccountManager': string;

  /**  */
  'hubSpotIdentifier': string;

  /**  */
  'hubSpotUrl': string;

  /**  */
  'businessUnit': BusinessUnitDto;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['agencyId'] = data['agencyId'];
    this['notes'] = data['notes'];
    this['paystarAccountManagerId'] = data['paystarAccountManagerId'];
    this['paystarAccountManager'] = data['paystarAccountManager'];
    this['hubSpotIdentifier'] = data['hubSpotIdentifier'];
    this['hubSpotUrl'] = data['hubSpotUrl'];
    this['businessUnit'] = data['businessUnit'];
  }
}

export class CreatePaymentGatewayConfigRequest {
  /**  */
  'paymentGatewayType': string;

  /**  */
  'name': string;

  /**  */
  'url': string;

  /**  */
  'username': string;

  /**  */
  'apiKey': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'emvDelaySeconds': number;

  /**  */
  'password': string;

  constructor(data: undefined | any = {}) {
    this['paymentGatewayType'] = data['paymentGatewayType'];
    this['name'] = data['name'];
    this['url'] = data['url'];
    this['username'] = data['username'];
    this['apiKey'] = data['apiKey'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['emvDelaySeconds'] = data['emvDelaySeconds'];
    this['password'] = data['password'];
  }
}

export class CreatePaymentIntentRequest {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountId': number;

  /**  */
  'channel': string;

  /**  */
  'clientReference': string;

  /**  */
  'charges': Charges;

  /**  */
  'paymentSource': PaymentIntentPaymentSource;

  /**  */
  'paymentFields': object;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountId'] = data['accountId'];
    this['channel'] = data['channel'];
    this['clientReference'] = data['clientReference'];
    this['charges'] = data['charges'];
    this['paymentSource'] = data['paymentSource'];
    this['paymentFields'] = data['paymentFields'];
  }
}

export class CreatePaymentSessionSourceFromPlaidRequest {
  /**  */
  'plaidAccountId': string;

  /**  */
  'plaidExchangeToken': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'savePaymentSource': boolean;

  constructor(data: undefined | any = {}) {
    this['plaidAccountId'] = data['plaidAccountId'];
    this['plaidExchangeToken'] = data['plaidExchangeToken'];
    this['accountHolderName'] = data['accountHolderName'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['savePaymentSource'] = data['savePaymentSource'];
  }
}

export class CreatePlaidPaymentSourceRequest {
  /**  */
  'plaidAccountId': string;

  /**  */
  'plaidExchangeToken': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'businessUnitId': number;

  constructor(data: undefined | any = {}) {
    this['plaidAccountId'] = data['plaidAccountId'];
    this['plaidExchangeToken'] = data['plaidExchangeToken'];
    this['accountHolderName'] = data['accountHolderName'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['businessUnitId'] = data['businessUnitId'];
  }
}

export class CreateProductRequest {
  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'sku': string;

  /**  */
  'price': number;

  /**  */
  'allowPriceModification': boolean;

  /**  */
  'active': boolean;

  /**  */
  'required': boolean;

  /**  */
  'paymentChannel': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['sku'] = data['sku'];
    this['price'] = data['price'];
    this['allowPriceModification'] = data['allowPriceModification'];
    this['active'] = data['active'];
    this['required'] = data['required'];
    this['paymentChannel'] = data['paymentChannel'];
  }
}

export class CreateTermsOfServiceRequest {
  /**  */
  'acknowledgementType': string;

  /**  */
  'identifier': string;

  /**  */
  'description': string;

  /**  */
  'terms': string;

  /**  */
  'isDefault': boolean;

  /**  */
  'businessUnitId': number;

  constructor(data: undefined | any = {}) {
    this['acknowledgementType'] = data['acknowledgementType'];
    this['identifier'] = data['identifier'];
    this['description'] = data['description'];
    this['terms'] = data['terms'];
    this['isDefault'] = data['isDefault'];
    this['businessUnitId'] = data['businessUnitId'];
  }
}

export class CreateTicketEventRequest {
  /**  */
  'eventType': string;

  /**  */
  'text': string;

  /**  */
  'metaJson': string;

  constructor(data: undefined | any = {}) {
    this['eventType'] = data['eventType'];
    this['text'] = data['text'];
    this['metaJson'] = data['metaJson'];
  }
}

export class CreateTicketRequest {
  /**  */
  'title': string;

  /**  */
  'description': string;

  /**  */
  'category': string;

  /**  */
  'status': string;

  /**  */
  'metaJson': string;

  /**  */
  'assignedUserId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'jobId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'followerIds': number[];

  /**  */
  'createdUrl': string;

  constructor(data: undefined | any = {}) {
    this['title'] = data['title'];
    this['description'] = data['description'];
    this['category'] = data['category'];
    this['status'] = data['status'];
    this['metaJson'] = data['metaJson'];
    this['assignedUserId'] = data['assignedUserId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['jobId'] = data['jobId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['followerIds'] = data['followerIds'];
    this['createdUrl'] = data['createdUrl'];
  }
}

export class CreateUserRequest {
  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'accountId': number;

  /**  */
  'organizationId': number;

  /**  */
  'customerId': number;

  /**  */
  'role': string;

  /**  */
  'isDeleted': boolean;

  /**  */
  'claims': string[];

  /**  */
  'businessUnitIds': number[];

  /**  */
  'organizationIdRequired': boolean;

  /**  */
  'accountIdRequired': boolean;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['accountId'] = data['accountId'];
    this['organizationId'] = data['organizationId'];
    this['customerId'] = data['customerId'];
    this['role'] = data['role'];
    this['isDeleted'] = data['isDeleted'];
    this['claims'] = data['claims'];
    this['businessUnitIds'] = data['businessUnitIds'];
    this['organizationIdRequired'] = data['organizationIdRequired'];
    this['accountIdRequired'] = data['accountIdRequired'];
  }
}

export class CreditCardConfig {
  /**  */
  'enabled': boolean;

  /**  */
  'paymentMerchantAccountId': number;

  /**  */
  'feeMerchantAccountId': number;

  /**  */
  'gatewayType': string;

  /**  */
  'tokenizer': TokenizerConfig;

  /**  */
  'cardSwipeEnabled': boolean;

  /**  */
  'useExplicitMsrEncryption': boolean;

  /**  */
  'avsValidation': string;

  /**  */
  'acceptedCardBrands': AcceptedCardBrands;

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['paymentMerchantAccountId'] = data['paymentMerchantAccountId'];
    this['feeMerchantAccountId'] = data['feeMerchantAccountId'];
    this['gatewayType'] = data['gatewayType'];
    this['tokenizer'] = data['tokenizer'];
    this['cardSwipeEnabled'] = data['cardSwipeEnabled'];
    this['useExplicitMsrEncryption'] = data['useExplicitMsrEncryption'];
    this['avsValidation'] = data['avsValidation'];
    this['acceptedCardBrands'] = data['acceptedCardBrands'];
  }
}

export class Currency {
  /**  */
  'value': number;

  /**  */
  'code': string;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
    this['code'] = data['code'];
  }
}

export class CustomGatewayMessageDto {
  /**  */
  'originalMessage': string;

  /**  */
  'replacementMessage': string;

  /**  */
  'transactionStatus': string;

  /**  */
  'gateway': string;

  constructor(data: undefined | any = {}) {
    this['originalMessage'] = data['originalMessage'];
    this['replacementMessage'] = data['replacementMessage'];
    this['transactionStatus'] = data['transactionStatus'];
    this['gateway'] = data['gateway'];
  }
}

export class CustomGatewayMessageGetDto {
  /**  */
  'originalMessage': string;

  /**  */
  'replacementMessage': string;

  /**  */
  'transactionStatus': string;

  /**  */
  'gateway': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['originalMessage'] = data['originalMessage'];
    this['replacementMessage'] = data['replacementMessage'];
    this['transactionStatus'] = data['transactionStatus'];
    this['gateway'] = data['gateway'];
    this['id'] = data['id'];
  }
}

export class CustomerAccountGetDto {
  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'autopaySettings': AutopayEnrollmentSettings;

  /**  */
  'customerName': string;

  /**  */
  'maskedEmail': string;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['autopaySettings'] = data['autopaySettings'];
    this['customerName'] = data['customerName'];
    this['maskedEmail'] = data['maskedEmail'];
  }
}

export class CustomerDetailDto {
  /**  */
  'userId': number;

  /**  */
  'upcomingPaymentLeadTimeInDays': number;

  /**  */
  'notifyUpcomingPaymentEmail': boolean;

  /**  */
  'notifyUpcomingPaymentSms': boolean;

  /**  */
  'notifyNewBillEmail': boolean;

  /**  */
  'notifyNewBillSms': boolean;

  /**  */
  'isEmbedded': boolean;

  /**  */
  'id': number;

  /**  */
  'emailAddress': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'role': string;

  /**  */
  'accountIds': number[];

  /**  */
  'autopayEnabledAccountIds': number[];

  /**  */
  'autopayEnabled': boolean;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'fullName': string;

  /**  */
  'isLinkedEmbeddedUser': boolean;

  constructor(data: undefined | any = {}) {
    this['userId'] = data['userId'];
    this['upcomingPaymentLeadTimeInDays'] = data['upcomingPaymentLeadTimeInDays'];
    this['notifyUpcomingPaymentEmail'] = data['notifyUpcomingPaymentEmail'];
    this['notifyUpcomingPaymentSms'] = data['notifyUpcomingPaymentSms'];
    this['notifyNewBillEmail'] = data['notifyNewBillEmail'];
    this['notifyNewBillSms'] = data['notifyNewBillSms'];
    this['isEmbedded'] = data['isEmbedded'];
    this['id'] = data['id'];
    this['emailAddress'] = data['emailAddress'];
    this['phoneNumber'] = data['phoneNumber'];
    this['role'] = data['role'];
    this['accountIds'] = data['accountIds'];
    this['autopayEnabledAccountIds'] = data['autopayEnabledAccountIds'];
    this['autopayEnabled'] = data['autopayEnabled'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['fullName'] = data['fullName'];
    this['isLinkedEmbeddedUser'] = data['isLinkedEmbeddedUser'];
  }
}

export class CustomerEmailLogDto {
  /**  */
  'fromEmail': string;

  /**  */
  'toEmail': string;

  /**  */
  'emailData': string;

  /**  */
  'emailType': string;

  /**  */
  'createdDate': Date;

  constructor(data: undefined | any = {}) {
    this['fromEmail'] = data['fromEmail'];
    this['toEmail'] = data['toEmail'];
    this['emailData'] = data['emailData'];
    this['emailType'] = data['emailType'];
    this['createdDate'] = data['createdDate'];
  }
}

export class CustomerGetDto {
  /**  */
  'userId': number;

  /**  */
  'upcomingPaymentLeadTimeInDays': number;

  /**  */
  'notifyUpcomingPaymentEmail': boolean;

  /**  */
  'notifyUpcomingPaymentSms': boolean;

  /**  */
  'notifyNewBillEmail': boolean;

  /**  */
  'notifyNewBillSms': boolean;

  /**  */
  'isEmbedded': boolean;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['userId'] = data['userId'];
    this['upcomingPaymentLeadTimeInDays'] = data['upcomingPaymentLeadTimeInDays'];
    this['notifyUpcomingPaymentEmail'] = data['notifyUpcomingPaymentEmail'];
    this['notifyUpcomingPaymentSms'] = data['notifyUpcomingPaymentSms'];
    this['notifyNewBillEmail'] = data['notifyNewBillEmail'];
    this['notifyNewBillSms'] = data['notifyNewBillSms'];
    this['isEmbedded'] = data['isEmbedded'];
    this['id'] = data['id'];
  }
}

export class CustomerSearchDto {
  /**  */
  'customerId': string;

  /**  */
  'userName': string;

  /**  */
  'email': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountId': number;

  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['customerId'] = data['customerId'];
    this['userName'] = data['userName'];
    this['email'] = data['email'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountId'] = data['accountId'];
    this['name'] = data['name'];
  }
}

export class DependentFormResponseDto {
  /**  */
  'formConfigurationId': number;

  /**  */
  'formResponseIdentifier': string;

  constructor(data: undefined | any = {}) {
    this['formConfigurationId'] = data['formConfigurationId'];
    this['formResponseIdentifier'] = data['formResponseIdentifier'];
  }
}

export class DeviceDetailDto {
  /**  */
  'serialNumber': string;

  /**  */
  'model': string;

  /**  */
  'manufacturer': string;

  /**  */
  'tokenizationType': string;

  /**  */
  'notes': string;

  /**  */
  'quantity': number;

  /**  */
  'organizationId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'modifiedBy': string;

  /**  */
  'meta': string;

  /**  */
  'id': number;

  /**  */
  'isArchived': boolean;

  /**  */
  'businessUnits': BusinessUnitDto[];

  /**  */
  'businessUnitIds': number[];

  constructor(data: undefined | any = {}) {
    this['serialNumber'] = data['serialNumber'];
    this['model'] = data['model'];
    this['manufacturer'] = data['manufacturer'];
    this['tokenizationType'] = data['tokenizationType'];
    this['notes'] = data['notes'];
    this['quantity'] = data['quantity'];
    this['organizationId'] = data['organizationId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['modifiedBy'] = data['modifiedBy'];
    this['meta'] = data['meta'];
    this['id'] = data['id'];
    this['isArchived'] = data['isArchived'];
    this['businessUnits'] = data['businessUnits'];
    this['businessUnitIds'] = data['businessUnitIds'];
  }
}

export class DeviceSummaryDto {
  /**  */
  'id': number;

  /**  */
  'serialNumber': string;

  /**  */
  'tokenizationType': string;

  /**  */
  'modifiedBy': string;

  /**  */
  'model': string;

  /**  */
  'manufacturer': string;

  /**  */
  'merchantAccountName': string;

  /**  */
  'businessUnitNames': string[];

  /**  */
  'isArchived': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['serialNumber'] = data['serialNumber'];
    this['tokenizationType'] = data['tokenizationType'];
    this['modifiedBy'] = data['modifiedBy'];
    this['model'] = data['model'];
    this['manufacturer'] = data['manufacturer'];
    this['merchantAccountName'] = data['merchantAccountName'];
    this['businessUnitNames'] = data['businessUnitNames'];
    this['isArchived'] = data['isArchived'];
  }
}

export class EmailLogGetDto {
  /**  */
  'messageId': string;

  /**  */
  'fromEmail': string;

  /**  */
  'toEmail': string;

  /**  */
  'emailData': string;

  /**  */
  'emailType': string;

  /**  */
  'status': string;

  /**  */
  'messageTemplateIdentifier': string;

  /**  */
  'hasAttachments': boolean;

  /**  */
  'paymentId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['messageId'] = data['messageId'];
    this['fromEmail'] = data['fromEmail'];
    this['toEmail'] = data['toEmail'];
    this['emailData'] = data['emailData'];
    this['emailType'] = data['emailType'];
    this['status'] = data['status'];
    this['messageTemplateIdentifier'] = data['messageTemplateIdentifier'];
    this['hasAttachments'] = data['hasAttachments'];
    this['paymentId'] = data['paymentId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['id'] = data['id'];
  }
}

export class EmbeddedAutopayUpdateEnrollmentRequest {
  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'maximumBalanceAllowed': number;

  /**  */
  'termsAcknowledged': boolean;

  constructor(data: undefined | any = {}) {
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['termsAcknowledged'] = data['termsAcknowledged'];
  }
}

export class EmbeddedCharge {
  /**  */
  'amount': number;

  /**  */
  'description': string;

  /**  */
  'chargeType': string;

  /**  */
  'clientAccount': ClientAccountDto;

  constructor(data: undefined | any = {}) {
    this['amount'] = data['amount'];
    this['description'] = data['description'];
    this['chargeType'] = data['chargeType'];
    this['clientAccount'] = data['clientAccount'];
  }
}

export class EmbeddedPaymentSourceDto {
  /**  */
  'identifier': string;

  /**  */
  'type': string;

  /**  */
  'nickname': string;

  /**  */
  'card': CardSourceDto;

  /**  */
  'bankAccount': BankAccountSourceDto;

  /**  */
  'billingAddress': OptionalAddress;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['type'] = data['type'];
    this['nickname'] = data['nickname'];
    this['card'] = data['card'];
    this['bankAccount'] = data['bankAccount'];
    this['billingAddress'] = data['billingAddress'];
  }
}

export class EmptyResponse {
  /**  */
  'hasErrors': boolean;

  /**  */
  'errors': Error[];

  constructor(data: undefined | any = {}) {
    this['hasErrors'] = data['hasErrors'];
    this['errors'] = data['errors'];
  }
}

export class EnqueueManualJobRequest {
  /**  */
  'executionContext': object;

  constructor(data: undefined | any = {}) {
    this['executionContext'] = data['executionContext'];
  }
}

export class Error {
  /**  */
  'propertyName': string;

  /**  */
  'errorMessage': string;

  constructor(data: undefined | any = {}) {
    this['propertyName'] = data['propertyName'];
    this['errorMessage'] = data['errorMessage'];
  }
}

export class ExternalEventDto {
  /**  */
  'eventType': string;

  /**  */
  'payload': string;

  /**  */
  'businessUnitSlug': string;

  constructor(data: undefined | any = {}) {
    this['eventType'] = data['eventType'];
    this['payload'] = data['payload'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
  }
}

export class ExternalLinkConfigGetDto {
  /**  */
  'identifier': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'showOnCustomerDashboard': boolean;

  /**  */
  'payloadConfigurationJson': string;

  /**  */
  'url': string;

  /**  */
  'isGeneratedLink': boolean;

  /**  */
  'httpMethod': string;

  /**  */
  'display': string;

  /**  */
  'description': string;

  /**  */
  'functionTriggerId': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['businessUnitId'] = data['businessUnitId'];
    this['showOnCustomerDashboard'] = data['showOnCustomerDashboard'];
    this['payloadConfigurationJson'] = data['payloadConfigurationJson'];
    this['url'] = data['url'];
    this['isGeneratedLink'] = data['isGeneratedLink'];
    this['httpMethod'] = data['httpMethod'];
    this['display'] = data['display'];
    this['description'] = data['description'];
    this['functionTriggerId'] = data['functionTriggerId'];
    this['id'] = data['id'];
  }
}

export class ExternalLinkConfigSummaryDto {
  /**  */
  'id': number;

  /**  */
  'identifier': string;

  /**  */
  'httpMethod': string;

  /**  */
  'display': string;

  /**  */
  'description': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['identifier'] = data['identifier'];
    this['httpMethod'] = data['httpMethod'];
    this['display'] = data['display'];
    this['description'] = data['description'];
  }
}

export class ExternalTransactionSummaryDto {
  /**  */
  'id': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'importSourceType': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'transactionDate': Date;

  /**  */
  'transactionType': string;

  /**  */
  'status': string;

  /**  */
  'amountMinor': number;

  /**  */
  'paymentSourceType': string;

  /**  */
  'paymentSourceAccountNumberMasked': string;

  /**  */
  'accountId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['referenceNumber'] = data['referenceNumber'];
    this['importSourceType'] = data['importSourceType'];
    this['businessUnitId'] = data['businessUnitId'];
    this['transactionDate'] = data['transactionDate'];
    this['transactionType'] = data['transactionType'];
    this['status'] = data['status'];
    this['amountMinor'] = data['amountMinor'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['paymentSourceAccountNumberMasked'] = data['paymentSourceAccountNumberMasked'];
    this['accountId'] = data['accountId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
  }
}

export class FormConfigurationGetDto {
  /**  */
  'displayName': string;

  /**  */
  'identifier': string;

  /**  */
  'fieldsJson': string;

  /**  */
  'displayLocations': string;

  /**  */
  'displayChannels': string;

  /**  */
  'displayConditions': string;

  /**  */
  'triggerFormSubmittedEvent': boolean;

  /**  */
  'onSubmitAction': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['displayName'] = data['displayName'];
    this['identifier'] = data['identifier'];
    this['fieldsJson'] = data['fieldsJson'];
    this['displayLocations'] = data['displayLocations'];
    this['displayChannels'] = data['displayChannels'];
    this['displayConditions'] = data['displayConditions'];
    this['triggerFormSubmittedEvent'] = data['triggerFormSubmittedEvent'];
    this['onSubmitAction'] = data['onSubmitAction'];
    this['businessUnitId'] = data['businessUnitId'];
    this['id'] = data['id'];
  }
}

export class FormSubmittedEventPayload {
  /**  */
  'formResponseIdentifier': string;

  /**  */
  'formIdentifier': string;

  /**  */
  'formDisplayName': string;

  /**  */
  'customerId': string;

  /**  */
  'accountId': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'onSubmitAction': string;

  /**  */
  'valuesJson': string;

  constructor(data: undefined | any = {}) {
    this['formResponseIdentifier'] = data['formResponseIdentifier'];
    this['formIdentifier'] = data['formIdentifier'];
    this['formDisplayName'] = data['formDisplayName'];
    this['customerId'] = data['customerId'];
    this['accountId'] = data['accountId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['onSubmitAction'] = data['onSubmitAction'];
    this['valuesJson'] = data['valuesJson'];
  }
}

export class FunctionTriggerGetDto {
  /**  */
  'id': number;

  /**  */
  'triggerType': string;

  /**  */
  'functionName': string;

  /**  */
  'functionApp': string;

  /**  */
  'metaJson': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['triggerType'] = data['triggerType'];
    this['functionName'] = data['functionName'];
    this['functionApp'] = data['functionApp'];
    this['metaJson'] = data['metaJson'];
  }
}

export class FundingEntryAggregate {
  /**  */
  'fundingEntryDate': string;

  /**  */
  'numberPayments': number;

  /**  */
  'totalAmountMinor': number;

  /**  */
  'netSalesMinor': number;

  /**  */
  'depositInformation': string;

  /**  */
  'gatewayFundingGroupId': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'merchantAccount': string;

  /**  */
  'paymentSourceType': string;

  constructor(data: undefined | any = {}) {
    this['fundingEntryDate'] = data['fundingEntryDate'];
    this['numberPayments'] = data['numberPayments'];
    this['totalAmountMinor'] = data['totalAmountMinor'];
    this['netSalesMinor'] = data['netSalesMinor'];
    this['depositInformation'] = data['depositInformation'];
    this['gatewayFundingGroupId'] = data['gatewayFundingGroupId'];
    this['merchantNumber'] = data['merchantNumber'];
    this['merchantAccount'] = data['merchantAccount'];
    this['paymentSourceType'] = data['paymentSourceType'];
  }
}

export class FundingEntryGetDto {
  /**  */
  'gatewayFundingEntryId': string;

  /**  */
  'gatewayFundingGroupId': string;

  /**  */
  'paymentGatewayType': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'fundingEntryDate': string;

  /**  */
  'currencyCode': string;

  /**  */
  'totalAmount': string;

  /**  */
  'netSales': string;

  /**  */
  'adjustments': string;

  /**  */
  'depositInformation': string;

  /**  */
  'fundingEntryJson': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['gatewayFundingEntryId'] = data['gatewayFundingEntryId'];
    this['gatewayFundingGroupId'] = data['gatewayFundingGroupId'];
    this['paymentGatewayType'] = data['paymentGatewayType'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['merchantNumber'] = data['merchantNumber'];
    this['fundingEntryDate'] = data['fundingEntryDate'];
    this['currencyCode'] = data['currencyCode'];
    this['totalAmount'] = data['totalAmount'];
    this['netSales'] = data['netSales'];
    this['adjustments'] = data['adjustments'];
    this['depositInformation'] = data['depositInformation'];
    this['fundingEntryJson'] = data['fundingEntryJson'];
    this['id'] = data['id'];
  }
}

export class GatewayPaystarAchBatchClosedEventPayload {
  /**  */
  'immediateOrigin': string;

  /**  */
  'originatingCompanyId': string;

  /**  */
  'originatingCompanyName': string;

  /**  */
  'destinationBankRoutingNumber': string;

  /**  */
  'destinationBankName': string;

  /**  */
  'companyDiscretionaryData': string;

  /**  */
  'companyEntryDescription': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'encryptionType': string;

  /**  */
  'batchDate': Date;

  /**  */
  'settlementDate': Date;

  /**  */
  'transactionCount': number;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'paymentAmountFormatted': string;

  /**  */
  'fileUrl': string;

  constructor(data: undefined | any = {}) {
    this['immediateOrigin'] = data['immediateOrigin'];
    this['originatingCompanyId'] = data['originatingCompanyId'];
    this['originatingCompanyName'] = data['originatingCompanyName'];
    this['destinationBankRoutingNumber'] = data['destinationBankRoutingNumber'];
    this['destinationBankName'] = data['destinationBankName'];
    this['companyDiscretionaryData'] = data['companyDiscretionaryData'];
    this['companyEntryDescription'] = data['companyEntryDescription'];
    this['merchantNumber'] = data['merchantNumber'];
    this['encryptionType'] = data['encryptionType'];
    this['batchDate'] = data['batchDate'];
    this['settlementDate'] = data['settlementDate'];
    this['transactionCount'] = data['transactionCount'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['paymentAmountFormatted'] = data['paymentAmountFormatted'];
    this['fileUrl'] = data['fileUrl'];
  }
}

export class GatewayPaystarAchBatchCreatedEventPayload {
  /**  */
  'odfiIdentifier': string;

  /**  */
  'merchantNumber': string;

  constructor(data: undefined | any = {}) {
    this['odfiIdentifier'] = data['odfiIdentifier'];
    this['merchantNumber'] = data['merchantNumber'];
  }
}

export class GenerateAutopayEnrollmentExport_Config {
  /**  */
  'datePreset': string;

  /**  */
  'startDate': string;

  /**  */
  'endDate': string;

  /**  */
  'autopayActivityTypes': string[];

  /**  */
  'exportType': string;

  /**  */
  'jsReportTemplateName': string;

  /**  */
  'fileName': string;

  constructor(data: undefined | any = {}) {
    this['datePreset'] = data['datePreset'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['autopayActivityTypes'] = data['autopayActivityTypes'];
    this['exportType'] = data['exportType'];
    this['jsReportTemplateName'] = data['jsReportTemplateName'];
    this['fileName'] = data['fileName'];
  }
}

export class GeneratePaperlessBillingEnrollmentExport_Config {
  /**  */
  'datePreset': string;

  /**  */
  'startDate': string;

  /**  */
  'endDate': string;

  /**  */
  'paperlessBillingActivityTypes': string[];

  /**  */
  'exportType': string;

  /**  */
  'jsReportTemplateName': string;

  /**  */
  'fileName': string;

  constructor(data: undefined | any = {}) {
    this['datePreset'] = data['datePreset'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['paperlessBillingActivityTypes'] = data['paperlessBillingActivityTypes'];
    this['exportType'] = data['exportType'];
    this['jsReportTemplateName'] = data['jsReportTemplateName'];
    this['fileName'] = data['fileName'];
  }
}

export class GeneratePaymentLineItemReport_Config {
  /**  */
  'datePreset': string;

  /**  */
  'startDate': string;

  /**  */
  'endDate': string;

  /**  */
  'paymentStatuses': string[];

  /**  */
  'paymentSourceTypes': string[];

  /**  */
  'paymentChannels': string[];

  /**  */
  'fileName': string;

  /**  */
  'lineItemTypes': string[];

  constructor(data: undefined | any = {}) {
    this['datePreset'] = data['datePreset'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['paymentStatuses'] = data['paymentStatuses'];
    this['paymentSourceTypes'] = data['paymentSourceTypes'];
    this['paymentChannels'] = data['paymentChannels'];
    this['fileName'] = data['fileName'];
    this['lineItemTypes'] = data['lineItemTypes'];
  }
}

export class GenerateTransactionExportFunction_Config {
  /**  */
  'functionOrchestrationUrl': string;

  /**  */
  'activityName': string;

  constructor(data: undefined | any = {}) {
    this['functionOrchestrationUrl'] = data['functionOrchestrationUrl'];
    this['activityName'] = data['activityName'];
  }
}

export class GenerateTransactionExportV2_Config {
  /**  */
  'datePreset': string;

  /**  */
  'startDate': string;

  /**  */
  'endDate': string;

  /**  */
  'paymentStatuses': string[];

  /**  */
  'paymentSourceTypes': string[];

  /**  */
  'paymentChannels': string[];

  /**  */
  'accountCreateMethods': string[];

  /**  */
  'additionalConfigurationJson': string;

  /**  */
  'exportType': string;

  /**  */
  'jsReportTemplateName': string;

  /**  */
  'fileName': string;

  constructor(data: undefined | any = {}) {
    this['datePreset'] = data['datePreset'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['paymentStatuses'] = data['paymentStatuses'];
    this['paymentSourceTypes'] = data['paymentSourceTypes'];
    this['paymentChannels'] = data['paymentChannels'];
    this['accountCreateMethods'] = data['accountCreateMethods'];
    this['additionalConfigurationJson'] = data['additionalConfigurationJson'];
    this['exportType'] = data['exportType'];
    this['jsReportTemplateName'] = data['jsReportTemplateName'];
    this['fileName'] = data['fileName'];
  }
}

export class GetAccountPaymentSourcesResponse {
  /**  */
  'accountPaymentSources': PaymentSourceSummaryDto[];

  /**  */
  'customerAutopayPaymentSource': PaymentSourceSummaryDto;

  constructor(data: undefined | any = {}) {
    this['accountPaymentSources'] = data['accountPaymentSources'];
    this['customerAutopayPaymentSource'] = data['customerAutopayPaymentSource'];
  }
}

export class GetPlaidLinkTokenRequest {
  /**  */
  'sessionIdentifier': string;

  constructor(data: undefined | any = {}) {
    this['sessionIdentifier'] = data['sessionIdentifier'];
  }
}

export class ImportProcessedBills_Config {
  /**  */
  'cycleConfigIdentifier': string;

  /**  */
  'cycleDateKey': string;

  /**  */
  'blobUrl': string;

  /**  */
  'dueDate': string;

  /**  */
  'parsedFileUrl': string;

  /**  */
  'autoApprove': boolean;

  /**  */
  'autoApprovalDelayInMinutes': number;

  constructor(data: undefined | any = {}) {
    this['cycleConfigIdentifier'] = data['cycleConfigIdentifier'];
    this['cycleDateKey'] = data['cycleDateKey'];
    this['blobUrl'] = data['blobUrl'];
    this['dueDate'] = data['dueDate'];
    this['parsedFileUrl'] = data['parsedFileUrl'];
    this['autoApprove'] = data['autoApprove'];
    this['autoApprovalDelayInMinutes'] = data['autoApprovalDelayInMinutes'];
  }
}

export class InitiateCustomerPhoneVerificationRequest {
  /**  */
  'phoneNumber': string;

  constructor(data: undefined | any = {}) {
    this['phoneNumber'] = data['phoneNumber'];
  }
}

export class InitiateEmbeddedPaymentSessionRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'channel': string;

  /**  */
  'clientReference': string;

  /**  */
  'paymentSourceIdentifier': string;

  /**  */
  'charges': EmbeddedCharge[];

  /**  */
  'paymentFields': object;

  /**  */
  'clientUser': ClientUserDto;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['channel'] = data['channel'];
    this['clientReference'] = data['clientReference'];
    this['paymentSourceIdentifier'] = data['paymentSourceIdentifier'];
    this['charges'] = data['charges'];
    this['paymentFields'] = data['paymentFields'];
    this['clientUser'] = data['clientUser'];
  }
}

export class InitiateFunctionOrchestration_CallbackDto {
  /**  */
  'identifier': string;

  /**  */
  'url': string;

  /**  */
  'initialPayload': object;

  /**  */
  'payloadKeyMap': object;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['url'] = data['url'];
    this['initialPayload'] = data['initialPayload'];
    this['payloadKeyMap'] = data['payloadKeyMap'];
  }
}

export class InitiateFunctionOrchestration_Config {
  /**  */
  'shouldPause': boolean;

  /**  */
  'functionOrchestration': InitiateFunctionOrchestration_FunctionOrchestration;

  constructor(data: undefined | any = {}) {
    this['shouldPause'] = data['shouldPause'];
    this['functionOrchestration'] = data['functionOrchestration'];
  }
}

export class InitiateFunctionOrchestration_FanConfiguration {
  /**  */
  'resultKey': string;

  /**  */
  'responseKey': string;

  /**  */
  'requestKey': string;

  /**  */
  'fannedRequestPayloadKey': string;

  /**  */
  'requestPayloadDelimiter': string;

  /**  */
  'flattenResponse': boolean;

  constructor(data: undefined | any = {}) {
    this['resultKey'] = data['resultKey'];
    this['responseKey'] = data['responseKey'];
    this['requestKey'] = data['requestKey'];
    this['fannedRequestPayloadKey'] = data['fannedRequestPayloadKey'];
    this['requestPayloadDelimiter'] = data['requestPayloadDelimiter'];
    this['flattenResponse'] = data['flattenResponse'];
  }
}

export class InitiateFunctionOrchestration_FunctionOrchestration {
  /**  */
  'functionTriggerId': number;

  /**  */
  'functionOrchestrationUrl': string;

  /**  */
  'functionName': string;

  /**  */
  'functionTriggerType': string;

  /**  */
  'onFailUrl': string;

  /**  */
  'functionRequestBody': object;

  /**  */
  'exitEarly': boolean;

  /**  */
  'fanResults': boolean;

  /**  */
  'fanRequest': boolean;

  /**  */
  'resultKey': string;

  /**  */
  'fanConfiguration': InitiateFunctionOrchestration_FanConfiguration;

  /**  */
  'callbacks': InitiateFunctionOrchestration_CallbackDto[];

  constructor(data: undefined | any = {}) {
    this['functionTriggerId'] = data['functionTriggerId'];
    this['functionOrchestrationUrl'] = data['functionOrchestrationUrl'];
    this['functionName'] = data['functionName'];
    this['functionTriggerType'] = data['functionTriggerType'];
    this['onFailUrl'] = data['onFailUrl'];
    this['functionRequestBody'] = data['functionRequestBody'];
    this['exitEarly'] = data['exitEarly'];
    this['fanResults'] = data['fanResults'];
    this['fanRequest'] = data['fanRequest'];
    this['resultKey'] = data['resultKey'];
    this['fanConfiguration'] = data['fanConfiguration'];
    this['callbacks'] = data['callbacks'];
  }
}

export class InitiateManageAutopaySessionRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'syncAccount': boolean;

  /**  */
  'clientAccount': ClientAccountDto;

  /**  */
  'clientUser': ClientUserDto;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['syncAccount'] = data['syncAccount'];
    this['clientAccount'] = data['clientAccount'];
    this['clientUser'] = data['clientUser'];
  }
}

export class InitiateManagePaperlessSessionRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'syncAccount': boolean;

  /**  */
  'clientAccount': ClientAccountDto;

  /**  */
  'clientUser': ClientUserDto;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['syncAccount'] = data['syncAccount'];
    this['clientAccount'] = data['clientAccount'];
    this['clientUser'] = data['clientUser'];
  }
}

export class InitiateManageWalletSessionRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'clientUser': ClientUserDto;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['clientUser'] = data['clientUser'];
  }
}

export class InitiateOnboardingPhoneNumberVerificationRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'phoneNumber': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['phoneNumber'] = data['phoneNumber'];
  }
}

export class InitiateOnboardingSessionRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'password': string;

  /**  */
  'confirmPassword': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['password'] = data['password'];
    this['confirmPassword'] = data['confirmPassword'];
  }
}

export class InitiatePaymentSessionRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'name': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'note': string;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'meta': string;

  /**  */
  'initialPaymentFields': object;

  /**  */
  'successUrl': string;

  /**  */
  'returnUrl': string;

  /**  */
  'channel': string;

  /**  */
  'clientReference': string;

  /**  */
  'skipAccountSync': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['name'] = data['name'];
    this['phoneNumber'] = data['phoneNumber'];
    this['address'] = data['address'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['note'] = data['note'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['meta'] = data['meta'];
    this['initialPaymentFields'] = data['initialPaymentFields'];
    this['successUrl'] = data['successUrl'];
    this['returnUrl'] = data['returnUrl'];
    this['channel'] = data['channel'];
    this['clientReference'] = data['clientReference'];
    this['skipAccountSync'] = data['skipAccountSync'];
  }
}

export class InitiateUserSessionRequest {
  /**  */
  'clientSecret': string;

  /**  */
  'businessUnitSlug': string;

  /**  */
  'returnUrl': string;

  /**  */
  'initialAction': string;

  /**  */
  'initialActionPayload': object;

  /**  */
  'clientUser': ClientUserDto;

  /**  */
  'clientAccount': ClientAccountDto;

  constructor(data: undefined | any = {}) {
    this['clientSecret'] = data['clientSecret'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['returnUrl'] = data['returnUrl'];
    this['initialAction'] = data['initialAction'];
    this['initialActionPayload'] = data['initialActionPayload'];
    this['clientUser'] = data['clientUser'];
    this['clientAccount'] = data['clientAccount'];
  }
}

export class InternalAccountNoteGetDto {
  /**  */
  'accountId': number;

  /**  */
  'notes': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['notes'] = data['notes'];
    this['id'] = data['id'];
  }
}

export class InternalAccountNoteSummaryDto {
  /**  */
  'id': number;

  /**  */
  'notes': string;

  /**  */
  'modifiedDate': Date;

  /**  */
  'modifiedByEmail': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['notes'] = data['notes'];
    this['modifiedDate'] = data['modifiedDate'];
    this['modifiedByEmail'] = data['modifiedByEmail'];
  }
}

export class IvrFileUploadedEvent {
  /**  */
  'businessUnitId': number;

  /**  */
  'outboundIvrUploadBlobUrl': string;

  /**  */
  'outboundIvrUploadDownloadBlobUrl': string;

  /**  */
  'userEmail': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'maxAttempts': number;

  /**  */
  'message': string;

  /**  */
  'followUpMessage': string;

  /**  */
  'followUpPhoneNumber': string;

  /**  */
  'callbackUrl': string;

  /**  */
  'callSummary': OutboundCallSummaryDto;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['outboundIvrUploadBlobUrl'] = data['outboundIvrUploadBlobUrl'];
    this['outboundIvrUploadDownloadBlobUrl'] = data['outboundIvrUploadDownloadBlobUrl'];
    this['userEmail'] = data['userEmail'];
    this['referenceNumber'] = data['referenceNumber'];
    this['maxAttempts'] = data['maxAttempts'];
    this['message'] = data['message'];
    this['followUpMessage'] = data['followUpMessage'];
    this['followUpPhoneNumber'] = data['followUpPhoneNumber'];
    this['callbackUrl'] = data['callbackUrl'];
    this['callSummary'] = data['callSummary'];
  }
}

export class JobDetailDto {
  /**  */
  'id': number;

  /**  */
  'businessUnitName': string;

  /**  */
  'organizationName': string;

  /**  */
  'jobEventTriggerCount': number;

  /**  */
  'scheduleCount': number;

  /**  */
  'description': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'jobContextJson': string;

  /**  */
  'tags': string;

  /**  */
  'taskDescriptions': string[];

  /**  */
  'disabled': boolean;

  /**  */
  'webhookId': string;

  /**  */
  'notes': string;

  /**  */
  'jobEventTriggers': string[];

  /**  */
  'jobTasks': JobTaskGetDto[];

  /**  */
  'jobExport': JobTaskExportDto[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitName'] = data['businessUnitName'];
    this['organizationName'] = data['organizationName'];
    this['jobEventTriggerCount'] = data['jobEventTriggerCount'];
    this['scheduleCount'] = data['scheduleCount'];
    this['description'] = data['description'];
    this['businessUnitId'] = data['businessUnitId'];
    this['jobContextJson'] = data['jobContextJson'];
    this['tags'] = data['tags'];
    this['taskDescriptions'] = data['taskDescriptions'];
    this['disabled'] = data['disabled'];
    this['webhookId'] = data['webhookId'];
    this['notes'] = data['notes'];
    this['jobEventTriggers'] = data['jobEventTriggers'];
    this['jobTasks'] = data['jobTasks'];
    this['jobExport'] = data['jobExport'];
  }
}

export class JobExecutionStateGetDto {
  /**  */
  'jobId': number;

  /**  */
  'identifier': string;

  /**  */
  'executionStatus': string;

  /**  */
  'startTime': Date;

  /**  */
  'endTime': Date;

  /**  */
  'id': number;

  /**  */
  'contextSnapshotJson': string;

  /**  */
  'pausedJobTaskIndex': number;

  constructor(data: undefined | any = {}) {
    this['jobId'] = data['jobId'];
    this['identifier'] = data['identifier'];
    this['executionStatus'] = data['executionStatus'];
    this['startTime'] = data['startTime'];
    this['endTime'] = data['endTime'];
    this['id'] = data['id'];
    this['contextSnapshotJson'] = data['contextSnapshotJson'];
    this['pausedJobTaskIndex'] = data['pausedJobTaskIndex'];
  }
}

export class JobExecutionStateSummaryDto {
  /**  */
  'id': number;

  /**  */
  'jobId': number;

  /**  */
  'identifier': string;

  /**  */
  'executionStatus': string;

  /**  */
  'startTime': Date;

  /**  */
  'endTime': Date;

  /**  */
  'fileCount': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['jobId'] = data['jobId'];
    this['identifier'] = data['identifier'];
    this['executionStatus'] = data['executionStatus'];
    this['startTime'] = data['startTime'];
    this['endTime'] = data['endTime'];
    this['fileCount'] = data['fileCount'];
  }
}

export class JobScheduleGetDto {
  /**  */
  'jobId': number;

  /**  */
  'rRule': string;

  /**  */
  'timeZone': string;

  /**  */
  'nextExecutionTimeUtc': Date;

  /**  */
  'lastExecutionTimeUtc': Date;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['jobId'] = data['jobId'];
    this['rRule'] = data['rRule'];
    this['timeZone'] = data['timeZone'];
    this['nextExecutionTimeUtc'] = data['nextExecutionTimeUtc'];
    this['lastExecutionTimeUtc'] = data['lastExecutionTimeUtc'];
    this['id'] = data['id'];
  }
}

export class JobTaskDto {
  /**  */
  'jobId': number;

  /**  */
  'type': string;

  /**  */
  'description': string;

  /**  */
  'settingsJson': string;

  /**  */
  'executionOrder': number;

  /**  */
  'executionCondition': string;

  constructor(data: undefined | any = {}) {
    this['jobId'] = data['jobId'];
    this['type'] = data['type'];
    this['description'] = data['description'];
    this['settingsJson'] = data['settingsJson'];
    this['executionOrder'] = data['executionOrder'];
    this['executionCondition'] = data['executionCondition'];
  }
}

export class JobTaskExportDto {
  /**  */
  'type': string;

  /**  */
  'description': string;

  /**  */
  'settingsJson': string;

  /**  */
  'executionOrder': number;

  /**  */
  'executionCondition': string;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['description'] = data['description'];
    this['settingsJson'] = data['settingsJson'];
    this['executionOrder'] = data['executionOrder'];
    this['executionCondition'] = data['executionCondition'];
  }
}

export class JobTaskGetDto {
  /**  */
  'jobId': number;

  /**  */
  'type': string;

  /**  */
  'description': string;

  /**  */
  'settingsJson': string;

  /**  */
  'executionOrder': number;

  /**  */
  'executionCondition': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['jobId'] = data['jobId'];
    this['type'] = data['type'];
    this['description'] = data['description'];
    this['settingsJson'] = data['settingsJson'];
    this['executionOrder'] = data['executionOrder'];
    this['executionCondition'] = data['executionCondition'];
    this['id'] = data['id'];
  }
}

export class JobTemplateGetDto {
  /**  */
  'name': string;

  /**  */
  'templateJson': string;

  /**  */
  'jobId': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['templateJson'] = data['templateJson'];
    this['jobId'] = data['jobId'];
    this['id'] = data['id'];
  }
}

export class JobTemplateSummaryDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class LinkCustomerAccountRequest {
  /**  */
  'accountId': number;

  /**  */
  'userId': number;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['userId'] = data['userId'];
  }
}

export class LinkReadings_Config {
  constructor(data: undefined | any = {}) {}
}

export class MatchedAdjustments {
  /**  */
  'adjustmentId': number;

  /**  */
  'adjustmentDate': Date;

  /**  */
  'adjustmentType': string;

  /**  */
  'adjustmentCode': string;

  /**  */
  'adjustmentAmount': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'batchId': string;

  /**  */
  'transactionId': number;

  /**  */
  'paymentId': number;

  /**  */
  'transactionType': string;

  /**  */
  'amountValue': number;

  /**  */
  'transactionReferenceNumber': string;

  /**  */
  'authCode': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'accountId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'paymentStatus': string;

  /**  */
  'channel': string;

  /**  */
  'paymentType': string;

  /**  */
  'serviceFeeAmount': number;

  /**  */
  'adjustmentAmountFormattedUsd': string;

  constructor(data: undefined | any = {}) {
    this['adjustmentId'] = data['adjustmentId'];
    this['adjustmentDate'] = data['adjustmentDate'];
    this['adjustmentType'] = data['adjustmentType'];
    this['adjustmentCode'] = data['adjustmentCode'];
    this['adjustmentAmount'] = data['adjustmentAmount'];
    this['merchantNumber'] = data['merchantNumber'];
    this['batchId'] = data['batchId'];
    this['transactionId'] = data['transactionId'];
    this['paymentId'] = data['paymentId'];
    this['transactionType'] = data['transactionType'];
    this['amountValue'] = data['amountValue'];
    this['transactionReferenceNumber'] = data['transactionReferenceNumber'];
    this['authCode'] = data['authCode'];
    this['businessUnitId'] = data['businessUnitId'];
    this['accountId'] = data['accountId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['paymentStatus'] = data['paymentStatus'];
    this['channel'] = data['channel'];
    this['paymentType'] = data['paymentType'];
    this['serviceFeeAmount'] = data['serviceFeeAmount'];
    this['adjustmentAmountFormattedUsd'] = data['adjustmentAmountFormattedUsd'];
  }
}

export class MerchantAccountChannelSummaryDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'currencyCode': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'acceptedCardBrands': AcceptedCardBrands;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['currencyCode'] = data['currencyCode'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['acceptedCardBrands'] = data['acceptedCardBrands'];
  }
}

export class MerchantAccountClientSummaryDto {
  /**  */
  'merchantNumber': string;

  /**  */
  'batchTime': TimeSpan;

  /**  */
  'timezone': string;

  constructor(data: undefined | any = {}) {
    this['merchantNumber'] = data['merchantNumber'];
    this['batchTime'] = data['batchTime'];
    this['timezone'] = data['timezone'];
  }
}

export class MerchantAccountDetailDto {
  /**  */
  'name': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'currencyCode': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'monthlyProcessingLimit': number;

  /**  */
  'timezone': string;

  /**  */
  'batchTime': TimeSpan;

  /**  */
  'supportsSettlement': boolean;

  /**  */
  'supportsFunding': boolean;

  /**  */
  'feeOnly': boolean;

  /**  */
  'processorId': string;

  /**  */
  'notes': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'nachaConfigId': number;

  /**  */
  'tokenizerConfig': object;

  /**  */
  'acceptedCardBrands': AcceptedCardBrands;

  /**  */
  'id': number;

  /**  */
  'paymentGateway': string;

  /**  */
  'organizationIds': number[];

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['merchantNumber'] = data['merchantNumber'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['currencyCode'] = data['currencyCode'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['monthlyProcessingLimit'] = data['monthlyProcessingLimit'];
    this['timezone'] = data['timezone'];
    this['batchTime'] = data['batchTime'];
    this['supportsSettlement'] = data['supportsSettlement'];
    this['supportsFunding'] = data['supportsFunding'];
    this['feeOnly'] = data['feeOnly'];
    this['processorId'] = data['processorId'];
    this['notes'] = data['notes'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['nachaConfigId'] = data['nachaConfigId'];
    this['tokenizerConfig'] = data['tokenizerConfig'];
    this['acceptedCardBrands'] = data['acceptedCardBrands'];
    this['id'] = data['id'];
    this['paymentGateway'] = data['paymentGateway'];
    this['organizationIds'] = data['organizationIds'];
  }
}

export class MerchantAccountGatewayConfigDto {
  /**  */
  'id': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'processorId': string;

  /**  */
  'url': string;

  /**  */
  'username': string;

  /**  */
  'apiKey': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['merchantNumber'] = data['merchantNumber'];
    this['processorId'] = data['processorId'];
    this['url'] = data['url'];
    this['username'] = data['username'];
    this['apiKey'] = data['apiKey'];
  }
}

export class MerchantAccountGetDto {
  /**  */
  'name': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'currencyCode': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'monthlyProcessingLimit': number;

  /**  */
  'timezone': string;

  /**  */
  'batchTime': TimeSpan;

  /**  */
  'supportsSettlement': boolean;

  /**  */
  'supportsFunding': boolean;

  /**  */
  'feeOnly': boolean;

  /**  */
  'processorId': string;

  /**  */
  'notes': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'nachaConfigId': number;

  /**  */
  'tokenizerConfig': object;

  /**  */
  'acceptedCardBrands': AcceptedCardBrands;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['merchantNumber'] = data['merchantNumber'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['currencyCode'] = data['currencyCode'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['monthlyProcessingLimit'] = data['monthlyProcessingLimit'];
    this['timezone'] = data['timezone'];
    this['batchTime'] = data['batchTime'];
    this['supportsSettlement'] = data['supportsSettlement'];
    this['supportsFunding'] = data['supportsFunding'];
    this['feeOnly'] = data['feeOnly'];
    this['processorId'] = data['processorId'];
    this['notes'] = data['notes'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['nachaConfigId'] = data['nachaConfigId'];
    this['tokenizerConfig'] = data['tokenizerConfig'];
    this['acceptedCardBrands'] = data['acceptedCardBrands'];
    this['id'] = data['id'];
  }
}

export class MerchantAccountSummaryDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'paymentGateway': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'currencyCode': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'feeOnly': boolean;

  /**  */
  'acceptedCardBrands': AcceptedCardBrands;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['paymentGateway'] = data['paymentGateway'];
    this['merchantNumber'] = data['merchantNumber'];
    this['currencyCode'] = data['currencyCode'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['feeOnly'] = data['feeOnly'];
    this['acceptedCardBrands'] = data['acceptedCardBrands'];
  }
}

export class MergeAccountsRequest {
  /**  */
  'targetAccountId': number;

  /**  */
  'mergingAccountId': number;

  constructor(data: undefined | any = {}) {
    this['targetAccountId'] = data['targetAccountId'];
    this['mergingAccountId'] = data['mergingAccountId'];
  }
}

export class MessageDetailDto {
  /**  */
  'type': string;

  /**  */
  'messageBody': string;

  /**  */
  'organizationId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'paymentChannelType': string;

  /**  */
  'showOnLogin': boolean;

  /**  */
  'showOnPayment': boolean;

  /**  */
  'showOnConfirmation': boolean;

  /**  */
  'showOnAdminPortal': boolean;

  /**  */
  'showOnBackOfficeAutopayPayerModal': boolean;

  /**  */
  'showDate': Date;

  /**  */
  'removeDate': Date;

  /**  */
  'id': number;

  /**  */
  'messageBodyPlainText': string;

  /**  */
  'organizationName': string;

  /**  */
  'businessUnitName': string;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['messageBody'] = data['messageBody'];
    this['organizationId'] = data['organizationId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paymentChannelType'] = data['paymentChannelType'];
    this['showOnLogin'] = data['showOnLogin'];
    this['showOnPayment'] = data['showOnPayment'];
    this['showOnConfirmation'] = data['showOnConfirmation'];
    this['showOnAdminPortal'] = data['showOnAdminPortal'];
    this['showOnBackOfficeAutopayPayerModal'] = data['showOnBackOfficeAutopayPayerModal'];
    this['showDate'] = data['showDate'];
    this['removeDate'] = data['removeDate'];
    this['id'] = data['id'];
    this['messageBodyPlainText'] = data['messageBodyPlainText'];
    this['organizationName'] = data['organizationName'];
    this['businessUnitName'] = data['businessUnitName'];
  }
}

export class MessageSummaryDto {
  /**  */
  'id': number;

  /**  */
  'type': string;

  /**  */
  'messageBody': string;

  /**  */
  'messageBodyPlainText': string;

  /**  */
  'organizationId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'paymentChannelType': string;

  /**  */
  'showOnLogin': boolean;

  /**  */
  'showOnPayment': boolean;

  /**  */
  'showOnConfirmation': boolean;

  /**  */
  'showOnAdminPortal': boolean;

  /**  */
  'showOnBackOfficeAutopayPayerModal': boolean;

  /**  */
  'organizationName': string;

  /**  */
  'businessUnitName': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['type'] = data['type'];
    this['messageBody'] = data['messageBody'];
    this['messageBodyPlainText'] = data['messageBodyPlainText'];
    this['organizationId'] = data['organizationId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paymentChannelType'] = data['paymentChannelType'];
    this['showOnLogin'] = data['showOnLogin'];
    this['showOnPayment'] = data['showOnPayment'];
    this['showOnConfirmation'] = data['showOnConfirmation'];
    this['showOnAdminPortal'] = data['showOnAdminPortal'];
    this['showOnBackOfficeAutopayPayerModal'] = data['showOnBackOfficeAutopayPayerModal'];
    this['organizationName'] = data['organizationName'];
    this['businessUnitName'] = data['businessUnitName'];
  }
}

export class NachaConfigGetDto {
  /**  */
  'name': string;

  /**  */
  'encryptionType': string;

  /**  */
  'immediateOrigin': string;

  /**  */
  'originatingCompanyId': string;

  /**  */
  'originatingCompanyName': string;

  /**  */
  'destinationBankRoutingNumber': string;

  /**  */
  'destinationBankName': string;

  /**  */
  'companyDiscretionaryData': string;

  /**  */
  'companyEntryDescription': string;

  /**  */
  'effectiveEntryDateCutoffTime': TimeSpan;

  /**  */
  'effectiveEntryDateCutoffTimezone': string;

  /**  */
  'secCodeOverride': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['encryptionType'] = data['encryptionType'];
    this['immediateOrigin'] = data['immediateOrigin'];
    this['originatingCompanyId'] = data['originatingCompanyId'];
    this['originatingCompanyName'] = data['originatingCompanyName'];
    this['destinationBankRoutingNumber'] = data['destinationBankRoutingNumber'];
    this['destinationBankName'] = data['destinationBankName'];
    this['companyDiscretionaryData'] = data['companyDiscretionaryData'];
    this['companyEntryDescription'] = data['companyEntryDescription'];
    this['effectiveEntryDateCutoffTime'] = data['effectiveEntryDateCutoffTime'];
    this['effectiveEntryDateCutoffTimezone'] = data['effectiveEntryDateCutoffTimezone'];
    this['secCodeOverride'] = data['secCodeOverride'];
    this['id'] = data['id'];
  }
}

export class NachaConfigSummaryDto {
  /**  */
  'id': number;

  /**  */
  'name': string;

  /**  */
  'encryptionType': string;

  /**  */
  'modifiedBy': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['encryptionType'] = data['encryptionType'];
    this['modifiedBy'] = data['modifiedBy'];
  }
}

export class NetRevenueTransactionMetricsResponse {
  /**  */
  'businessUnitId': number;

  /**  */
  'transactionDateCst': Date;

  /**  */
  'paymentChannel': string;

  /**  */
  'paymentStatus': string;

  /**  */
  'transactionCount': number;

  /**  */
  'paymentCount': number;

  /**  */
  'transactionVolumeMinor': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['transactionDateCst'] = data['transactionDateCst'];
    this['paymentChannel'] = data['paymentChannel'];
    this['paymentStatus'] = data['paymentStatus'];
    this['transactionCount'] = data['transactionCount'];
    this['paymentCount'] = data['paymentCount'];
    this['transactionVolumeMinor'] = data['transactionVolumeMinor'];
  }
}

export class NewPaymentSource {
  /**  */
  'accountType': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'achAccountNumber': string;

  /**  */
  'achRoutingNumber': string;

  /**  */
  'accountNumberToken': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'cardExpiration': Date;

  /**  */
  'cardEntryMethod': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'achConfirmAccountNumber': string;

  /**  */
  'nickname': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountNumberMasked': string;

  constructor(data: undefined | any = {}) {
    this['accountType'] = data['accountType'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['achAccountNumber'] = data['achAccountNumber'];
    this['achRoutingNumber'] = data['achRoutingNumber'];
    this['accountNumberToken'] = data['accountNumberToken'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['cardExpiration'] = data['cardExpiration'];
    this['cardEntryMethod'] = data['cardEntryMethod'];
    this['accountHolderName'] = data['accountHolderName'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['achConfirmAccountNumber'] = data['achConfirmAccountNumber'];
    this['nickname'] = data['nickname'];
    this['address'] = data['address'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
  }
}

export class OnboardingConfigurationSummaryDto {
  /**  */
  'enrollInPaperlessBillingByDefault': boolean;

  /**  */
  'allowUpdatingDateToProcessAutopay': boolean;

  /**  */
  'allowVerifyPhoneNumber': boolean;

  /**  */
  'allowPaperlessBillingEnrollment': boolean;

  /**  */
  'allowAutopayEnrollment': boolean;

  /**  */
  'allowNotificationsEnrollment': boolean;

  /**  */
  'defaultProcessingLeadTime': number;

  constructor(data: undefined | any = {}) {
    this['enrollInPaperlessBillingByDefault'] = data['enrollInPaperlessBillingByDefault'];
    this['allowUpdatingDateToProcessAutopay'] = data['allowUpdatingDateToProcessAutopay'];
    this['allowVerifyPhoneNumber'] = data['allowVerifyPhoneNumber'];
    this['allowPaperlessBillingEnrollment'] = data['allowPaperlessBillingEnrollment'];
    this['allowAutopayEnrollment'] = data['allowAutopayEnrollment'];
    this['allowNotificationsEnrollment'] = data['allowNotificationsEnrollment'];
    this['defaultProcessingLeadTime'] = data['defaultProcessingLeadTime'];
  }
}

export class OnboardingSessionDetailsDto {
  /**  */
  'sessionIdentifier': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'email': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'uniqueAccountKey': string;

  /**  */
  'status': string;

  /**  */
  'emailSentTimestamp': Date;

  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'customerVerified': boolean;

  /**  */
  'accountVerified': boolean;

  /**  */
  'paperlessBillingEnrollmentCompleted': boolean;

  /**  */
  'autopayEnrollmentCompleted': boolean;

  /**  */
  'notificationsEnrollmentCompleted': boolean;

  /**  */
  'phoneNumberVerified': boolean;

  /**  */
  'id': number;

  /**  */
  'slug': string;

  /**  */
  'businessUnitLogoUrl': string;

  /**  */
  'businessUnitOrganizationName': string;

  /**  */
  'businessUnitPhoneNumber': string;

  /**  */
  'redirectUrl': string;

  /**  */
  'requirePassword': boolean;

  /**  */
  'billingAddressCollectionType': string;

  /**  */
  'phoneNumberVerificationIdentifier': string;

  /**  */
  'paperlessBillingEnabled': boolean;

  /**  */
  'autopayEnabled': boolean;

  /**  */
  'upcomingPaymentNotificationsEnabled': boolean;

  /**  */
  'billNotificationsEnabled': boolean;

  /**  */
  'smsNotificationsEnabled': boolean;

  /**  */
  'configuration': OnboardingConfigurationSummaryDto;

  /**  */
  'account': AccountOnboardingDto;

  /**  */
  'nextSession': OnboardingSessionDetailsDto;

  constructor(data: undefined | any = {}) {
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['businessUnitId'] = data['businessUnitId'];
    this['email'] = data['email'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['uniqueAccountKey'] = data['uniqueAccountKey'];
    this['status'] = data['status'];
    this['emailSentTimestamp'] = data['emailSentTimestamp'];
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['customerVerified'] = data['customerVerified'];
    this['accountVerified'] = data['accountVerified'];
    this['paperlessBillingEnrollmentCompleted'] = data['paperlessBillingEnrollmentCompleted'];
    this['autopayEnrollmentCompleted'] = data['autopayEnrollmentCompleted'];
    this['notificationsEnrollmentCompleted'] = data['notificationsEnrollmentCompleted'];
    this['phoneNumberVerified'] = data['phoneNumberVerified'];
    this['id'] = data['id'];
    this['slug'] = data['slug'];
    this['businessUnitLogoUrl'] = data['businessUnitLogoUrl'];
    this['businessUnitOrganizationName'] = data['businessUnitOrganizationName'];
    this['businessUnitPhoneNumber'] = data['businessUnitPhoneNumber'];
    this['redirectUrl'] = data['redirectUrl'];
    this['requirePassword'] = data['requirePassword'];
    this['billingAddressCollectionType'] = data['billingAddressCollectionType'];
    this['phoneNumberVerificationIdentifier'] = data['phoneNumberVerificationIdentifier'];
    this['paperlessBillingEnabled'] = data['paperlessBillingEnabled'];
    this['autopayEnabled'] = data['autopayEnabled'];
    this['upcomingPaymentNotificationsEnabled'] = data['upcomingPaymentNotificationsEnabled'];
    this['billNotificationsEnabled'] = data['billNotificationsEnabled'];
    this['smsNotificationsEnabled'] = data['smsNotificationsEnabled'];
    this['configuration'] = data['configuration'];
    this['account'] = data['account'];
    this['nextSession'] = data['nextSession'];
  }
}

export class OpenCharge {
  /**  */
  'amount': number;

  /**  */
  'description': string;

  constructor(data: undefined | any = {}) {
    this['amount'] = data['amount'];
    this['description'] = data['description'];
  }
}

export class OpenTicketDto {
  /**  */
  'id': number;

  /**  */
  'title': string;

  /**  */
  'modifiedDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['title'] = data['title'];
    this['modifiedDate'] = data['modifiedDate'];
  }
}

export class OptionalAddress {
  /**  */
  'streetAddress': string;

  /**  */
  'streetAddress2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['streetAddress'] = data['streetAddress'];
    this['streetAddress2'] = data['streetAddress2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OrganizationGetDto {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'agencyId': number;

  /**  */
  'notes': string;

  /**  */
  'paystarAccountManagerId': number;

  /**  */
  'paystarAccountManager': string;

  /**  */
  'hubSpotIdentifier': string;

  /**  */
  'hubSpotUrl': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['agencyId'] = data['agencyId'];
    this['notes'] = data['notes'];
    this['paystarAccountManagerId'] = data['paystarAccountManagerId'];
    this['paystarAccountManager'] = data['paystarAccountManager'];
    this['hubSpotIdentifier'] = data['hubSpotIdentifier'];
    this['hubSpotUrl'] = data['hubSpotUrl'];
    this['id'] = data['id'];
  }
}

export class OutboundCallSummaryDto {
  /**  */
  'total': number;

  /**  */
  'answered': number;

  /**  */
  'noAnswer': number;

  /**  */
  'inProgress': number;

  /**  */
  'invalid': number;

  /**  */
  'error': number;

  constructor(data: undefined | any = {}) {
    this['total'] = data['total'];
    this['answered'] = data['answered'];
    this['noAnswer'] = data['noAnswer'];
    this['inProgress'] = data['inProgress'];
    this['invalid'] = data['invalid'];
    this['error'] = data['error'];
  }
}

export class OutboundIvrEvent {
  /**  */
  'businessUnitId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'followUpMessage': string;

  /**  */
  'followUpPhoneNumber': string;

  /**  */
  'status': string;

  /**  */
  'summaryText': string;

  /**  */
  'message': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['followUpMessage'] = data['followUpMessage'];
    this['followUpPhoneNumber'] = data['followUpPhoneNumber'];
    this['status'] = data['status'];
    this['summaryText'] = data['summaryText'];
    this['message'] = data['message'];
  }
}

export class OutboundIvrJobGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'maxAttempts': number;

  /**  */
  'message': string;

  /**  */
  'followUpMessage': string;

  /**  */
  'followUpPhoneNumber': string;

  /**  */
  'status': string;

  /**  */
  'summaryText': string;

  /**  */
  'summaryJson': string;

  /**  */
  'reportUrl': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['maxAttempts'] = data['maxAttempts'];
    this['message'] = data['message'];
    this['followUpMessage'] = data['followUpMessage'];
    this['followUpPhoneNumber'] = data['followUpPhoneNumber'];
    this['status'] = data['status'];
    this['summaryText'] = data['summaryText'];
    this['summaryJson'] = data['summaryJson'];
    this['reportUrl'] = data['reportUrl'];
    this['id'] = data['id'];
  }
}

export class OutboundIvrJobSummaryDto {
  /**  */
  'id': number;

  /**  */
  'createdDate': Date;

  /**  */
  'status': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'message': string;

  /**  */
  'summaryText': string;

  /**  */
  'summaryJson': string;

  /**  */
  'reportUrl': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['createdDate'] = data['createdDate'];
    this['status'] = data['status'];
    this['referenceNumber'] = data['referenceNumber'];
    this['message'] = data['message'];
    this['summaryText'] = data['summaryText'];
    this['summaryJson'] = data['summaryJson'];
    this['reportUrl'] = data['reportUrl'];
  }
}

export class OutboundWebhookPayloadDto {
  /**  */
  'payload': string;

  constructor(data: undefined | any = {}) {
    this['payload'] = data['payload'];
  }
}

export class OutboundWebhookSummaryDto {
  /**  */
  'id': number;

  /**  */
  'url': string;

  /**  */
  'identifier': string;

  /**  */
  'initialSendDate': Date;

  /**  */
  'status': string;

  /**  */
  'attempts': number;

  /**  */
  'responseCode': string;

  /**  */
  'businessUnitName': string;

  /**  */
  'type': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['url'] = data['url'];
    this['identifier'] = data['identifier'];
    this['initialSendDate'] = data['initialSendDate'];
    this['status'] = data['status'];
    this['attempts'] = data['attempts'];
    this['responseCode'] = data['responseCode'];
    this['businessUnitName'] = data['businessUnitName'];
    this['type'] = data['type'];
  }
}

export class PaperlessBillingActivityLogDetailDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'activityType': string;

  /**  */
  'emailAddress': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'modifiedByUserEmail': string;

  /**  */
  'modifiedDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['activityType'] = data['activityType'];
    this['emailAddress'] = data['emailAddress'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['modifiedByUserEmail'] = data['modifiedByUserEmail'];
    this['modifiedDate'] = data['modifiedDate'];
  }
}

export class PaperlessBillingActivityLogReportDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'paidItem': string;

  /**  */
  'activityType': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'emailAddress': string;

  /**  */
  'modifiedByUserEmail': string;

  /**  */
  'modifiedByUserName': string;

  /**  */
  'createdByUserId': number;

  /**  */
  'modifiedByUserId': number;

  /**  */
  'deletedByUserId': number;

  /**  */
  'isDeleted': boolean;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'deletedDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paidItem'] = data['paidItem'];
    this['activityType'] = data['activityType'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['emailAddress'] = data['emailAddress'];
    this['modifiedByUserEmail'] = data['modifiedByUserEmail'];
    this['modifiedByUserName'] = data['modifiedByUserName'];
    this['createdByUserId'] = data['createdByUserId'];
    this['modifiedByUserId'] = data['modifiedByUserId'];
    this['deletedByUserId'] = data['deletedByUserId'];
    this['isDeleted'] = data['isDeleted'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['deletedDate'] = data['deletedDate'];
  }
}

export class PaperlessBillingEnrollmentRequest {
  /**  */
  'emailAddress': string;

  /**  */
  'activityType': string;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['activityType'] = data['activityType'];
  }
}

export class PaperlessBillingStatusEventPayload {
  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'accountEmailAddress': string;

  /**  */
  'userEmailAddress': string;

  constructor(data: undefined | any = {}) {
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['accountEmailAddress'] = data['accountEmailAddress'];
    this['userEmailAddress'] = data['userEmailAddress'];
  }
}

export class ParseBills_Config {
  /**  */
  'billingCycleId': string;

  /**  */
  'strategy': string;

  /**  */
  'blobUrl': string;

  constructor(data: undefined | any = {}) {
    this['billingCycleId'] = data['billingCycleId'];
    this['strategy'] = data['strategy'];
    this['blobUrl'] = data['blobUrl'];
  }
}

export class ParseFile_Config {
  /**  */
  'blobUrl': string;

  /**  */
  'missingAccountStrategy': string;

  /**  */
  'fieldMapConfiguration': string;

  /**  */
  'fieldOverrides': string;

  /**  */
  'overrideBadUploadProtection': boolean;

  /**  */
  'skippedAccountThreshold': number;

  constructor(data: undefined | any = {}) {
    this['blobUrl'] = data['blobUrl'];
    this['missingAccountStrategy'] = data['missingAccountStrategy'];
    this['fieldMapConfiguration'] = data['fieldMapConfiguration'];
    this['fieldOverrides'] = data['fieldOverrides'];
    this['overrideBadUploadProtection'] = data['overrideBadUploadProtection'];
    this['skippedAccountThreshold'] = data['skippedAccountThreshold'];
  }
}

export class PayStarAchBatchSummaryDto {
  /**  */
  'id': number;

  /**  */
  'odfiIdentifier': string;

  /**  */
  'batchStatus': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'merchantAccountId': number;

  /**  */
  'batchStartDate': Date;

  /**  */
  'batchCloseDate': Date;

  /**  */
  'batchSettleDate': Date;

  /**  */
  'nachaFileBlobUrl': string;

  /**  */
  'nachaFileGenerationDate': Date;

  /**  */
  'nachaFileSubmissionDate': Date;

  /**  */
  'nachaFileSubmissionStatus': string;

  /**  */
  'transactionCount': number;

  /**  */
  'transactionVolume': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['odfiIdentifier'] = data['odfiIdentifier'];
    this['batchStatus'] = data['batchStatus'];
    this['merchantNumber'] = data['merchantNumber'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['batchStartDate'] = data['batchStartDate'];
    this['batchCloseDate'] = data['batchCloseDate'];
    this['batchSettleDate'] = data['batchSettleDate'];
    this['nachaFileBlobUrl'] = data['nachaFileBlobUrl'];
    this['nachaFileGenerationDate'] = data['nachaFileGenerationDate'];
    this['nachaFileSubmissionDate'] = data['nachaFileSubmissionDate'];
    this['nachaFileSubmissionStatus'] = data['nachaFileSubmissionStatus'];
    this['transactionCount'] = data['transactionCount'];
    this['transactionVolume'] = data['transactionVolume'];
  }
}

export class PayStarAchTransactionGetDto {
  /**  */
  'amount': number;

  /**  */
  'transactionDate': Date;

  /**  */
  'transactionStatus': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'payStarAchBatchId': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'secCode': string;

  /**  */
  'clientReference': string;

  /**  */
  'returnTime': Date;

  /**  */
  'returnCode': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['amount'] = data['amount'];
    this['transactionDate'] = data['transactionDate'];
    this['transactionStatus'] = data['transactionStatus'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['merchantNumber'] = data['merchantNumber'];
    this['payStarAchBatchId'] = data['payStarAchBatchId'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['secCode'] = data['secCode'];
    this['clientReference'] = data['clientReference'];
    this['returnTime'] = data['returnTime'];
    this['returnCode'] = data['returnCode'];
    this['id'] = data['id'];
  }
}

export class PaymentChannelClientSummaryDto {
  /**  */
  'id': number;

  /**  */
  'channelType': string;

  /**  */
  'enabled': boolean;

  /**  */
  'allowsFreeFormPayment': boolean;

  /**  */
  'requiresMinimumOfBalance': boolean;

  /**  */
  'creditCard': PaymentChannelSourceClientSummaryDto;

  /**  */
  'ach': PaymentChannelSourceClientSummaryDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['channelType'] = data['channelType'];
    this['enabled'] = data['enabled'];
    this['allowsFreeFormPayment'] = data['allowsFreeFormPayment'];
    this['requiresMinimumOfBalance'] = data['requiresMinimumOfBalance'];
    this['creditCard'] = data['creditCard'];
    this['ach'] = data['ach'];
  }
}

export class PaymentChannelSourceClientSummaryDto {
  /**  */
  'enabled': boolean;

  /**  */
  'paymentMerchantAccountId': number;

  /**  */
  'paymentMerchantAccount': MerchantAccountClientSummaryDto;

  /**  */
  'feeMerchantAccountId': number;

  /**  */
  'feeMerchantAccount': MerchantAccountClientSummaryDto;

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['paymentMerchantAccountId'] = data['paymentMerchantAccountId'];
    this['paymentMerchantAccount'] = data['paymentMerchantAccount'];
    this['feeMerchantAccountId'] = data['feeMerchantAccountId'];
    this['feeMerchantAccount'] = data['feeMerchantAccount'];
  }
}

export class PaymentChannelSourceSummaryDto {
  /**  */
  'enabled': boolean;

  /**  */
  'paymentMerchantAccountId': number;

  /**  */
  'feeMerchantAccountId': number;

  /**  */
  'paymentMerchantAccount': MerchantAccountChannelSummaryDto;

  /**  */
  'feeMerchantAccount': MerchantAccountChannelSummaryDto;

  /**  */
  'tokenizer': TokenizerConfig;

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['paymentMerchantAccountId'] = data['paymentMerchantAccountId'];
    this['feeMerchantAccountId'] = data['feeMerchantAccountId'];
    this['paymentMerchantAccount'] = data['paymentMerchantAccount'];
    this['feeMerchantAccount'] = data['feeMerchantAccount'];
    this['tokenizer'] = data['tokenizer'];
  }
}

export class PaymentChannelSummaryDto {
  /**  */
  'id': number;

  /**  */
  'channelType': string;

  /**  */
  'enabled': boolean;

  /**  */
  'allowsFreeFormPayment': boolean;

  /**  */
  'requiresMinimumOfBalance': boolean;

  /**  */
  'businessUnitId': number;

  /**  */
  'creditCard': PaymentChannelSourceSummaryDto;

  /**  */
  'ach': PaymentChannelSourceSummaryDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['channelType'] = data['channelType'];
    this['enabled'] = data['enabled'];
    this['allowsFreeFormPayment'] = data['allowsFreeFormPayment'];
    this['requiresMinimumOfBalance'] = data['requiresMinimumOfBalance'];
    this['businessUnitId'] = data['businessUnitId'];
    this['creditCard'] = data['creditCard'];
    this['ach'] = data['ach'];
  }
}

export class PaymentDetailDto {
  /**  */
  'paymentType': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'paymentSessionId': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'paymentBatchId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'channel': string;

  /**  */
  'amount': Currency;

  /**  */
  'serviceFeeAmount': number;

  /**  */
  'status': string;

  /**  */
  'gateway': string;

  /**  */
  'accountSnapshot': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'paymentMetaJson': string;

  /**  */
  'paymentSourceJson': string;

  /**  */
  'id': number;

  /**  */
  'createdDate': Date;

  /**  */
  'organizationId': number;

  /**  */
  'gatewayErrorMessage': string;

  /**  */
  'reversalReason': string;

  /**  */
  'canVoid': boolean;

  /**  */
  'canRefund': boolean;

  /**  */
  'createdByUserName': string;

  /**  */
  'createdByUserRole': string;

  /**  */
  'transactions': TransactionDetailDto[];

  /**  */
  'relatedPayments': PaymentSummaryDto[];

  /**  */
  'lineItems': PaymentSessionLineItemGetDto[];

  /**  */
  'accounts': AccountSummaryDto[];

  /**  */
  'isMultiAccount': boolean;

  /**  */
  'accountId': number;

  constructor(data: undefined | any = {}) {
    this['paymentType'] = data['paymentType'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paymentSessionId'] = data['paymentSessionId'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['paymentBatchId'] = data['paymentBatchId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['channel'] = data['channel'];
    this['amount'] = data['amount'];
    this['serviceFeeAmount'] = data['serviceFeeAmount'];
    this['status'] = data['status'];
    this['gateway'] = data['gateway'];
    this['accountSnapshot'] = data['accountSnapshot'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['paymentMetaJson'] = data['paymentMetaJson'];
    this['paymentSourceJson'] = data['paymentSourceJson'];
    this['id'] = data['id'];
    this['createdDate'] = data['createdDate'];
    this['organizationId'] = data['organizationId'];
    this['gatewayErrorMessage'] = data['gatewayErrorMessage'];
    this['reversalReason'] = data['reversalReason'];
    this['canVoid'] = data['canVoid'];
    this['canRefund'] = data['canRefund'];
    this['createdByUserName'] = data['createdByUserName'];
    this['createdByUserRole'] = data['createdByUserRole'];
    this['transactions'] = data['transactions'];
    this['relatedPayments'] = data['relatedPayments'];
    this['lineItems'] = data['lineItems'];
    this['accounts'] = data['accounts'];
    this['isMultiAccount'] = data['isMultiAccount'];
    this['accountId'] = data['accountId'];
  }
}

export class PaymentFieldDetailDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'key': string;

  /**  */
  'label': string;

  /**  */
  'description': string;

  /**  */
  'isDisplayField': boolean;

  /**  */
  'isRequired': boolean;

  /**  */
  'isQuickPay': boolean;

  /**  */
  'isPointOfSale': boolean;

  /**  */
  'fieldType': string;

  /**  */
  'fieldTypeOptionsJson': string;

  /**  */
  'fieldTypeOptions': object;

  /**  */
  'accountFieldMappingKey': string;

  /**  */
  'order': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['key'] = data['key'];
    this['label'] = data['label'];
    this['description'] = data['description'];
    this['isDisplayField'] = data['isDisplayField'];
    this['isRequired'] = data['isRequired'];
    this['isQuickPay'] = data['isQuickPay'];
    this['isPointOfSale'] = data['isPointOfSale'];
    this['fieldType'] = data['fieldType'];
    this['fieldTypeOptionsJson'] = data['fieldTypeOptionsJson'];
    this['fieldTypeOptions'] = data['fieldTypeOptions'];
    this['accountFieldMappingKey'] = data['accountFieldMappingKey'];
    this['order'] = data['order'];
  }
}

export class PaymentFieldGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'key': string;

  /**  */
  'label': string;

  /**  */
  'description': string;

  /**  */
  'isDisplayField': boolean;

  /**  */
  'isRequired': boolean;

  /**  */
  'isQuickPay': boolean;

  /**  */
  'isPointOfSale': boolean;

  /**  */
  'fieldType': string;

  /**  */
  'fieldTypeOptionsJson': string;

  /**  */
  'accountFieldMappingKey': string;

  /**  */
  'order': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['key'] = data['key'];
    this['label'] = data['label'];
    this['description'] = data['description'];
    this['isDisplayField'] = data['isDisplayField'];
    this['isRequired'] = data['isRequired'];
    this['isQuickPay'] = data['isQuickPay'];
    this['isPointOfSale'] = data['isPointOfSale'];
    this['fieldType'] = data['fieldType'];
    this['fieldTypeOptionsJson'] = data['fieldTypeOptionsJson'];
    this['accountFieldMappingKey'] = data['accountFieldMappingKey'];
    this['order'] = data['order'];
    this['id'] = data['id'];
  }
}

export class PaymentFieldItemDto {
  /**  */
  'key': string;

  /**  */
  'label': string;

  /**  */
  'value': string;

  constructor(data: undefined | any = {}) {
    this['key'] = data['key'];
    this['label'] = data['label'];
    this['value'] = data['value'];
  }
}

export class PaymentGatewayConfigGetDto {
  /**  */
  'paymentGatewayType': string;

  /**  */
  'name': string;

  /**  */
  'url': string;

  /**  */
  'username': string;

  /**  */
  'apiKey': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'emvDelaySeconds': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['paymentGatewayType'] = data['paymentGatewayType'];
    this['name'] = data['name'];
    this['url'] = data['url'];
    this['username'] = data['username'];
    this['apiKey'] = data['apiKey'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['emvDelaySeconds'] = data['emvDelaySeconds'];
    this['id'] = data['id'];
  }
}

export class PaymentGatewayConfigSummaryDto {
  /**  */
  'id': number;

  /**  */
  'paymentGatewayType': string;

  /**  */
  'url': string;

  /**  */
  'username': string;

  /**  */
  'name': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'emvDelaySeconds': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['paymentGatewayType'] = data['paymentGatewayType'];
    this['url'] = data['url'];
    this['username'] = data['username'];
    this['name'] = data['name'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['emvDelaySeconds'] = data['emvDelaySeconds'];
  }
}

export class PaymentGatewayFundingEventSummary {
  /**  */
  'activityFound': boolean;

  /**  */
  'fundingGroupIds': string[];

  constructor(data: undefined | any = {}) {
    this['activityFound'] = data['activityFound'];
    this['fundingGroupIds'] = data['fundingGroupIds'];
  }
}

export class PaymentIntentConfig {
  /**  */
  'paymentState': string;

  /**  */
  'channel': string;

  /**  */
  'enabled': boolean;

  /**  */
  'scope': string;

  /**  */
  'organizationId': number;

  /**  */
  'organizationName': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'businessUnitSlug': string;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'businessUnitHeader': string;

  /**  */
  'businessUnitSubHeader': string;

  /**  */
  'businessUnitLogoUrl': string;

  /**  */
  'businessUnitMobileLogo': string;

  /**  */
  'businessUnitPrimaryColor': string;

  /**  */
  'businessUnitPhoneNumber': string;

  /**  */
  'businessUnitWebsiteUrl': string;

  /**  */
  'businessUnitAddress': Address;

  /**  */
  'businessUnitPastDueAccounts': boolean;

  /**  */
  'businessUnitAutopayEarlyBilling': boolean;

  /**  */
  'businessUnitEnableZeroValueEmbeddedCharges': boolean;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'currencyCode': string;

  /**  */
  'timezone': string;

  /**  */
  'openChargeEnabled': boolean;

  /**  */
  'paymentSessionLoginEnabled': boolean;

  /**  */
  'minPaymentAmount': number;

  /**  */
  'maxPaymentAmount': number;

  /**  */
  'paymentFields': PaymentFieldDetailDto[];

  /**  */
  'messages': MessageDetailDto[];

  /**  */
  'accountConfig': AccountConfig;

  /**  */
  'paymentSourceConfig': PaymentSourceConfig;

  /**  */
  'productConfig': ProductConfig;

  /**  */
  'recaptchaConfig': RecaptchaClientConfig;

  constructor(data: undefined | any = {}) {
    this['paymentState'] = data['paymentState'];
    this['channel'] = data['channel'];
    this['enabled'] = data['enabled'];
    this['scope'] = data['scope'];
    this['organizationId'] = data['organizationId'];
    this['organizationName'] = data['organizationName'];
    this['businessUnitId'] = data['businessUnitId'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['businessUnitHeader'] = data['businessUnitHeader'];
    this['businessUnitSubHeader'] = data['businessUnitSubHeader'];
    this['businessUnitLogoUrl'] = data['businessUnitLogoUrl'];
    this['businessUnitMobileLogo'] = data['businessUnitMobileLogo'];
    this['businessUnitPrimaryColor'] = data['businessUnitPrimaryColor'];
    this['businessUnitPhoneNumber'] = data['businessUnitPhoneNumber'];
    this['businessUnitWebsiteUrl'] = data['businessUnitWebsiteUrl'];
    this['businessUnitAddress'] = data['businessUnitAddress'];
    this['businessUnitPastDueAccounts'] = data['businessUnitPastDueAccounts'];
    this['businessUnitAutopayEarlyBilling'] = data['businessUnitAutopayEarlyBilling'];
    this['businessUnitEnableZeroValueEmbeddedCharges'] = data['businessUnitEnableZeroValueEmbeddedCharges'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['currencyCode'] = data['currencyCode'];
    this['timezone'] = data['timezone'];
    this['openChargeEnabled'] = data['openChargeEnabled'];
    this['paymentSessionLoginEnabled'] = data['paymentSessionLoginEnabled'];
    this['minPaymentAmount'] = data['minPaymentAmount'];
    this['maxPaymentAmount'] = data['maxPaymentAmount'];
    this['paymentFields'] = data['paymentFields'];
    this['messages'] = data['messages'];
    this['accountConfig'] = data['accountConfig'];
    this['paymentSourceConfig'] = data['paymentSourceConfig'];
    this['productConfig'] = data['productConfig'];
    this['recaptchaConfig'] = data['recaptchaConfig'];
  }
}

export class PaymentIntentDetailDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'sessionIdentifier': string;

  /**  */
  'channel': string;

  /**  */
  'paymentSourceId': number;

  /**  */
  'status': string;

  /**  */
  'paymentFieldsJson': string;

  /**  */
  'clientReference': string;

  /**  */
  'successUrl': string;

  /**  */
  'returnUrl': string;

  /**  */
  'scope': string;

  /**  */
  'id': number;

  /**  */
  'organizationId': number;

  /**  */
  'lineItems': PaymentSessionLineItemGetDto[];

  /**  */
  'paymentSource': PaymentSourceSummaryDto;

  /**  */
  'accounts': AccountSummaryDto[];

  /**  */
  'isMultiAccount': boolean;

  /**  */
  'paymentFields': object;

  /**  */
  'subtotal': number;

  /**  */
  'serviceFeeCalculation': ServiceFeeCalculationDto;

  /**  */
  'accountId': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['channel'] = data['channel'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['status'] = data['status'];
    this['paymentFieldsJson'] = data['paymentFieldsJson'];
    this['clientReference'] = data['clientReference'];
    this['successUrl'] = data['successUrl'];
    this['returnUrl'] = data['returnUrl'];
    this['scope'] = data['scope'];
    this['id'] = data['id'];
    this['organizationId'] = data['organizationId'];
    this['lineItems'] = data['lineItems'];
    this['paymentSource'] = data['paymentSource'];
    this['accounts'] = data['accounts'];
    this['isMultiAccount'] = data['isMultiAccount'];
    this['paymentFields'] = data['paymentFields'];
    this['subtotal'] = data['subtotal'];
    this['serviceFeeCalculation'] = data['serviceFeeCalculation'];
    this['accountId'] = data['accountId'];
  }
}

export class PaymentIntentPaymentSource {
  /**  */
  'externalIdentifier': string;

  /**  */
  'savedPaymentSourceId': number;

  /**  */
  'newPaymentSource': NewPaymentSource;

  /**  */
  'savePaymentSource': boolean;

  /**  */
  'saveAccountPaymentSource': boolean;

  constructor(data: undefined | any = {}) {
    this['externalIdentifier'] = data['externalIdentifier'];
    this['savedPaymentSourceId'] = data['savedPaymentSourceId'];
    this['newPaymentSource'] = data['newPaymentSource'];
    this['savePaymentSource'] = data['savePaymentSource'];
    this['saveAccountPaymentSource'] = data['saveAccountPaymentSource'];
  }
}

export class PaymentIntentResultDto {
  /**  */
  'id': number;

  /**  */
  'identifier': string;

  /**  */
  'sessionStatus': string;

  /**  */
  'paymentDate': Date;

  /**  */
  'basePaymentId': number;

  /**  */
  'basePaymentReference': string;

  /**  */
  'basePaymentStatus': string;

  /**  */
  'basePaymentAmountMinor': number;

  /**  */
  'serviceFeeAmountMinor': number;

  /**  */
  'errorMessage': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['identifier'] = data['identifier'];
    this['sessionStatus'] = data['sessionStatus'];
    this['paymentDate'] = data['paymentDate'];
    this['basePaymentId'] = data['basePaymentId'];
    this['basePaymentReference'] = data['basePaymentReference'];
    this['basePaymentStatus'] = data['basePaymentStatus'];
    this['basePaymentAmountMinor'] = data['basePaymentAmountMinor'];
    this['serviceFeeAmountMinor'] = data['serviceFeeAmountMinor'];
    this['errorMessage'] = data['errorMessage'];
  }
}

export class PaymentLookupDto {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'paymentMethod': string;

  /**  */
  'paymentSourceAccountNumberLast4': string;

  /**  */
  'authCode': string;

  /**  */
  'businessUnitHeader': string;

  /**  */
  'businessUnitSubHeader': string;

  /**  */
  'businessUnitLogoUrl': string;

  /**  */
  'businessUnitMobileLogoUrl': string;

  /**  */
  'paymentType': string;

  /**  */
  'amountMinor': number;

  /**  */
  'serviceFeeAmount': number;

  /**  */
  'paymentDescriptorLabel': string;

  /**  */
  'createdDate': Date;

  /**  */
  'status': string;

  /**  */
  'hasAdjustment': boolean;

  /**  */
  'redirectReferenceNumber': string;

  /**  */
  'lineItems': PaymentSessionLineItemGetDto[];

  /**  */
  'relatedPayments': PaymentSummaryDto[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['paymentMethod'] = data['paymentMethod'];
    this['paymentSourceAccountNumberLast4'] = data['paymentSourceAccountNumberLast4'];
    this['authCode'] = data['authCode'];
    this['businessUnitHeader'] = data['businessUnitHeader'];
    this['businessUnitSubHeader'] = data['businessUnitSubHeader'];
    this['businessUnitLogoUrl'] = data['businessUnitLogoUrl'];
    this['businessUnitMobileLogoUrl'] = data['businessUnitMobileLogoUrl'];
    this['paymentType'] = data['paymentType'];
    this['amountMinor'] = data['amountMinor'];
    this['serviceFeeAmount'] = data['serviceFeeAmount'];
    this['paymentDescriptorLabel'] = data['paymentDescriptorLabel'];
    this['createdDate'] = data['createdDate'];
    this['status'] = data['status'];
    this['hasAdjustment'] = data['hasAdjustment'];
    this['redirectReferenceNumber'] = data['redirectReferenceNumber'];
    this['lineItems'] = data['lineItems'];
    this['relatedPayments'] = data['relatedPayments'];
  }
}

export class PaymentReceiptDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'sessionIdentifier': string;

  /**  */
  'channel': string;

  /**  */
  'accountId': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'status': string;

  /**  */
  'paymentFieldsJson': string;

  /**  */
  'clientReference': string;

  /**  */
  'successUrl': string;

  /**  */
  'returnUrl': string;

  /**  */
  'scope': string;

  /**  */
  'id': number;

  /**  */
  'authCode': string;

  /**  */
  'hasAdjustments': boolean;

  /**  */
  'refundAmount': Currency;

  /**  */
  'businessUnitFeaturesPaymentReceiptBarcodes': boolean;

  /**  */
  'payments': PaymentSummaryDto[];

  /**  */
  'basePayment': PaymentSummaryDto;

  /**  */
  'serviceFee': PaymentSummaryDto;

  /**  */
  'accounts': AccountSummaryDto[];

  /**  */
  'isMultiAccount': boolean;

  /**  */
  'accountFields': AccountFieldGetDto[];

  /**  */
  'lineItems': PaymentSessionLineItemGetDto[];

  /**  */
  'paymentFieldItems': PaymentFieldItemDto[];

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['channel'] = data['channel'];
    this['accountId'] = data['accountId'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['status'] = data['status'];
    this['paymentFieldsJson'] = data['paymentFieldsJson'];
    this['clientReference'] = data['clientReference'];
    this['successUrl'] = data['successUrl'];
    this['returnUrl'] = data['returnUrl'];
    this['scope'] = data['scope'];
    this['id'] = data['id'];
    this['authCode'] = data['authCode'];
    this['hasAdjustments'] = data['hasAdjustments'];
    this['refundAmount'] = data['refundAmount'];
    this['businessUnitFeaturesPaymentReceiptBarcodes'] = data['businessUnitFeaturesPaymentReceiptBarcodes'];
    this['payments'] = data['payments'];
    this['basePayment'] = data['basePayment'];
    this['serviceFee'] = data['serviceFee'];
    this['accounts'] = data['accounts'];
    this['isMultiAccount'] = data['isMultiAccount'];
    this['accountFields'] = data['accountFields'];
    this['lineItems'] = data['lineItems'];
    this['paymentFieldItems'] = data['paymentFieldItems'];
  }
}

export class PaymentSearchDto {
  /**  */
  'id': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'status': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'amount_Value': number;

  /**  */
  'createdDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['referenceNumber'] = data['referenceNumber'];
    this['status'] = data['status'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['amount_Value'] = data['amount_Value'];
    this['createdDate'] = data['createdDate'];
  }
}

export class PaymentSessionDetailDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'sessionIdentifier': string;

  /**  */
  'channel': string;

  /**  */
  'paymentSourceId': number;

  /**  */
  'status': string;

  /**  */
  'paymentFieldsJson': string;

  /**  */
  'clientReference': string;

  /**  */
  'successUrl': string;

  /**  */
  'returnUrl': string;

  /**  */
  'scope': string;

  /**  */
  'id': number;

  /**  */
  'accounts': AccountSummaryDto[];

  /**  */
  'isMultiAccount': boolean;

  /**  */
  'paymentSource': PaymentSourceSummaryDto;

  /**  */
  'serviceFeeCalculations': ServiceFeeCalculation;

  /**  */
  'payments': PaymentDetailDto[];

  /**  */
  'lineItems': PaymentSessionLineItemGetDto[];

  /**  */
  'basePayment': PaymentDetailDto;

  /**  */
  'serviceFee': PaymentDetailDto;

  /**  */
  'subtotal': number;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'accountId': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['channel'] = data['channel'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['status'] = data['status'];
    this['paymentFieldsJson'] = data['paymentFieldsJson'];
    this['clientReference'] = data['clientReference'];
    this['successUrl'] = data['successUrl'];
    this['returnUrl'] = data['returnUrl'];
    this['scope'] = data['scope'];
    this['id'] = data['id'];
    this['accounts'] = data['accounts'];
    this['isMultiAccount'] = data['isMultiAccount'];
    this['paymentSource'] = data['paymentSource'];
    this['serviceFeeCalculations'] = data['serviceFeeCalculations'];
    this['payments'] = data['payments'];
    this['lineItems'] = data['lineItems'];
    this['basePayment'] = data['basePayment'];
    this['serviceFee'] = data['serviceFee'];
    this['subtotal'] = data['subtotal'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['accountId'] = data['accountId'];
  }
}

export class PaymentSessionLineItemGetDto {
  /**  */
  'paymentSessionId': number;

  /**  */
  'lineItemType': string;

  /**  */
  'description': string;

  /**  */
  'price': Currency;

  /**  */
  'accountId': number;

  /**  */
  'productId': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['paymentSessionId'] = data['paymentSessionId'];
    this['lineItemType'] = data['lineItemType'];
    this['description'] = data['description'];
    this['price'] = data['price'];
    this['accountId'] = data['accountId'];
    this['productId'] = data['productId'];
    this['id'] = data['id'];
  }
}

export class PaymentSourceConfig {
  /**  */
  'creditCard': CreditCardConfig;

  /**  */
  'ach': AchConfig;

  /**  */
  'paymentSources': PaymentSourceSummaryDto[];

  /**  */
  'accountWalletsEnabled': boolean;

  /**  */
  'paymentSourceTypes': string[];

  constructor(data: undefined | any = {}) {
    this['creditCard'] = data['creditCard'];
    this['ach'] = data['ach'];
    this['paymentSources'] = data['paymentSources'];
    this['accountWalletsEnabled'] = data['accountWalletsEnabled'];
    this['paymentSourceTypes'] = data['paymentSourceTypes'];
  }
}

export class PaymentSourceJsonDto {
  /**  */
  'id': number;

  /**  */
  'accountType': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'accountNumberLast4': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountType'] = data['accountType'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['accountHolderName'] = data['accountHolderName'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
  }
}

export class PaymentSourceSummaryDto {
  /**  */
  'id': number;

  /**  */
  'paymentSourceType': string;

  /**  */
  'nickname': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'accountNumberMasked': string;

  /**  */
  'accountType': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'isAutopay': boolean;

  /**  */
  'isVerified': boolean;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountNumberLast4': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['nickname'] = data['nickname'];
    this['accountHolderName'] = data['accountHolderName'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
    this['accountType'] = data['accountType'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['isAutopay'] = data['isAutopay'];
    this['isVerified'] = data['isVerified'];
    this['address'] = data['address'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
  }
}

export class PaymentSummaryDto {
  /**  */
  'id': number;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'referenceNumber': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'createdByUserRole': string;

  /**  */
  'amount': Currency;

  /**  */
  'amountMinor': number;

  /**  */
  'status': string;

  /**  */
  'accountId': number;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'paymentMetaJson': string;

  /**  */
  'paymentSourceJson': string;

  /**  */
  'channel': string;

  /**  */
  'paymentType': string;

  /**  */
  'organizationName': string;

  /**  */
  'businessUnitName': string;

  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'paymentSource': PaymentSourceJsonDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['referenceNumber'] = data['referenceNumber'];
    this['createdByUserName'] = data['createdByUserName'];
    this['createdByUserRole'] = data['createdByUserRole'];
    this['amount'] = data['amount'];
    this['amountMinor'] = data['amountMinor'];
    this['status'] = data['status'];
    this['accountId'] = data['accountId'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['paymentMetaJson'] = data['paymentMetaJson'];
    this['paymentSourceJson'] = data['paymentSourceJson'];
    this['channel'] = data['channel'];
    this['paymentType'] = data['paymentType'];
    this['organizationName'] = data['organizationName'];
    this['businessUnitName'] = data['businessUnitName'];
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['paymentSource'] = data['paymentSource'];
  }
}

export class PaymentVoidRefundEventPayload {
  /**  */
  'accountId': number;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'amount': number;

  /**  */
  'amountFormatted': string;

  /**  */
  'channel': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'status': string;

  /**  */
  'gatewayErrorMessage': string;

  /**  */
  'accountNumberLast4': string;

  /**  */
  'paymentType': string;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['createdByUserName'] = data['createdByUserName'];
    this['amount'] = data['amount'];
    this['amountFormatted'] = data['amountFormatted'];
    this['channel'] = data['channel'];
    this['referenceNumber'] = data['referenceNumber'];
    this['status'] = data['status'];
    this['gatewayErrorMessage'] = data['gatewayErrorMessage'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
    this['paymentType'] = data['paymentType'];
  }
}

export class PhoneVerificationIdentifierDto {
  /**  */
  'expiresAt': Date;

  /**  */
  'verificationIdentifier': string;

  constructor(data: undefined | any = {}) {
    this['expiresAt'] = data['expiresAt'];
    this['verificationIdentifier'] = data['verificationIdentifier'];
  }
}

export class PlaidLinkTokenDto {
  /**  */
  'linkToken': string;

  /**  */
  'expiration': Date;

  constructor(data: undefined | any = {}) {
    this['linkToken'] = data['linkToken'];
    this['expiration'] = data['expiration'];
  }
}

export class ProcessAccountUploadRequest {
  /**  */
  'fileName': string;

  /**  */
  'blobUrl': string;

  /**  */
  'dueDateOverride': Date;

  /**  */
  'issueDateOverride': Date;

  constructor(data: undefined | any = {}) {
    this['fileName'] = data['fileName'];
    this['blobUrl'] = data['blobUrl'];
    this['dueDateOverride'] = data['dueDateOverride'];
    this['issueDateOverride'] = data['issueDateOverride'];
  }
}

export class ProcessPaymentIntentRequest {
  /**  */
  'cardCvv': string;

  constructor(data: undefined | any = {}) {
    this['cardCvv'] = data['cardCvv'];
  }
}

export class ProductCharge {
  /**  */
  'productId': number;

  /**  */
  'price': number;

  constructor(data: undefined | any = {}) {
    this['productId'] = data['productId'];
    this['price'] = data['price'];
  }
}

export class ProductConfig {
  /**  */
  'enabled': boolean;

  /**  */
  'options': ProductSummaryDto[];

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['options'] = data['options'];
  }
}

export class ProductGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'sku': string;

  /**  */
  'price': number;

  /**  */
  'allowPriceModification': boolean;

  /**  */
  'active': boolean;

  /**  */
  'required': boolean;

  /**  */
  'paymentChannel': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['sku'] = data['sku'];
    this['price'] = data['price'];
    this['allowPriceModification'] = data['allowPriceModification'];
    this['active'] = data['active'];
    this['required'] = data['required'];
    this['paymentChannel'] = data['paymentChannel'];
    this['id'] = data['id'];
  }
}

export class ProductReportDto {
  /**  */
  'paymentId': number;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'paymentStatus': string;

  /**  */
  'productName': string;

  /**  */
  'productPaidAmount': number;

  /**  */
  'totalPaymentAmount': number;

  /**  */
  'createdDate': Date;

  constructor(data: undefined | any = {}) {
    this['paymentId'] = data['paymentId'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['paymentStatus'] = data['paymentStatus'];
    this['productName'] = data['productName'];
    this['productPaidAmount'] = data['productPaidAmount'];
    this['totalPaymentAmount'] = data['totalPaymentAmount'];
    this['createdDate'] = data['createdDate'];
  }
}

export class ProductSummaryDto {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'sku': string;

  /**  */
  'price': number;

  /**  */
  'allowPriceModification': boolean;

  /**  */
  'active': boolean;

  /**  */
  'required': boolean;

  /**  */
  'paymentChannel': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['sku'] = data['sku'];
    this['price'] = data['price'];
    this['allowPriceModification'] = data['allowPriceModification'];
    this['active'] = data['active'];
    this['required'] = data['required'];
    this['paymentChannel'] = data['paymentChannel'];
  }
}

export class ReadingsFileUploadedEventPayload {
  /**  */
  'blobUrl': string;

  /**  */
  'downloadBlobUrl': string;

  /**  */
  'userEmail': string;

  /**  */
  'fileName': string;

  constructor(data: undefined | any = {}) {
    this['blobUrl'] = data['blobUrl'];
    this['downloadBlobUrl'] = data['downloadBlobUrl'];
    this['userEmail'] = data['userEmail'];
    this['fileName'] = data['fileName'];
  }
}

export class ReadingsSyncLogSummaryDto {
  /**  */
  'id': number;

  /**  */
  'type': string;

  /**  */
  'description': string;

  /**  */
  'metaJson': string;

  /**  */
  'eventDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['type'] = data['type'];
    this['description'] = data['description'];
    this['metaJson'] = data['metaJson'];
    this['eventDate'] = data['eventDate'];
  }
}

export class RecaptchaClientConfig {
  /**  */
  'enabled': boolean;

  /**  */
  'siteKey': string;

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['siteKey'] = data['siteKey'];
  }
}

export class ReceiptEmailLogDto {
  /**  */
  'sender': string;

  /**  */
  'toEmail': string;

  /**  */
  'createdDate': Date;

  /**  */
  'status': string;

  constructor(data: undefined | any = {}) {
    this['sender'] = data['sender'];
    this['toEmail'] = data['toEmail'];
    this['createdDate'] = data['createdDate'];
    this['status'] = data['status'];
  }
}

export class RefundPaymentRequest {
  /**  */
  'paymentId': number;

  /**  */
  'amount': number;

  /**  */
  'newBalance': number;

  /**  */
  'reason': string;

  constructor(data: undefined | any = {}) {
    this['paymentId'] = data['paymentId'];
    this['amount'] = data['amount'];
    this['newBalance'] = data['newBalance'];
    this['reason'] = data['reason'];
  }
}

export class RemoveBillingCycleInsertRequest {
  /**  */
  'billingCycleId': number;

  constructor(data: undefined | any = {}) {
    this['billingCycleId'] = data['billingCycleId'];
  }
}

export class ScheduleManualJobRequest {
  /**  */
  'executionContext': object;

  /**  */
  'runOnDateUtc': Date;

  constructor(data: undefined | any = {}) {
    this['executionContext'] = data['executionContext'];
    this['runOnDateUtc'] = data['runOnDateUtc'];
  }
}

export class ScheduledPaymentCustomerSummaryDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'dateToProcess': Date;

  /**  */
  'dateToProcessLocal': string;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'schedulingStatus': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['dateToProcess'] = data['dateToProcess'];
    this['dateToProcessLocal'] = data['dateToProcessLocal'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['schedulingStatus'] = data['schedulingStatus'];
  }
}

export class ScheduledPaymentDetailDto {
  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'dateToProcess': Date;

  /**  */
  'dateToProcessLocal': string;

  /**  */
  'schedulingType': string;

  /**  */
  'schedulingStatus': string;

  /**  */
  'schedulingMessage': string;

  /**  */
  'idempotencyKey': string;

  /**  */
  'customerNotified': boolean;

  /**  */
  'paymentId': number;

  /**  */
  'autopaySettingsId': number;

  /**  */
  'id': number;

  /**  */
  'customerName': string;

  /**  */
  'customerEmail': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'paymentSourceAccountType': string;

  /**  */
  'paymentSourceAccountNumberMasked': string;

  /**  */
  'autopayActivityLogMetaJson': string;

  /**  */
  'paymentDetail': PaymentDetailDto;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['dateToProcess'] = data['dateToProcess'];
    this['dateToProcessLocal'] = data['dateToProcessLocal'];
    this['schedulingType'] = data['schedulingType'];
    this['schedulingStatus'] = data['schedulingStatus'];
    this['schedulingMessage'] = data['schedulingMessage'];
    this['idempotencyKey'] = data['idempotencyKey'];
    this['customerNotified'] = data['customerNotified'];
    this['paymentId'] = data['paymentId'];
    this['autopaySettingsId'] = data['autopaySettingsId'];
    this['id'] = data['id'];
    this['customerName'] = data['customerName'];
    this['customerEmail'] = data['customerEmail'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['paymentSourceAccountType'] = data['paymentSourceAccountType'];
    this['paymentSourceAccountNumberMasked'] = data['paymentSourceAccountNumberMasked'];
    this['autopayActivityLogMetaJson'] = data['autopayActivityLogMetaJson'];
    this['paymentDetail'] = data['paymentDetail'];
  }
}

export class ScheduledPaymentSummaryDto {
  /**  */
  'id': number;

  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'dateToProcess': Date;

  /**  */
  'dateToProcessLocal': string;

  /**  */
  'scheduledPaymentType': string;

  /**  */
  'schedulingStatus': string;

  /**  */
  'schedulingMessage': string;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'customerNotified': boolean;

  /**  */
  'paymentId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'customerName': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'enrollmentType': string;

  /**  */
  'accountBalanceMinor': number;

  /**  */
  'accountDueDate': Date;

  /**  */
  'accountSyncDate': Date;

  /**  */
  'paymentSourceType': string;

  /**  */
  'paymentSourceAccountNumberMasked': string;

  /**  */
  'paymentStatus': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['dateToProcess'] = data['dateToProcess'];
    this['dateToProcessLocal'] = data['dateToProcessLocal'];
    this['scheduledPaymentType'] = data['scheduledPaymentType'];
    this['schedulingStatus'] = data['schedulingStatus'];
    this['schedulingMessage'] = data['schedulingMessage'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['customerNotified'] = data['customerNotified'];
    this['paymentId'] = data['paymentId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['customerName'] = data['customerName'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['enrollmentType'] = data['enrollmentType'];
    this['accountBalanceMinor'] = data['accountBalanceMinor'];
    this['accountDueDate'] = data['accountDueDate'];
    this['accountSyncDate'] = data['accountSyncDate'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['paymentSourceAccountNumberMasked'] = data['paymentSourceAccountNumberMasked'];
    this['paymentStatus'] = data['paymentStatus'];
  }
}

export class ScheduledPaymentsStats {
  /**  */
  'stagedCount': number;

  constructor(data: undefined | any = {}) {
    this['stagedCount'] = data['stagedCount'];
  }
}

export class SearchResponse {
  /**  */
  'accountSummaries': AccountSearchDto[];

  /**  */
  'paymentSummaries': PaymentSearchDto[];

  /**  */
  'customerSummaries': CustomerSearchDto[];

  constructor(data: undefined | any = {}) {
    this['accountSummaries'] = data['accountSummaries'];
    this['paymentSummaries'] = data['paymentSummaries'];
    this['customerSummaries'] = data['customerSummaries'];
  }
}

export class SendClientEmailRequest {
  /**  */
  'message': string;

  /**  */
  'ticketId': number;

  constructor(data: undefined | any = {}) {
    this['message'] = data['message'];
    this['ticketId'] = data['ticketId'];
  }
}

export class SendEmail_Config {
  /**  */
  'toEmail': string;

  /**  */
  'emailDataHeader': string;

  /**  */
  'emailDataSubject': string;

  /**  */
  'emailDataBody': string;

  /**  */
  'emailDataLinkUrl': string;

  /**  */
  'emailDataLinkText': string;

  constructor(data: undefined | any = {}) {
    this['toEmail'] = data['toEmail'];
    this['emailDataHeader'] = data['emailDataHeader'];
    this['emailDataSubject'] = data['emailDataSubject'];
    this['emailDataBody'] = data['emailDataBody'];
    this['emailDataLinkUrl'] = data['emailDataLinkUrl'];
    this['emailDataLinkText'] = data['emailDataLinkText'];
  }
}

export class SendPaymentReceiptRequest {
  /**  */
  'emailAddress': string;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
  }
}

export class SendWebhook_Config {
  /**  */
  'url': string;

  /**  */
  'hmacSha1Secret': string;

  /**  */
  'payload': string;

  constructor(data: undefined | any = {}) {
    this['url'] = data['url'];
    this['hmacSha1Secret'] = data['hmacSha1Secret'];
    this['payload'] = data['payload'];
  }
}

export class ServiceFeeCalculation {
  /**  */
  'flatFee': number;

  /**  */
  'percentageFee': number;

  /**  */
  'totalFee': number;

  /**  */
  'tier': ServiceFeeTierGetDto;

  constructor(data: undefined | any = {}) {
    this['flatFee'] = data['flatFee'];
    this['percentageFee'] = data['percentageFee'];
    this['totalFee'] = data['totalFee'];
    this['tier'] = data['tier'];
  }
}

export class ServiceFeeCalculationDto {
  /**  */
  'tier': ServiceFeeTierGetDto;

  /**  */
  'amount': number;

  constructor(data: undefined | any = {}) {
    this['tier'] = data['tier'];
    this['amount'] = data['amount'];
  }
}

export class ServiceFeeTierGetDto {
  /**  */
  'paymentChannel': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'startRange': number;

  /**  */
  'endRange': number;

  /**  */
  'flatFee': number;

  /**  */
  'percentageFee': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['paymentChannel'] = data['paymentChannel'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['startRange'] = data['startRange'];
    this['endRange'] = data['endRange'];
    this['flatFee'] = data['flatFee'];
    this['percentageFee'] = data['percentageFee'];
    this['id'] = data['id'];
  }
}

export class ServiceFeeTierSyncDto {
  /**  */
  'paymentChannel': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'startRange': number;

  /**  */
  'endRange': number;

  /**  */
  'flatFee': number;

  /**  */
  'percentageFee': number;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['paymentChannel'] = data['paymentChannel'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['startRange'] = data['startRange'];
    this['endRange'] = data['endRange'];
    this['flatFee'] = data['flatFee'];
    this['percentageFee'] = data['percentageFee'];
    this['id'] = data['id'];
  }
}

export class SettingSummaryDto {
  /**  */
  'id': number;

  /**  */
  'key': string;

  /**  */
  'value': string;

  /**  */
  'type': string;

  /**  */
  'enum': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['key'] = data['key'];
    this['value'] = data['value'];
    this['type'] = data['type'];
    this['enum'] = data['enum'];
  }
}

export class SmsLogDetailDto {
  /**  */
  'messageIdentifier': string;

  /**  */
  'messageBody': string;

  /**  */
  'fromNumber': string;

  /**  */
  'recipientNumber': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'userId': number;

  /**  */
  'id': number;

  /**  */
  'createdDate': Date;

  constructor(data: undefined | any = {}) {
    this['messageIdentifier'] = data['messageIdentifier'];
    this['messageBody'] = data['messageBody'];
    this['fromNumber'] = data['fromNumber'];
    this['recipientNumber'] = data['recipientNumber'];
    this['businessUnitId'] = data['businessUnitId'];
    this['userId'] = data['userId'];
    this['id'] = data['id'];
    this['createdDate'] = data['createdDate'];
  }
}

export class StageImportedAccounts_Config {
  /**  */
  'blobUrl': string;

  /**  */
  'aggregateAccounts': boolean;

  /**  */
  'skippedAccountThreshold': number;

  /**  */
  'duplicateAccountStrategy': string;

  constructor(data: undefined | any = {}) {
    this['blobUrl'] = data['blobUrl'];
    this['aggregateAccounts'] = data['aggregateAccounts'];
    this['skippedAccountThreshold'] = data['skippedAccountThreshold'];
    this['duplicateAccountStrategy'] = data['duplicateAccountStrategy'];
  }
}

export class SubmitFormRequest {
  /**  */
  'valuesJson': string;

  /**  */
  'customerId': number;

  /**  */
  'accountId': number;

  /**  */
  'paymentSessionSessionIdentifier': string;

  /**  */
  'baseFormResponseId': number;

  /**  */
  'isDependentForm': boolean;

  /**  */
  'dependentFormResponses': DependentFormResponseDto[];

  constructor(data: undefined | any = {}) {
    this['valuesJson'] = data['valuesJson'];
    this['customerId'] = data['customerId'];
    this['accountId'] = data['accountId'];
    this['paymentSessionSessionIdentifier'] = data['paymentSessionSessionIdentifier'];
    this['baseFormResponseId'] = data['baseFormResponseId'];
    this['isDependentForm'] = data['isDependentForm'];
    this['dependentFormResponses'] = data['dependentFormResponses'];
  }
}

export class SubmitFormResponse {
  /**  */
  'formResponseIdentifier': string;

  /**  */
  'formConfigurationId': number;

  constructor(data: undefined | any = {}) {
    this['formResponseIdentifier'] = data['formResponseIdentifier'];
    this['formConfigurationId'] = data['formConfigurationId'];
  }
}

export class SyncStagedAccounts_Config {
  /**  */
  'importSource': string;

  /**  */
  'missingAccountStrategy': string;

  /**  */
  'overrideBadUploadProtection': boolean;

  /**  */
  'optimizeSync': boolean;

  /**  */
  'minimumRecordCount': number;

  /**  */
  'updateConfiguration': SyncStagedAccounts_UpdateConfiguration;

  constructor(data: undefined | any = {}) {
    this['importSource'] = data['importSource'];
    this['missingAccountStrategy'] = data['missingAccountStrategy'];
    this['overrideBadUploadProtection'] = data['overrideBadUploadProtection'];
    this['optimizeSync'] = data['optimizeSync'];
    this['minimumRecordCount'] = data['minimumRecordCount'];
    this['updateConfiguration'] = data['updateConfiguration'];
  }
}

export class SyncStagedAccounts_UpdateConfiguration {
  /**  */
  'skippedFields': string[];

  /**  */
  'includedFields': string[];

  constructor(data: undefined | any = {}) {
    this['skippedFields'] = data['skippedFields'];
    this['includedFields'] = data['includedFields'];
  }
}

export class SynchronizeServiceFeeTiersRequest {
  /**  */
  'reasonForConfigChange': string;

  /**  */
  'removeAllServiceFees': boolean;

  /**  */
  'serviceFeeTiers': ServiceFeeTierSyncDto[];

  constructor(data: undefined | any = {}) {
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
    this['removeAllServiceFees'] = data['removeAllServiceFees'];
    this['serviceFeeTiers'] = data['serviceFeeTiers'];
  }
}

export class TermsOfServiceDetailDto {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'acknowledgementType': string;

  /**  */
  'identifier': string;

  /**  */
  'description': string;

  /**  */
  'terms': string;

  /**  */
  'version': number;

  /**  */
  'isDraft': boolean;

  /**  */
  'isDefault': boolean;

  /**  */
  'publishedByUserName': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'lastModifiedByUserName': string;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'publishedDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['acknowledgementType'] = data['acknowledgementType'];
    this['identifier'] = data['identifier'];
    this['description'] = data['description'];
    this['terms'] = data['terms'];
    this['version'] = data['version'];
    this['isDraft'] = data['isDraft'];
    this['isDefault'] = data['isDefault'];
    this['publishedByUserName'] = data['publishedByUserName'];
    this['createdByUserName'] = data['createdByUserName'];
    this['lastModifiedByUserName'] = data['lastModifiedByUserName'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['publishedDate'] = data['publishedDate'];
  }
}

export class TermsOfServiceGetDto {
  /**  */
  'acknowledgementType': string;

  /**  */
  'identifier': string;

  /**  */
  'description': string;

  /**  */
  'terms': string;

  /**  */
  'isDefault': boolean;

  /**  */
  'businessUnitId': number;

  /**  */
  'id': number;

  /**  */
  'version': number;

  /**  */
  'isDraft': boolean;

  constructor(data: undefined | any = {}) {
    this['acknowledgementType'] = data['acknowledgementType'];
    this['identifier'] = data['identifier'];
    this['description'] = data['description'];
    this['terms'] = data['terms'];
    this['isDefault'] = data['isDefault'];
    this['businessUnitId'] = data['businessUnitId'];
    this['id'] = data['id'];
    this['version'] = data['version'];
    this['isDraft'] = data['isDraft'];
  }
}

export class TicketCreatedEventPayload {
  /**  */
  'title': string;

  /**  */
  'assignedUser': string;

  /**  */
  'assignedUserEmail': string;

  /**  */
  'category': string;

  /**  */
  'description': string;

  /**  */
  'status': string;

  /**  */
  'businessUnitHeader': string;

  /**  */
  'businessUnitSubHeader': string;

  /**  */
  'ticketUrl': string;

  constructor(data: undefined | any = {}) {
    this['title'] = data['title'];
    this['assignedUser'] = data['assignedUser'];
    this['assignedUserEmail'] = data['assignedUserEmail'];
    this['category'] = data['category'];
    this['description'] = data['description'];
    this['status'] = data['status'];
    this['businessUnitHeader'] = data['businessUnitHeader'];
    this['businessUnitSubHeader'] = data['businessUnitSubHeader'];
    this['ticketUrl'] = data['ticketUrl'];
  }
}

export class TicketDetailDto {
  /**  */
  'title': string;

  /**  */
  'description': string;

  /**  */
  'category': string;

  /**  */
  'status': string;

  /**  */
  'metaJson': string;

  /**  */
  'assignedUserId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'jobId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'id': number;

  /**  */
  'businessUnitHeader': string;

  /**  */
  'businessUnitSubHeader': string;

  /**  */
  'businessUnitSlug': string;

  /**  */
  'assignedUser': UserGetDto;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'followerIds': number[];

  /**  */
  'paystarAccountManager': string;

  constructor(data: undefined | any = {}) {
    this['title'] = data['title'];
    this['description'] = data['description'];
    this['category'] = data['category'];
    this['status'] = data['status'];
    this['metaJson'] = data['metaJson'];
    this['assignedUserId'] = data['assignedUserId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['jobId'] = data['jobId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['id'] = data['id'];
    this['businessUnitHeader'] = data['businessUnitHeader'];
    this['businessUnitSubHeader'] = data['businessUnitSubHeader'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['assignedUser'] = data['assignedUser'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['followerIds'] = data['followerIds'];
    this['paystarAccountManager'] = data['paystarAccountManager'];
  }
}

export class TicketEventGetDto {
  /**  */
  'ticketId': number;

  /**  */
  'eventType': string;

  /**  */
  'text': string;

  /**  */
  'metaJson': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['ticketId'] = data['ticketId'];
    this['eventType'] = data['eventType'];
    this['text'] = data['text'];
    this['metaJson'] = data['metaJson'];
    this['id'] = data['id'];
  }
}

export class TicketEventSummaryDto {
  /**  */
  'id': number;

  /**  */
  'eventType': string;

  /**  */
  'text': string;

  /**  */
  'metaJson': string;

  /**  */
  'createdDate': Date;

  /**  */
  'createdByUserName': string;

  /**  */
  'createdByUserRole': string;

  /**  */
  'ticketId': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['eventType'] = data['eventType'];
    this['text'] = data['text'];
    this['metaJson'] = data['metaJson'];
    this['createdDate'] = data['createdDate'];
    this['createdByUserName'] = data['createdByUserName'];
    this['createdByUserRole'] = data['createdByUserRole'];
    this['ticketId'] = data['ticketId'];
  }
}

export class TicketGetDto {
  /**  */
  'title': string;

  /**  */
  'description': string;

  /**  */
  'category': string;

  /**  */
  'status': string;

  /**  */
  'metaJson': string;

  /**  */
  'assignedUserId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'jobId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['title'] = data['title'];
    this['description'] = data['description'];
    this['category'] = data['category'];
    this['status'] = data['status'];
    this['metaJson'] = data['metaJson'];
    this['assignedUserId'] = data['assignedUserId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['jobId'] = data['jobId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['id'] = data['id'];
  }
}

export class TicketSummaryDto {
  /**  */
  'id': number;

  /**  */
  'title': string;

  /**  */
  'description': string;

  /**  */
  'businessUnit': string;

  /**  */
  'organization': string;

  /**  */
  'category': string;

  /**  */
  'status': string;

  /**  */
  'modifiedDate': Date;

  /**  */
  'createdDate': Date;

  /**  */
  'assignedUserName': string;

  /**  */
  'paystarAccountManager': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['title'] = data['title'];
    this['description'] = data['description'];
    this['businessUnit'] = data['businessUnit'];
    this['organization'] = data['organization'];
    this['category'] = data['category'];
    this['status'] = data['status'];
    this['modifiedDate'] = data['modifiedDate'];
    this['createdDate'] = data['createdDate'];
    this['assignedUserName'] = data['assignedUserName'];
    this['paystarAccountManager'] = data['paystarAccountManager'];
  }
}

export class TimeSpan {
  /**  */
  'ticks': number;

  /**  */
  'days': number;

  /**  */
  'hours': number;

  /**  */
  'milliseconds': number;

  /**  */
  'minutes': number;

  /**  */
  'seconds': number;

  /**  */
  'totalDays': number;

  /**  */
  'totalHours': number;

  /**  */
  'totalMilliseconds': number;

  /**  */
  'totalMinutes': number;

  /**  */
  'totalSeconds': number;

  constructor(data: undefined | any = {}) {
    this['ticks'] = data['ticks'];
    this['days'] = data['days'];
    this['hours'] = data['hours'];
    this['milliseconds'] = data['milliseconds'];
    this['minutes'] = data['minutes'];
    this['seconds'] = data['seconds'];
    this['totalDays'] = data['totalDays'];
    this['totalHours'] = data['totalHours'];
    this['totalMilliseconds'] = data['totalMilliseconds'];
    this['totalMinutes'] = data['totalMinutes'];
    this['totalSeconds'] = data['totalSeconds'];
  }
}

export class TokenizerConfig {
  /**  */
  'strategy': string;

  /**  */
  'properties': object;

  constructor(data: undefined | any = {}) {
    this['strategy'] = data['strategy'];
    this['properties'] = data['properties'];
  }
}

export class TransactionBatchAggregate {
  /**  */
  'merchantConfigId': number;

  /**  */
  'transactionBatchStartInclusive': Date;

  /**  */
  'transactionBatchEndExclusive': Date;

  /**  */
  'transactionCount': number;

  /**  */
  'paymentCount': number;

  /**  */
  'transactionNetMinor': number;

  /**  */
  'transactionSalesMinor': number;

  /**  */
  'transactionRefundsMinor': number;

  /**  */
  'merchantConfigName': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'paymentSourceType': string;

  constructor(data: undefined | any = {}) {
    this['merchantConfigId'] = data['merchantConfigId'];
    this['transactionBatchStartInclusive'] = data['transactionBatchStartInclusive'];
    this['transactionBatchEndExclusive'] = data['transactionBatchEndExclusive'];
    this['transactionCount'] = data['transactionCount'];
    this['paymentCount'] = data['paymentCount'];
    this['transactionNetMinor'] = data['transactionNetMinor'];
    this['transactionSalesMinor'] = data['transactionSalesMinor'];
    this['transactionRefundsMinor'] = data['transactionRefundsMinor'];
    this['merchantConfigName'] = data['merchantConfigName'];
    this['merchantNumber'] = data['merchantNumber'];
    this['paymentSourceType'] = data['paymentSourceType'];
  }
}

export class TransactionBatchAggregateV2 {
  /**  */
  'businessUnitId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'processorId': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'batchIdentifier': string;

  /**  */
  'rawBatchIdentifier': string;

  /**  */
  'potentialMissedBatchClose': boolean;

  /**  */
  'transactionCount': number;

  /**  */
  'transactionNetMinor': number;

  /**  */
  'transactionSalesMinor': number;

  /**  */
  'transactionRefundsMinor': number;

  /**  */
  'firstTransaction': Date;

  /**  */
  'lastTransaction': Date;

  /**  */
  'expectedBatchSettlement': Date;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['merchantNumber'] = data['merchantNumber'];
    this['processorId'] = data['processorId'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['batchIdentifier'] = data['batchIdentifier'];
    this['rawBatchIdentifier'] = data['rawBatchIdentifier'];
    this['potentialMissedBatchClose'] = data['potentialMissedBatchClose'];
    this['transactionCount'] = data['transactionCount'];
    this['transactionNetMinor'] = data['transactionNetMinor'];
    this['transactionSalesMinor'] = data['transactionSalesMinor'];
    this['transactionRefundsMinor'] = data['transactionRefundsMinor'];
    this['firstTransaction'] = data['firstTransaction'];
    this['lastTransaction'] = data['lastTransaction'];
    this['expectedBatchSettlement'] = data['expectedBatchSettlement'];
  }
}

export class TransactionDetailDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountId': number;

  /**  */
  'paymentId': number;

  /**  */
  'merchantId': string;

  /**  */
  'expectedSettlementDate': Date;

  /**  */
  'authCode': string;

  /**  */
  'type': string;

  /**  */
  'status': string;

  /**  */
  'amount': Currency;

  /**  */
  'referenceNumber': string;

  /**  */
  'batchIdentifier': string;

  /**  */
  'errorMessage': string;

  /**  */
  'gatewayResponseCode': string;

  /**  */
  'gatewayResponseDescription': string;

  /**  */
  'success': boolean;

  /**  */
  'adjustmentId': number;

  /**  */
  'latestExportTime': Date;

  /**  */
  'latestExportIdentifier': string;

  /**  */
  'id': number;

  /**  */
  'hasError': boolean;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'createdDate': Date;

  /**  */
  'adjustmentCreatedByUserEmail': string;

  /**  */
  'canVoid': boolean;

  /**  */
  'canRefund': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountId'] = data['accountId'];
    this['paymentId'] = data['paymentId'];
    this['merchantId'] = data['merchantId'];
    this['expectedSettlementDate'] = data['expectedSettlementDate'];
    this['authCode'] = data['authCode'];
    this['type'] = data['type'];
    this['status'] = data['status'];
    this['amount'] = data['amount'];
    this['referenceNumber'] = data['referenceNumber'];
    this['batchIdentifier'] = data['batchIdentifier'];
    this['errorMessage'] = data['errorMessage'];
    this['gatewayResponseCode'] = data['gatewayResponseCode'];
    this['gatewayResponseDescription'] = data['gatewayResponseDescription'];
    this['success'] = data['success'];
    this['adjustmentId'] = data['adjustmentId'];
    this['latestExportTime'] = data['latestExportTime'];
    this['latestExportIdentifier'] = data['latestExportIdentifier'];
    this['id'] = data['id'];
    this['hasError'] = data['hasError'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['createdDate'] = data['createdDate'];
    this['adjustmentCreatedByUserEmail'] = data['adjustmentCreatedByUserEmail'];
    this['canVoid'] = data['canVoid'];
    this['canRefund'] = data['canRefund'];
  }
}

export class TransactionFundingActivityDetailDto {
  /**  */
  'id': number;

  /**  */
  'gatewayFundingActivityId': string;

  /**  */
  'gatewayFundingGroupId': string;

  /**  */
  'paymentGatewayType': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'transactionId': number;

  /**  */
  'paymentId': number;

  /**  */
  'activityType': string;

  /**  */
  'activityDate': string;

  /**  */
  'amount': string;

  /**  */
  'activityStatus': string;

  /**  */
  'activityJson': string;

  /**  */
  'payment': PaymentSummaryDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['gatewayFundingActivityId'] = data['gatewayFundingActivityId'];
    this['gatewayFundingGroupId'] = data['gatewayFundingGroupId'];
    this['paymentGatewayType'] = data['paymentGatewayType'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['merchantNumber'] = data['merchantNumber'];
    this['referenceNumber'] = data['referenceNumber'];
    this['transactionId'] = data['transactionId'];
    this['paymentId'] = data['paymentId'];
    this['activityType'] = data['activityType'];
    this['activityDate'] = data['activityDate'];
    this['amount'] = data['amount'];
    this['activityStatus'] = data['activityStatus'];
    this['activityJson'] = data['activityJson'];
    this['payment'] = data['payment'];
  }
}

export class TransactionFundingActivityGetDto {
  /**  */
  'fundingDate': string;

  /**  */
  'gatewayFundingActivityId': string;

  /**  */
  'gatewayFundingGroupId': string;

  /**  */
  'paymentGatewayType': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'merchantNumber': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'transactionId': number;

  /**  */
  'paymentId': number;

  /**  */
  'activityType': string;

  /**  */
  'activityDate': string;

  /**  */
  'amount': string;

  /**  */
  'activityStatus': string;

  /**  */
  'authDate': string;

  /**  */
  'achReturnCode': string;

  /**  */
  'batchId': string;

  /**  */
  'activityJson': string;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['fundingDate'] = data['fundingDate'];
    this['gatewayFundingActivityId'] = data['gatewayFundingActivityId'];
    this['gatewayFundingGroupId'] = data['gatewayFundingGroupId'];
    this['paymentGatewayType'] = data['paymentGatewayType'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['merchantNumber'] = data['merchantNumber'];
    this['referenceNumber'] = data['referenceNumber'];
    this['transactionId'] = data['transactionId'];
    this['paymentId'] = data['paymentId'];
    this['activityType'] = data['activityType'];
    this['activityDate'] = data['activityDate'];
    this['amount'] = data['amount'];
    this['activityStatus'] = data['activityStatus'];
    this['authDate'] = data['authDate'];
    this['achReturnCode'] = data['achReturnCode'];
    this['batchId'] = data['batchId'];
    this['activityJson'] = data['activityJson'];
    this['id'] = data['id'];
  }
}

export class TransactionFundingEntryDto {
  /**  */
  'transactionFundingActivity': TransactionFundingActivityGetDto;

  /**  */
  'fundingEntry': FundingEntryGetDto;

  constructor(data: undefined | any = {}) {
    this['transactionFundingActivity'] = data['transactionFundingActivity'];
    this['fundingEntry'] = data['fundingEntry'];
  }
}

export class TransactionGetDto {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountId': number;

  /**  */
  'paymentId': number;

  /**  */
  'merchantId': string;

  /**  */
  'expectedSettlementDate': Date;

  /**  */
  'authCode': string;

  /**  */
  'type': string;

  /**  */
  'status': string;

  /**  */
  'amount': Currency;

  /**  */
  'referenceNumber': string;

  /**  */
  'batchIdentifier': string;

  /**  */
  'errorMessage': string;

  /**  */
  'gatewayResponseCode': string;

  /**  */
  'gatewayResponseDescription': string;

  /**  */
  'success': boolean;

  /**  */
  'adjustmentId': number;

  /**  */
  'latestExportTime': Date;

  /**  */
  'latestExportIdentifier': string;

  /**  */
  'id': number;

  /**  */
  'hasError': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountId'] = data['accountId'];
    this['paymentId'] = data['paymentId'];
    this['merchantId'] = data['merchantId'];
    this['expectedSettlementDate'] = data['expectedSettlementDate'];
    this['authCode'] = data['authCode'];
    this['type'] = data['type'];
    this['status'] = data['status'];
    this['amount'] = data['amount'];
    this['referenceNumber'] = data['referenceNumber'];
    this['batchIdentifier'] = data['batchIdentifier'];
    this['errorMessage'] = data['errorMessage'];
    this['gatewayResponseCode'] = data['gatewayResponseCode'];
    this['gatewayResponseDescription'] = data['gatewayResponseDescription'];
    this['success'] = data['success'];
    this['adjustmentId'] = data['adjustmentId'];
    this['latestExportTime'] = data['latestExportTime'];
    this['latestExportIdentifier'] = data['latestExportIdentifier'];
    this['id'] = data['id'];
    this['hasError'] = data['hasError'];
  }
}

export class TransactionMetricResponse {
  /**  */
  'businessUnitId': number;

  /**  */
  'transactionDateCst': Date;

  /**  */
  'transactionCount': number;

  /**  */
  'transactionsTotal': number;

  /**  */
  'paymentStatus': string;

  /**  */
  'currencyCode': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['transactionDateCst'] = data['transactionDateCst'];
    this['transactionCount'] = data['transactionCount'];
    this['transactionsTotal'] = data['transactionsTotal'];
    this['paymentStatus'] = data['paymentStatus'];
    this['currencyCode'] = data['currencyCode'];
  }
}

export class TransactionSummaryDto {
  /**  */
  'id': number;

  /**  */
  'authCode': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'createdByUserRole': string;

  /**  */
  'type': string;

  /**  */
  'status': string;

  /**  */
  'paymentStatus': string;

  /**  */
  'paymentId': number;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'channel': string;

  /**  */
  'amountMinor': number;

  /**  */
  'createdDate': Date;

  /**  */
  'gatewayResponseCode': string;

  /**  */
  'gatewayResponseDescription': string;

  /**  */
  'success': boolean;

  /**  */
  'errorMessage': string;

  /**  */
  'adjustmentId': number;

  /**  */
  'amount': Currency;

  /**  */
  'isEmbeddedPayment': boolean;

  /**  */
  'paymentSourceSnapshot': string;

  /**  */
  'paymentSourceJson': PaymentSourceJsonDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['authCode'] = data['authCode'];
    this['createdByUserName'] = data['createdByUserName'];
    this['createdByUserRole'] = data['createdByUserRole'];
    this['type'] = data['type'];
    this['status'] = data['status'];
    this['paymentStatus'] = data['paymentStatus'];
    this['paymentId'] = data['paymentId'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['referenceNumber'] = data['referenceNumber'];
    this['channel'] = data['channel'];
    this['amountMinor'] = data['amountMinor'];
    this['createdDate'] = data['createdDate'];
    this['gatewayResponseCode'] = data['gatewayResponseCode'];
    this['gatewayResponseDescription'] = data['gatewayResponseDescription'];
    this['success'] = data['success'];
    this['errorMessage'] = data['errorMessage'];
    this['adjustmentId'] = data['adjustmentId'];
    this['amount'] = data['amount'];
    this['isEmbeddedPayment'] = data['isEmbeddedPayment'];
    this['paymentSourceSnapshot'] = data['paymentSourceSnapshot'];
    this['paymentSourceJson'] = data['paymentSourceJson'];
  }
}

export class TransformContext_Config {
  /**  */
  'transformFunction': string;

  constructor(data: undefined | any = {}) {
    this['transformFunction'] = data['transformFunction'];
  }
}

export class TransformFile_Config {
  /**  */
  'blobUrl': string;

  /**  */
  'fieldMapConfiguration': string;

  /**  */
  'fieldOverrides': string;

  constructor(data: undefined | any = {}) {
    this['blobUrl'] = data['blobUrl'];
    this['fieldMapConfiguration'] = data['fieldMapConfiguration'];
    this['fieldOverrides'] = data['fieldOverrides'];
  }
}

export class TransformPreviewRequest {
  /**  */
  'transformType': string;

  /**  */
  'arguments': string;

  /**  */
  'inputs': string[];

  constructor(data: undefined | any = {}) {
    this['transformType'] = data['transformType'];
    this['arguments'] = data['arguments'];
    this['inputs'] = data['inputs'];
  }
}

export class UnfundedPaymentDto {
  /**  */
  'paymentId': number;

  /**  */
  'createdDate': Date;

  /**  */
  'paymentAmount': number;

  /**  */
  'accountId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'transactionReferenceNumber': string;

  /**  */
  'channel': string;

  /**  */
  'merchantAccountName': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'batchTime': TimeSpan;

  /**  */
  'timezone': string;

  /**  */
  'paymentStatus': string;

  constructor(data: undefined | any = {}) {
    this['paymentId'] = data['paymentId'];
    this['createdDate'] = data['createdDate'];
    this['paymentAmount'] = data['paymentAmount'];
    this['accountId'] = data['accountId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['merchantNumber'] = data['merchantNumber'];
    this['transactionReferenceNumber'] = data['transactionReferenceNumber'];
    this['channel'] = data['channel'];
    this['merchantAccountName'] = data['merchantAccountName'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['batchTime'] = data['batchTime'];
    this['timezone'] = data['timezone'];
    this['paymentStatus'] = data['paymentStatus'];
  }
}

export class UnlinkCustomerAccountAsAdminRequest {
  /**  */
  'customerId': number;

  /**  */
  'accountId': number;

  constructor(data: undefined | any = {}) {
    this['customerId'] = data['customerId'];
    this['accountId'] = data['accountId'];
  }
}

export class UnlinkCustomerAccountAsCustomerRequest {
  /**  */
  'accountId': number;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
  }
}

export class UpdateAccountFieldsRequest {
  /**  */
  'accountFields': AccountFieldUpdateDto[];

  constructor(data: undefined | any = {}) {
    this['accountFields'] = data['accountFields'];
  }
}

export class UpdateAccountNotesRequest {
  /**  */
  'notes': string;

  constructor(data: undefined | any = {}) {
    this['notes'] = data['notes'];
  }
}

export class UpdateAccountRequest {
  /**  */
  'businessUnitId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'balance': number;

  /**  */
  'lateFee': number;

  /**  */
  'pastDueAmountMinor': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'emailAddress': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'note': string;

  /**  */
  'meta': string;

  /**  */
  'createMethod': string;

  /**  */
  'allowOnlinePayment': boolean;

  /**  */
  'blockCreditCardPayment': boolean;

  /**  */
  'blockAchPayment': boolean;

  /**  */
  'paperlessBilling': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['pastDueAmountMinor'] = data['pastDueAmountMinor'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['phoneNumber'] = data['phoneNumber'];
    this['emailAddress'] = data['emailAddress'];
    this['address'] = data['address'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['note'] = data['note'];
    this['meta'] = data['meta'];
    this['createMethod'] = data['createMethod'];
    this['allowOnlinePayment'] = data['allowOnlinePayment'];
    this['blockCreditCardPayment'] = data['blockCreditCardPayment'];
    this['blockAchPayment'] = data['blockAchPayment'];
    this['paperlessBilling'] = data['paperlessBilling'];
  }
}

export class UpdateAgencyRequest {
  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
  }
}

export class UpdateBillingCycleConfigRequest {
  /**  */
  'identifier': string;

  /**  */
  'notificationStrategyType': string;

  /**  */
  'billType': string;

  /**  */
  'allowInserts': boolean;

  /**  */
  'isBillPrinted': boolean;

  /**  */
  'isDisabled': boolean;

  /**  */
  'executionOrder': number;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['notificationStrategyType'] = data['notificationStrategyType'];
    this['billType'] = data['billType'];
    this['allowInserts'] = data['allowInserts'];
    this['isBillPrinted'] = data['isBillPrinted'];
    this['isDisabled'] = data['isDisabled'];
    this['executionOrder'] = data['executionOrder'];
  }
}

export class UpdateBillingSystemRequest {
  /**  */
  'name': string;

  /**  */
  'notes': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['notes'] = data['notes'];
  }
}

export class UpdateBusinessUnitClientManagementSettingsRequest {
  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'reasonForConfigChange': string;

  constructor(data: undefined | any = {}) {
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
  }
}

export class UpdateBusinessUnitFeaturesRequest {
  /**  */
  'reasonForConfigChange': string;

  /**  */
  'features': BusinessUnitFeatures;

  constructor(data: undefined | any = {}) {
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
    this['features'] = data['features'];
  }
}

export class UpdateBusinessUnitRequest {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'address': Address;

  /**  */
  'phoneNumber': string;

  /**  */
  'ivrNumber': string;

  /**  */
  'websiteUrl': string;

  /**  */
  'logoUrl': string;

  /**  */
  'mobileLogoUrl': string;

  /**  */
  'accountHelpUrl': string;

  /**  */
  'billingAddressCollectionType': string;

  /**  */
  'currencyCode': string;

  /**  */
  'timezone': string;

  /**  */
  'notes': string;

  /**  */
  'primaryColor': string;

  /**  */
  'header': string;

  /**  */
  'subHeader': string;

  /**  */
  'billTemplateIdentifier': string;

  /**  */
  'pdfTemplateIdentifier': string;

  /**  */
  'excelTemplateIdentifier': string;

  /**  */
  'accountSyncStrategy': string;

  /**  */
  'accountSyncUrl': string;

  /**  */
  'accountSyncApiKey': string;

  /**  */
  'trustedDomains': string;

  /**  */
  'readingsIdentifier': string;

  /**  */
  'readingsSyncUrl': string;

  /**  */
  'readingsSyncApiKey': string;

  /**  */
  'syncPayments': boolean;

  /**  */
  'paymentSyncUrl': string;

  /**  */
  'paymentSyncSecret': string;

  /**  */
  'paymentSyncPayload': string;

  /**  */
  'paymentSyncOnSucceeded': boolean;

  /**  */
  'paymentSyncOnDeclined': boolean;

  /**  */
  'paymentSyncOnRefunded': boolean;

  /**  */
  'paymentSyncOnVoided': boolean;

  /**  */
  'quickPayConfigId': number;

  /**  */
  'ivrConfigId': number;

  /**  */
  'pointOfSaleConfigId': number;

  /**  */
  'autopayConfigId': number;

  /**  */
  'minPaymentAmount': number;

  /**  */
  'maxPaymentAmount': number;

  /**  */
  'maxRefundAmount': number;

  /**  */
  'agencyId': number;

  /**  */
  'paymentsDisabled': boolean;

  /**  */
  'testAccount': boolean;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'billingSystemId': number;

  /**  */
  'onboardingConfigurationId': number;

  /**  */
  'billingSystemVersion': string;

  /**  */
  'reasonForConfigChange': string;

  /**  */
  'payerLoginMode': string;

  /**  */
  'embeddedHost': string;

  /**  */
  'uploadAccountsDueDateDay': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['address'] = data['address'];
    this['phoneNumber'] = data['phoneNumber'];
    this['ivrNumber'] = data['ivrNumber'];
    this['websiteUrl'] = data['websiteUrl'];
    this['logoUrl'] = data['logoUrl'];
    this['mobileLogoUrl'] = data['mobileLogoUrl'];
    this['accountHelpUrl'] = data['accountHelpUrl'];
    this['billingAddressCollectionType'] = data['billingAddressCollectionType'];
    this['currencyCode'] = data['currencyCode'];
    this['timezone'] = data['timezone'];
    this['notes'] = data['notes'];
    this['primaryColor'] = data['primaryColor'];
    this['header'] = data['header'];
    this['subHeader'] = data['subHeader'];
    this['billTemplateIdentifier'] = data['billTemplateIdentifier'];
    this['pdfTemplateIdentifier'] = data['pdfTemplateIdentifier'];
    this['excelTemplateIdentifier'] = data['excelTemplateIdentifier'];
    this['accountSyncStrategy'] = data['accountSyncStrategy'];
    this['accountSyncUrl'] = data['accountSyncUrl'];
    this['accountSyncApiKey'] = data['accountSyncApiKey'];
    this['trustedDomains'] = data['trustedDomains'];
    this['readingsIdentifier'] = data['readingsIdentifier'];
    this['readingsSyncUrl'] = data['readingsSyncUrl'];
    this['readingsSyncApiKey'] = data['readingsSyncApiKey'];
    this['syncPayments'] = data['syncPayments'];
    this['paymentSyncUrl'] = data['paymentSyncUrl'];
    this['paymentSyncSecret'] = data['paymentSyncSecret'];
    this['paymentSyncPayload'] = data['paymentSyncPayload'];
    this['paymentSyncOnSucceeded'] = data['paymentSyncOnSucceeded'];
    this['paymentSyncOnDeclined'] = data['paymentSyncOnDeclined'];
    this['paymentSyncOnRefunded'] = data['paymentSyncOnRefunded'];
    this['paymentSyncOnVoided'] = data['paymentSyncOnVoided'];
    this['quickPayConfigId'] = data['quickPayConfigId'];
    this['ivrConfigId'] = data['ivrConfigId'];
    this['pointOfSaleConfigId'] = data['pointOfSaleConfigId'];
    this['autopayConfigId'] = data['autopayConfigId'];
    this['minPaymentAmount'] = data['minPaymentAmount'];
    this['maxPaymentAmount'] = data['maxPaymentAmount'];
    this['maxRefundAmount'] = data['maxRefundAmount'];
    this['agencyId'] = data['agencyId'];
    this['paymentsDisabled'] = data['paymentsDisabled'];
    this['testAccount'] = data['testAccount'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['billingSystemId'] = data['billingSystemId'];
    this['onboardingConfigurationId'] = data['onboardingConfigurationId'];
    this['billingSystemVersion'] = data['billingSystemVersion'];
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
    this['payerLoginMode'] = data['payerLoginMode'];
    this['embeddedHost'] = data['embeddedHost'];
    this['uploadAccountsDueDateDay'] = data['uploadAccountsDueDateDay'];
  }
}

export class UpdateCustomGatewayMessageRequest {
  /**  */
  'originalMessage': string;

  /**  */
  'replacementMessage': string;

  /**  */
  'transactionStatus': string;

  /**  */
  'gateway': string;

  constructor(data: undefined | any = {}) {
    this['originalMessage'] = data['originalMessage'];
    this['replacementMessage'] = data['replacementMessage'];
    this['transactionStatus'] = data['transactionStatus'];
    this['gateway'] = data['gateway'];
  }
}

export class UpdateCustomerRequest {
  /**  */
  'userId': number;

  /**  */
  'upcomingPaymentLeadTimeInDays': number;

  /**  */
  'notifyUpcomingPaymentEmail': boolean;

  /**  */
  'notifyUpcomingPaymentSms': boolean;

  /**  */
  'notifyNewBillEmail': boolean;

  /**  */
  'notifyNewBillSms': boolean;

  /**  */
  'isEmbedded': boolean;

  /**  */
  'id': number;

  constructor(data: undefined | any = {}) {
    this['userId'] = data['userId'];
    this['upcomingPaymentLeadTimeInDays'] = data['upcomingPaymentLeadTimeInDays'];
    this['notifyUpcomingPaymentEmail'] = data['notifyUpcomingPaymentEmail'];
    this['notifyUpcomingPaymentSms'] = data['notifyUpcomingPaymentSms'];
    this['notifyNewBillEmail'] = data['notifyNewBillEmail'];
    this['notifyNewBillSms'] = data['notifyNewBillSms'];
    this['isEmbedded'] = data['isEmbedded'];
    this['id'] = data['id'];
  }
}

export class UpdateDeviceRequest {
  /**  */
  'serialNumber': string;

  /**  */
  'model': string;

  /**  */
  'manufacturer': string;

  /**  */
  'tokenizationType': string;

  /**  */
  'notes': string;

  /**  */
  'quantity': number;

  /**  */
  'organizationId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'modifiedBy': string;

  /**  */
  'meta': string;

  /**  */
  'businessUnitIds': number[];

  constructor(data: undefined | any = {}) {
    this['serialNumber'] = data['serialNumber'];
    this['model'] = data['model'];
    this['manufacturer'] = data['manufacturer'];
    this['tokenizationType'] = data['tokenizationType'];
    this['notes'] = data['notes'];
    this['quantity'] = data['quantity'];
    this['organizationId'] = data['organizationId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['modifiedBy'] = data['modifiedBy'];
    this['meta'] = data['meta'];
    this['businessUnitIds'] = data['businessUnitIds'];
  }
}

export class UpdateExternalLinkConfigRequest {
  /**  */
  'identifier': string;

  /**  */
  'showOnCustomerDashboard': boolean;

  /**  */
  'payloadConfigurationJson': string;

  /**  */
  'url': string;

  /**  */
  'isGeneratedLink': boolean;

  /**  */
  'httpMethod': string;

  /**  */
  'display': string;

  /**  */
  'description': string;

  /**  */
  'functionTriggerId': number;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['showOnCustomerDashboard'] = data['showOnCustomerDashboard'];
    this['payloadConfigurationJson'] = data['payloadConfigurationJson'];
    this['url'] = data['url'];
    this['isGeneratedLink'] = data['isGeneratedLink'];
    this['httpMethod'] = data['httpMethod'];
    this['display'] = data['display'];
    this['description'] = data['description'];
    this['functionTriggerId'] = data['functionTriggerId'];
  }
}

export class UpdateInternalAccountNotesRequest {
  /**  */
  'accountId': number;

  /**  */
  'notes': string;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['notes'] = data['notes'];
  }
}

export class UpdateIvrPaymentIntentChargesRequest {
  /**  */
  'accountId': number;

  /**  */
  'paymentAmount': number;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['paymentAmount'] = data['paymentAmount'];
  }
}

export class UpdateIvrPaymentIntentPaymentSourceRequest {
  /**  */
  'accountType': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'achAccountNumber': string;

  /**  */
  'achRoutingNumber': string;

  /**  */
  'accountNumberToken': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'cardExpiration': Date;

  /**  */
  'cardEntryMethod': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'achConfirmAccountNumber': string;

  /**  */
  'nickname': string;

  /**  */
  'address': OptionalAddress;

  /**  */
  'accountNumberMasked': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['accountType'] = data['accountType'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['achAccountNumber'] = data['achAccountNumber'];
    this['achRoutingNumber'] = data['achRoutingNumber'];
    this['accountNumberToken'] = data['accountNumberToken'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['cardExpiration'] = data['cardExpiration'];
    this['cardEntryMethod'] = data['cardEntryMethod'];
    this['accountHolderName'] = data['accountHolderName'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['achConfirmAccountNumber'] = data['achConfirmAccountNumber'];
    this['nickname'] = data['nickname'];
    this['address'] = data['address'];
    this['accountNumberMasked'] = data['accountNumberMasked'];
    this['zipCode'] = data['zipCode'];
  }
}

export class UpdateJobRequest {
  /**  */
  'description': string;

  /**  */
  'disabled': boolean;

  /**  */
  'businessUnitId': number;

  /**  */
  'jobContextJson': string;

  /**  */
  'tags': string;

  /**  */
  'notes': string;

  /**  */
  'jobEventTriggers': string[];

  /**  */
  'jobTasks': JobTaskGetDto[];

  constructor(data: undefined | any = {}) {
    this['description'] = data['description'];
    this['disabled'] = data['disabled'];
    this['businessUnitId'] = data['businessUnitId'];
    this['jobContextJson'] = data['jobContextJson'];
    this['tags'] = data['tags'];
    this['notes'] = data['notes'];
    this['jobEventTriggers'] = data['jobEventTriggers'];
    this['jobTasks'] = data['jobTasks'];
  }
}

export class UpdateJobScheduleRequest {
  /**  */
  'jobId': number;

  /**  */
  'rRule': string;

  /**  */
  'timeZone': string;

  /**  */
  'nextExecutionTimeUtc': Date;

  /**  */
  'lastExecutionTimeUtc': Date;

  constructor(data: undefined | any = {}) {
    this['jobId'] = data['jobId'];
    this['rRule'] = data['rRule'];
    this['timeZone'] = data['timeZone'];
    this['nextExecutionTimeUtc'] = data['nextExecutionTimeUtc'];
    this['lastExecutionTimeUtc'] = data['lastExecutionTimeUtc'];
  }
}

export class UpdateJobTaskRequest {
  /**  */
  'jobId': number;

  /**  */
  'type': string;

  /**  */
  'description': string;

  /**  */
  'settingsJson': string;

  /**  */
  'executionOrder': number;

  /**  */
  'executionCondition': string;

  constructor(data: undefined | any = {}) {
    this['jobId'] = data['jobId'];
    this['type'] = data['type'];
    this['description'] = data['description'];
    this['settingsJson'] = data['settingsJson'];
    this['executionOrder'] = data['executionOrder'];
    this['executionCondition'] = data['executionCondition'];
  }
}

export class UpdateJobTemplateRequest {
  /**  */
  'name': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
  }
}

export class UpdateJobWebhookRequest {
  /**  */
  'regenerate': boolean;

  constructor(data: undefined | any = {}) {
    this['regenerate'] = data['regenerate'];
  }
}

export class UpdateMerchantAccountRequest {
  /**  */
  'name': string;

  /**  */
  'merchantNumber': string;

  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'currencyCode': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'monthlyProcessingLimit': number;

  /**  */
  'timezone': string;

  /**  */
  'batchTime': TimeSpan;

  /**  */
  'supportsSettlement': boolean;

  /**  */
  'supportsFunding': boolean;

  /**  */
  'feeOnly': boolean;

  /**  */
  'processorId': string;

  /**  */
  'notes': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'nachaConfigId': number;

  /**  */
  'tokenizerConfig': object;

  /**  */
  'acceptedCardBrands': AcceptedCardBrands;

  /**  */
  'organizationIds': number[];

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['merchantNumber'] = data['merchantNumber'];
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['currencyCode'] = data['currencyCode'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['monthlyProcessingLimit'] = data['monthlyProcessingLimit'];
    this['timezone'] = data['timezone'];
    this['batchTime'] = data['batchTime'];
    this['supportsSettlement'] = data['supportsSettlement'];
    this['supportsFunding'] = data['supportsFunding'];
    this['feeOnly'] = data['feeOnly'];
    this['processorId'] = data['processorId'];
    this['notes'] = data['notes'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['nachaConfigId'] = data['nachaConfigId'];
    this['tokenizerConfig'] = data['tokenizerConfig'];
    this['acceptedCardBrands'] = data['acceptedCardBrands'];
    this['organizationIds'] = data['organizationIds'];
  }
}

export class UpdateMessageRequest {
  /**  */
  'type': string;

  /**  */
  'messageBody': string;

  /**  */
  'organizationId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'paymentChannelType': string;

  /**  */
  'showOnLogin': boolean;

  /**  */
  'showOnPayment': boolean;

  /**  */
  'showOnConfirmation': boolean;

  /**  */
  'showOnAdminPortal': boolean;

  /**  */
  'showOnBackOfficeAutopayPayerModal': boolean;

  /**  */
  'showDate': Date;

  /**  */
  'removeDate': Date;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['messageBody'] = data['messageBody'];
    this['organizationId'] = data['organizationId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paymentChannelType'] = data['paymentChannelType'];
    this['showOnLogin'] = data['showOnLogin'];
    this['showOnPayment'] = data['showOnPayment'];
    this['showOnConfirmation'] = data['showOnConfirmation'];
    this['showOnAdminPortal'] = data['showOnAdminPortal'];
    this['showOnBackOfficeAutopayPayerModal'] = data['showOnBackOfficeAutopayPayerModal'];
    this['showDate'] = data['showDate'];
    this['removeDate'] = data['removeDate'];
  }
}

export class UpdateNachaConfigRequest {
  /**  */
  'name': string;

  /**  */
  'encryptionType': string;

  /**  */
  'immediateOrigin': string;

  /**  */
  'originatingCompanyId': string;

  /**  */
  'originatingCompanyName': string;

  /**  */
  'destinationBankRoutingNumber': string;

  /**  */
  'destinationBankName': string;

  /**  */
  'companyDiscretionaryData': string;

  /**  */
  'companyEntryDescription': string;

  /**  */
  'effectiveEntryDateCutoffTime': TimeSpan;

  /**  */
  'effectiveEntryDateCutoffTimezone': string;

  /**  */
  'secCodeOverride': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['encryptionType'] = data['encryptionType'];
    this['immediateOrigin'] = data['immediateOrigin'];
    this['originatingCompanyId'] = data['originatingCompanyId'];
    this['originatingCompanyName'] = data['originatingCompanyName'];
    this['destinationBankRoutingNumber'] = data['destinationBankRoutingNumber'];
    this['destinationBankName'] = data['destinationBankName'];
    this['companyDiscretionaryData'] = data['companyDiscretionaryData'];
    this['companyEntryDescription'] = data['companyEntryDescription'];
    this['effectiveEntryDateCutoffTime'] = data['effectiveEntryDateCutoffTime'];
    this['effectiveEntryDateCutoffTimezone'] = data['effectiveEntryDateCutoffTimezone'];
    this['secCodeOverride'] = data['secCodeOverride'];
  }
}

export class UpdateOrganizationRequest {
  /**  */
  'name': string;

  /**  */
  'slug': string;

  /**  */
  'agencyId': number;

  /**  */
  'notes': string;

  /**  */
  'paystarAccountManagerId': number;

  /**  */
  'paystarAccountManager': string;

  /**  */
  'hubSpotIdentifier': string;

  /**  */
  'hubSpotUrl': string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['slug'] = data['slug'];
    this['agencyId'] = data['agencyId'];
    this['notes'] = data['notes'];
    this['paystarAccountManagerId'] = data['paystarAccountManagerId'];
    this['paystarAccountManager'] = data['paystarAccountManager'];
    this['hubSpotIdentifier'] = data['hubSpotIdentifier'];
    this['hubSpotUrl'] = data['hubSpotUrl'];
  }
}

export class UpdatePaymentChannelRequest {
  /**  */
  'channelType': string;

  /**  */
  'enabled': boolean;

  /**  */
  'allowsFreeFormPayment': boolean;

  /**  */
  'requiresMinimumOfBalance': boolean;

  /**  */
  'businessUnitId': number;

  /**  */
  'creditCard': PaymentChannelSourceSummaryDto;

  /**  */
  'ach': PaymentChannelSourceSummaryDto;

  /**  */
  'reasonForConfigChange': string;

  constructor(data: undefined | any = {}) {
    this['channelType'] = data['channelType'];
    this['enabled'] = data['enabled'];
    this['allowsFreeFormPayment'] = data['allowsFreeFormPayment'];
    this['requiresMinimumOfBalance'] = data['requiresMinimumOfBalance'];
    this['businessUnitId'] = data['businessUnitId'];
    this['creditCard'] = data['creditCard'];
    this['ach'] = data['ach'];
    this['reasonForConfigChange'] = data['reasonForConfigChange'];
  }
}

export class UpdatePaymentFieldsRequest {
  /**  */
  'paymentFields': PaymentFieldGetDto[];

  constructor(data: undefined | any = {}) {
    this['paymentFields'] = data['paymentFields'];
  }
}

export class UpdatePaymentGatewayConfigPasswordRequest {
  /**  */
  'paymentGatewayConfigId': number;

  /**  */
  'password': string;

  constructor(data: undefined | any = {}) {
    this['paymentGatewayConfigId'] = data['paymentGatewayConfigId'];
    this['password'] = data['password'];
  }
}

export class UpdatePaymentGatewayConfigRequest {
  /**  */
  'paymentGatewayType': string;

  /**  */
  'name': string;

  /**  */
  'url': string;

  /**  */
  'username': string;

  /**  */
  'apiKey': string;

  /**  */
  'tokenizerConfigJson': string;

  /**  */
  'emvDelaySeconds': number;

  /**  */
  'password': string;

  constructor(data: undefined | any = {}) {
    this['paymentGatewayType'] = data['paymentGatewayType'];
    this['name'] = data['name'];
    this['url'] = data['url'];
    this['username'] = data['username'];
    this['apiKey'] = data['apiKey'];
    this['tokenizerConfigJson'] = data['tokenizerConfigJson'];
    this['emvDelaySeconds'] = data['emvDelaySeconds'];
    this['password'] = data['password'];
  }
}

export class UpdatePaymentIntentCardDataRequest {
  /**  */
  'cardCvv': string;

  constructor(data: undefined | any = {}) {
    this['cardCvv'] = data['cardCvv'];
  }
}

export class UpdatePaymentIntentChargesRequest {
  /**  */
  'charges': Charges;

  /**  */
  'scope': string;

  constructor(data: undefined | any = {}) {
    this['charges'] = data['charges'];
    this['scope'] = data['scope'];
  }
}

export class UpdatePaymentIntentFormRequest {
  /**  */
  'valuesJson': string;

  /**  */
  'formConfigurationId': number;

  /**  */
  'customerId': number;

  /**  */
  'accountId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'paymentSessionSessionIdentifier': string;

  /**  */
  'baseFormResponseId': number;

  constructor(data: undefined | any = {}) {
    this['valuesJson'] = data['valuesJson'];
    this['formConfigurationId'] = data['formConfigurationId'];
    this['customerId'] = data['customerId'];
    this['accountId'] = data['accountId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['paymentSessionSessionIdentifier'] = data['paymentSessionSessionIdentifier'];
    this['baseFormResponseId'] = data['baseFormResponseId'];
  }
}

export class UpdatePaymentIntentPaymentFieldsRequest {
  /**  */
  'paymentFields': object;

  /**  */
  'useMergeStrategy': boolean;

  /**  */
  'scope': string;

  constructor(data: undefined | any = {}) {
    this['paymentFields'] = data['paymentFields'];
    this['useMergeStrategy'] = data['useMergeStrategy'];
    this['scope'] = data['scope'];
  }
}

export class UpdatePaymentIntentPaymentSourceRequest {
  /**  */
  'paymentSource': PaymentIntentPaymentSource;

  constructor(data: undefined | any = {}) {
    this['paymentSource'] = data['paymentSource'];
  }
}

export class UpdatePaymentSessionPaymentSourceRequest {
  /**  */
  'sessionIdentifier': string;

  /**  */
  'emailAddress': string;

  /**  */
  'achConfirmAccountNumber': string;

  /**  */
  'savedPaymentSourceId': number;

  /**  */
  'savePaymentSource': boolean;

  /**  */
  'saveAccountPaymentSource': boolean;

  /**  */
  'cardEntryMethod': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'accountHolderName': string;

  /**  */
  'accountNumberToken': string;

  /**  */
  'accountType': string;

  /**  */
  'streetAddress': string;

  /**  */
  'streetAddress2': string;

  /**  */
  'city': string;

  /**  */
  'state': string;

  /**  */
  'zipCode': string;

  /**  */
  'cardExpirationMonth': string;

  /**  */
  'cardExpirationYear': string;

  /**  */
  'achAccountHolderType': string;

  /**  */
  'achAccountNumber': string;

  /**  */
  'achRoutingNumber': string;

  constructor(data: undefined | any = {}) {
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['emailAddress'] = data['emailAddress'];
    this['achConfirmAccountNumber'] = data['achConfirmAccountNumber'];
    this['savedPaymentSourceId'] = data['savedPaymentSourceId'];
    this['savePaymentSource'] = data['savePaymentSource'];
    this['saveAccountPaymentSource'] = data['saveAccountPaymentSource'];
    this['cardEntryMethod'] = data['cardEntryMethod'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['accountHolderName'] = data['accountHolderName'];
    this['accountNumberToken'] = data['accountNumberToken'];
    this['accountType'] = data['accountType'];
    this['streetAddress'] = data['streetAddress'];
    this['streetAddress2'] = data['streetAddress2'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zipCode'] = data['zipCode'];
    this['cardExpirationMonth'] = data['cardExpirationMonth'];
    this['cardExpirationYear'] = data['cardExpirationYear'];
    this['achAccountHolderType'] = data['achAccountHolderType'];
    this['achAccountNumber'] = data['achAccountNumber'];
    this['achRoutingNumber'] = data['achRoutingNumber'];
  }
}

export class UpdateProductRequest {
  /**  */
  'businessUnitId': number;

  /**  */
  'name': string;

  /**  */
  'description': string;

  /**  */
  'sku': string;

  /**  */
  'price': number;

  /**  */
  'allowPriceModification': boolean;

  /**  */
  'active': boolean;

  /**  */
  'required': boolean;

  /**  */
  'paymentChannel': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['sku'] = data['sku'];
    this['price'] = data['price'];
    this['allowPriceModification'] = data['allowPriceModification'];
    this['active'] = data['active'];
    this['required'] = data['required'];
    this['paymentChannel'] = data['paymentChannel'];
  }
}

export class UpdateSettingRequest {
  /**  */
  'value': string;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
  }
}

export class UpdateTermsOfServiceRequest {
  /**  */
  'acknowledgementType': string;

  /**  */
  'identifier': string;

  /**  */
  'description': string;

  /**  */
  'terms': string;

  /**  */
  'isDefault': boolean;

  /**  */
  'businessUnitId': number;

  constructor(data: undefined | any = {}) {
    this['acknowledgementType'] = data['acknowledgementType'];
    this['identifier'] = data['identifier'];
    this['description'] = data['description'];
    this['terms'] = data['terms'];
    this['isDefault'] = data['isDefault'];
    this['businessUnitId'] = data['businessUnitId'];
  }
}

export class UpdateTicketRequest {
  /**  */
  'title': string;

  /**  */
  'description': string;

  /**  */
  'category': string;

  /**  */
  'status': string;

  /**  */
  'metaJson': string;

  /**  */
  'assignedUserId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'jobId': number;

  /**  */
  'referenceNumber': string;

  /**  */
  'followerIds': number[];

  /**  */
  'ticketBefore': TicketGetDto;

  constructor(data: undefined | any = {}) {
    this['title'] = data['title'];
    this['description'] = data['description'];
    this['category'] = data['category'];
    this['status'] = data['status'];
    this['metaJson'] = data['metaJson'];
    this['assignedUserId'] = data['assignedUserId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['jobId'] = data['jobId'];
    this['referenceNumber'] = data['referenceNumber'];
    this['followerIds'] = data['followerIds'];
    this['ticketBefore'] = data['ticketBefore'];
  }
}

export class UpdateUserPasswordRequest {
  /**  */
  'confirmNewPassword': string;

  /**  */
  'currentPassword': string;

  /**  */
  'newPassword': string;

  constructor(data: undefined | any = {}) {
    this['confirmNewPassword'] = data['confirmNewPassword'];
    this['currentPassword'] = data['currentPassword'];
    this['newPassword'] = data['newPassword'];
  }
}

export class UpdateUserRequest {
  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'accountId': number;

  /**  */
  'organizationId': number;

  /**  */
  'customerId': number;

  /**  */
  'role': string;

  /**  */
  'isDeleted': boolean;

  /**  */
  'phoneNumber': string;

  /**  */
  'claims': string[];

  /**  */
  'organizationIdRequired': boolean;

  /**  */
  'accountIdRequired': boolean;

  /**  */
  'businessUnitIds': number[];

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['accountId'] = data['accountId'];
    this['organizationId'] = data['organizationId'];
    this['customerId'] = data['customerId'];
    this['role'] = data['role'];
    this['isDeleted'] = data['isDeleted'];
    this['phoneNumber'] = data['phoneNumber'];
    this['claims'] = data['claims'];
    this['organizationIdRequired'] = data['organizationIdRequired'];
    this['accountIdRequired'] = data['accountIdRequired'];
    this['businessUnitIds'] = data['businessUnitIds'];
  }
}

export class UpdatedEmbeddedPaymentSessionChargesRequest {
  /**  */
  'recaptchaToken': string;

  /**  */
  'charges': Charges;

  constructor(data: undefined | any = {}) {
    this['recaptchaToken'] = data['recaptchaToken'];
    this['charges'] = data['charges'];
  }
}

export class UpdatedEmbeddedPaymentSessionPaymentSourceRequest {
  /**  */
  'recaptchaToken': string;

  /**  */
  'paymentSource': NewPaymentSource;

  /**  */
  'savedPaymentSourceId': number;

  /**  */
  'savePaymentSource': boolean;

  constructor(data: undefined | any = {}) {
    this['recaptchaToken'] = data['recaptchaToken'];
    this['paymentSource'] = data['paymentSource'];
    this['savedPaymentSourceId'] = data['savedPaymentSourceId'];
    this['savePaymentSource'] = data['savePaymentSource'];
  }
}

export class UploadFileResponse {
  /**  */
  'uri': string;

  constructor(data: undefined | any = {}) {
    this['uri'] = data['uri'];
  }
}

export class UploadFileToFtp_Config {
  /**  */
  'directory': string;

  /**  */
  'blobUrl': string;

  /**  */
  'newFileName': string;

  constructor(data: undefined | any = {}) {
    this['directory'] = data['directory'];
    this['blobUrl'] = data['blobUrl'];
    this['newFileName'] = data['newFileName'];
  }
}

export class UsageMetricDto {
  /**  */
  'key': string;

  /**  */
  'unit': string;

  /**  */
  'readingType': string;

  /**  */
  'interval': string;

  /**  */
  'timestamp': Date;

  /**  */
  'value': number;

  constructor(data: undefined | any = {}) {
    this['key'] = data['key'];
    this['unit'] = data['unit'];
    this['readingType'] = data['readingType'];
    this['interval'] = data['interval'];
    this['timestamp'] = data['timestamp'];
    this['value'] = data['value'];
  }
}

export class UserDetailDto {
  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'accountId': number;

  /**  */
  'organizationId': number;

  /**  */
  'customerId': number;

  /**  */
  'role': string;

  /**  */
  'isDeleted': boolean;

  /**  */
  'id': number;

  /**  */
  'phoneNumber': string;

  /**  */
  'claims': string[];

  /**  */
  'isLockedOut': boolean;

  /**  */
  'businessUnitIds': number[];

  /**  */
  'environmentKey': string;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['accountId'] = data['accountId'];
    this['organizationId'] = data['organizationId'];
    this['customerId'] = data['customerId'];
    this['role'] = data['role'];
    this['isDeleted'] = data['isDeleted'];
    this['id'] = data['id'];
    this['phoneNumber'] = data['phoneNumber'];
    this['claims'] = data['claims'];
    this['isLockedOut'] = data['isLockedOut'];
    this['businessUnitIds'] = data['businessUnitIds'];
    this['environmentKey'] = data['environmentKey'];
  }
}

export class UserGetDto {
  /**  */
  'emailAddress': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'accountId': number;

  /**  */
  'organizationId': number;

  /**  */
  'customerId': number;

  /**  */
  'role': string;

  /**  */
  'isDeleted': boolean;

  /**  */
  'id': number;

  /**  */
  'phoneNumber': string;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['accountId'] = data['accountId'];
    this['organizationId'] = data['organizationId'];
    this['customerId'] = data['customerId'];
    this['role'] = data['role'];
    this['isDeleted'] = data['isDeleted'];
    this['id'] = data['id'];
    this['phoneNumber'] = data['phoneNumber'];
  }
}

export class UserOptionItemDto {
  /**  */
  'value': number;

  /**  */
  'text': string;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
    this['text'] = data['text'];
  }
}

export class UserSummaryDto {
  /**  */
  'id': number;

  /**  */
  'emailAddress': string;

  /**  */
  'phoneNumber': string;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'fullName': string;

  /**  */
  'role': string;

  /**  */
  'organizationName': string;

  /**  */
  'organizationId': number;

  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['emailAddress'] = data['emailAddress'];
    this['phoneNumber'] = data['phoneNumber'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['fullName'] = data['fullName'];
    this['role'] = data['role'];
    this['organizationName'] = data['organizationName'];
    this['organizationId'] = data['organizationId'];
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
  }
}

export class ValidateApiPaymentChannelUserRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'paymentChannelType': string;

  /**  */
  'lookupFieldEntries': object;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['paymentChannelType'] = data['paymentChannelType'];
    this['lookupFieldEntries'] = data['lookupFieldEntries'];
  }
}

export class VerifyCustomerPhoneRequest {
  /**  */
  'identifier': string;

  /**  */
  'code': string;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['code'] = data['code'];
  }
}

export class VerifyOnboardingAutopayEnrollmentRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'skipAutopayEnrollment': boolean;

  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'maximumBalanceAllowed': number;

  /**  */
  'termsAcknowledged': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['skipAutopayEnrollment'] = data['skipAutopayEnrollment'];
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['maximumBalanceAllowed'] = data['maximumBalanceAllowed'];
    this['termsAcknowledged'] = data['termsAcknowledged'];
  }
}

export class VerifyOnboardingAutopayUnenrollmentRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'termsAcknowledged': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['termsAcknowledged'] = data['termsAcknowledged'];
  }
}

export class VerifyOnboardingNotificationSettingsRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'skipNotificationSettings': boolean;

  /**  */
  'upcomingPaymentLeadTimeInDays': number;

  /**  */
  'notifyUpcomingPaymentEmail': boolean;

  /**  */
  'notifyUpcomingPaymentSms': boolean;

  /**  */
  'notifyNewBillEmail': boolean;

  /**  */
  'notifyNewBillSms': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['skipNotificationSettings'] = data['skipNotificationSettings'];
    this['upcomingPaymentLeadTimeInDays'] = data['upcomingPaymentLeadTimeInDays'];
    this['notifyUpcomingPaymentEmail'] = data['notifyUpcomingPaymentEmail'];
    this['notifyUpcomingPaymentSms'] = data['notifyUpcomingPaymentSms'];
    this['notifyNewBillEmail'] = data['notifyNewBillEmail'];
    this['notifyNewBillSms'] = data['notifyNewBillSms'];
  }
}

export class VerifyOnboardingPaperlessEnrollmentRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'skipPaperlessEnrollment': boolean;

  /**  */
  'emailAddress': string;

  /**  */
  'termsAcknowledged': boolean;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['skipPaperlessEnrollment'] = data['skipPaperlessEnrollment'];
    this['emailAddress'] = data['emailAddress'];
    this['termsAcknowledged'] = data['termsAcknowledged'];
  }
}

export class VerifyOnboardingPhoneNumberRequest {
  /**  */
  'businessUnitSlug': string;

  /**  */
  'identifier': string;

  /**  */
  'code': string;

  constructor(data: undefined | any = {}) {
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['identifier'] = data['identifier'];
    this['code'] = data['code'];
  }
}

export class ViewAutopayAccountSummary {
  /**  */
  'businessUnitId': number;

  /**  */
  'timezone': string;

  /**  */
  'mappedTimezone': string;

  /**  */
  'scheduleAutopayOnEarlyBilling': boolean;

  /**  */
  'accountId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'name': string;

  /**  */
  'uniqueAccountKey': string;

  /**  */
  'accountSyncDate': Date;

  /**  */
  'dueDate': Date;

  /**  */
  'autopaySchedulingDateComputed': Date;

  /**  */
  'autopayProcessingDateComputed': Date;

  /**  */
  'balance': number;

  /**  */
  'autopaySettingsId': number;

  /**  */
  'autopayCustomerId': number;

  /**  */
  'scheduledPaymentId': number;

  /**  */
  'scheduledPaymentAmountMinor': number;

  /**  */
  'scheduledDateToProcess': Date;

  /**  */
  'schedulingStatus': string;

  /**  */
  'schedulingMessage': string;

  /**  */
  'customerNotified': boolean;

  /**  */
  'id': number;

  /**  */
  'createdByUserId': number;

  /**  */
  'modifiedByUserId': number;

  /**  */
  'deletedByUserId': number;

  /**  */
  'isDeleted': boolean;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'deletedDate': Date;

  constructor(data: undefined | any = {}) {
    this['businessUnitId'] = data['businessUnitId'];
    this['timezone'] = data['timezone'];
    this['mappedTimezone'] = data['mappedTimezone'];
    this['scheduleAutopayOnEarlyBilling'] = data['scheduleAutopayOnEarlyBilling'];
    this['accountId'] = data['accountId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['name'] = data['name'];
    this['uniqueAccountKey'] = data['uniqueAccountKey'];
    this['accountSyncDate'] = data['accountSyncDate'];
    this['dueDate'] = data['dueDate'];
    this['autopaySchedulingDateComputed'] = data['autopaySchedulingDateComputed'];
    this['autopayProcessingDateComputed'] = data['autopayProcessingDateComputed'];
    this['balance'] = data['balance'];
    this['autopaySettingsId'] = data['autopaySettingsId'];
    this['autopayCustomerId'] = data['autopayCustomerId'];
    this['scheduledPaymentId'] = data['scheduledPaymentId'];
    this['scheduledPaymentAmountMinor'] = data['scheduledPaymentAmountMinor'];
    this['scheduledDateToProcess'] = data['scheduledDateToProcess'];
    this['schedulingStatus'] = data['schedulingStatus'];
    this['schedulingMessage'] = data['schedulingMessage'];
    this['customerNotified'] = data['customerNotified'];
    this['id'] = data['id'];
    this['createdByUserId'] = data['createdByUserId'];
    this['modifiedByUserId'] = data['modifiedByUserId'];
    this['deletedByUserId'] = data['deletedByUserId'];
    this['isDeleted'] = data['isDeleted'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['deletedDate'] = data['deletedDate'];
  }
}

export class ViewCustomerAccounts {
  /**  */
  'accountId': number;

  /**  */
  'customerId': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'emailAddress': string;

  /**  */
  'dueDate': Date;

  /**  */
  'issueDate': Date;

  /**  */
  'balance': number;

  /**  */
  'lateFee': number;

  /**  */
  'processingLeadTimeInDays': number;

  /**  */
  'paperlessBilling': boolean;

  /**  */
  'isArchived': boolean;

  /**  */
  'anyCustomerAccountEnrolledInAutopay': boolean;

  /**  */
  'id': number;

  /**  */
  'createdByUserId': number;

  /**  */
  'modifiedByUserId': number;

  /**  */
  'deletedByUserId': number;

  /**  */
  'isDeleted': boolean;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'deletedDate': Date;

  constructor(data: undefined | any = {}) {
    this['accountId'] = data['accountId'];
    this['customerId'] = data['customerId'];
    this['businessUnitId'] = data['businessUnitId'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['emailAddress'] = data['emailAddress'];
    this['dueDate'] = data['dueDate'];
    this['issueDate'] = data['issueDate'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['processingLeadTimeInDays'] = data['processingLeadTimeInDays'];
    this['paperlessBilling'] = data['paperlessBilling'];
    this['isArchived'] = data['isArchived'];
    this['anyCustomerAccountEnrolledInAutopay'] = data['anyCustomerAccountEnrolledInAutopay'];
    this['id'] = data['id'];
    this['createdByUserId'] = data['createdByUserId'];
    this['modifiedByUserId'] = data['modifiedByUserId'];
    this['deletedByUserId'] = data['deletedByUserId'];
    this['isDeleted'] = data['isDeleted'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['deletedDate'] = data['deletedDate'];
  }
}

export class ViewJobSummaryDto {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'latestJobExecutionId': number;

  /**  */
  'scheduleCount': number;

  /**  */
  'jobEventTriggerCount': number;

  /**  */
  'jobTasksBitMask': number;

  /**  */
  'jobTriggerBitMask': number;

  /**  */
  'display': string;

  /**  */
  'businessUnitName': string;

  /**  */
  'organizationName': string;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'identifier': string;

  /**  */
  'startTime': Date;

  /**  */
  'endTime': Date;

  /**  */
  'executionStatus': string;

  /**  */
  'pausedJobTaskIndex': number;

  /**  */
  'contextSnapshotJson': string;

  /**  */
  'description': string;

  /**  */
  'disabled': boolean;

  /**  */
  'tags': string;

  /**  */
  'webhookId': string;

  /**  */
  'notes': string;

  /**  */
  'taskDescriptions': string[];

  /**  */
  'createdByUserId': number;

  /**  */
  'modifiedByUserId': number;

  /**  */
  'deletedByUserId': number;

  /**  */
  'isDeleted': boolean;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'deletedDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['latestJobExecutionId'] = data['latestJobExecutionId'];
    this['scheduleCount'] = data['scheduleCount'];
    this['jobEventTriggerCount'] = data['jobEventTriggerCount'];
    this['jobTasksBitMask'] = data['jobTasksBitMask'];
    this['jobTriggerBitMask'] = data['jobTriggerBitMask'];
    this['display'] = data['display'];
    this['businessUnitName'] = data['businessUnitName'];
    this['organizationName'] = data['organizationName'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['identifier'] = data['identifier'];
    this['startTime'] = data['startTime'];
    this['endTime'] = data['endTime'];
    this['executionStatus'] = data['executionStatus'];
    this['pausedJobTaskIndex'] = data['pausedJobTaskIndex'];
    this['contextSnapshotJson'] = data['contextSnapshotJson'];
    this['description'] = data['description'];
    this['disabled'] = data['disabled'];
    this['tags'] = data['tags'];
    this['webhookId'] = data['webhookId'];
    this['notes'] = data['notes'];
    this['taskDescriptions'] = data['taskDescriptions'];
    this['createdByUserId'] = data['createdByUserId'];
    this['modifiedByUserId'] = data['modifiedByUserId'];
    this['deletedByUserId'] = data['deletedByUserId'];
    this['isDeleted'] = data['isDeleted'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['deletedDate'] = data['deletedDate'];
  }
}

export class ViewPaymentProcessedEvent {
  /**  */
  'sessionIdentifier': string;

  /**  */
  'businessUnitId': number;

  /**  */
  'businessUnitName': string;

  /**  */
  'businessUnitSlug': string;

  /**  */
  'businessUnitTimeZone': string;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'accountNumber': string;

  /**  */
  'subAccountNumber': string;

  /**  */
  'accountName': string;

  /**  */
  'balanceMinor': number;

  /**  */
  'lateFeeMinor': number;

  /**  */
  'balance': string;

  /**  */
  'lateFee': string;

  /**  */
  'dueDate': Date;

  /**  */
  'gatewayErrorMessage': string;

  /**  */
  'accountCreateMethod': string;

  /**  */
  'amountMinor': number;

  /**  */
  'currencyCode': string;

  /**  */
  'amount': number;

  /**  */
  'amountFormatted': string;

  /**  */
  'serviceFeeAmountMinor': number;

  /**  */
  'serviceFeeAmountFormatted': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'paymentCreatedDateUtc': Date;

  /**  */
  'channel': string;

  /**  */
  'referenceNumber': string;

  /**  */
  'status': string;

  /**  */
  'paymentType': string;

  /**  */
  'paymentSourceType': string;

  /**  */
  'accountNumberLast4': string;

  /**  */
  'accountType': string;

  /**  */
  'paymentMetaJson': string;

  constructor(data: undefined | any = {}) {
    this['sessionIdentifier'] = data['sessionIdentifier'];
    this['businessUnitId'] = data['businessUnitId'];
    this['businessUnitName'] = data['businessUnitName'];
    this['businessUnitSlug'] = data['businessUnitSlug'];
    this['businessUnitTimeZone'] = data['businessUnitTimeZone'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['accountNumber'] = data['accountNumber'];
    this['subAccountNumber'] = data['subAccountNumber'];
    this['accountName'] = data['accountName'];
    this['balanceMinor'] = data['balanceMinor'];
    this['lateFeeMinor'] = data['lateFeeMinor'];
    this['balance'] = data['balance'];
    this['lateFee'] = data['lateFee'];
    this['dueDate'] = data['dueDate'];
    this['gatewayErrorMessage'] = data['gatewayErrorMessage'];
    this['accountCreateMethod'] = data['accountCreateMethod'];
    this['amountMinor'] = data['amountMinor'];
    this['currencyCode'] = data['currencyCode'];
    this['amount'] = data['amount'];
    this['amountFormatted'] = data['amountFormatted'];
    this['serviceFeeAmountMinor'] = data['serviceFeeAmountMinor'];
    this['serviceFeeAmountFormatted'] = data['serviceFeeAmountFormatted'];
    this['createdByUserName'] = data['createdByUserName'];
    this['paymentCreatedDateUtc'] = data['paymentCreatedDateUtc'];
    this['channel'] = data['channel'];
    this['referenceNumber'] = data['referenceNumber'];
    this['status'] = data['status'];
    this['paymentType'] = data['paymentType'];
    this['paymentSourceType'] = data['paymentSourceType'];
    this['accountNumberLast4'] = data['accountNumberLast4'];
    this['accountType'] = data['accountType'];
    this['paymentMetaJson'] = data['paymentMetaJson'];
  }
}

export class ViewTermsOfService {
  /**  */
  'identifier': string;

  /**  */
  'description': string;

  /**  */
  'acknowledgementType': string;

  /**  */
  'newestPublishedVersion': number;

  /**  */
  'newestDraftVersion': number;

  /**  */
  'draftCount': number;

  /**  */
  'newerDraft': boolean;

  /**  */
  'businessUnitId': number;

  /**  */
  'id': number;

  /**  */
  'createdByUserId': number;

  /**  */
  'modifiedByUserId': number;

  /**  */
  'deletedByUserId': number;

  /**  */
  'isDeleted': boolean;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'deletedDate': Date;

  constructor(data: undefined | any = {}) {
    this['identifier'] = data['identifier'];
    this['description'] = data['description'];
    this['acknowledgementType'] = data['acknowledgementType'];
    this['newestPublishedVersion'] = data['newestPublishedVersion'];
    this['newestDraftVersion'] = data['newestDraftVersion'];
    this['draftCount'] = data['draftCount'];
    this['newerDraft'] = data['newerDraft'];
    this['businessUnitId'] = data['businessUnitId'];
    this['id'] = data['id'];
    this['createdByUserId'] = data['createdByUserId'];
    this['modifiedByUserId'] = data['modifiedByUserId'];
    this['deletedByUserId'] = data['deletedByUserId'];
    this['isDeleted'] = data['isDeleted'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['deletedDate'] = data['deletedDate'];
  }
}

export class ViewTransactionAggregate {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'organizationId': number;

  /**  */
  'paymentId': number;

  /**  */
  'accountId': number;

  /**  */
  'adjustmentId': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'paymentSessionId': number;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'paymentMetaJson': string;

  /**  */
  'createdDateLocal': string;

  /**  */
  'type': string;

  /**  */
  'status': string;

  /**  */
  'amountMinor': number;

  /**  */
  'currencyCode': string;

  /**  */
  'gatewayId': string;

  /**  */
  'errorMessage': string;

  /**  */
  'successful': boolean;

  /**  */
  'merchantNumber': string;

  /**  */
  'authCode': string;

  /**  */
  'batchIdentifier': string;

  /**  */
  'businessUnitDisplay': string;

  /**  */
  'businessUnitState': string;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'paymentCreatedDate': Date;

  /**  */
  'paymentCreatedDateLocal': string;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'paymentStatus': string;

  /**  */
  'paymentChannel': string;

  /**  */
  'paymentType': string;

  /**  */
  'paymentServiceFeeAmountMinor': number;

  /**  */
  'paymentSessionStatus': string;

  /**  */
  'paymentSourcePaymentSourceType': string;

  /**  */
  'paymentSourceNickname': string;

  /**  */
  'paymentSourceStreetAddress': string;

  /**  */
  'paymentSourceCity': string;

  /**  */
  'paymentSourceState': string;

  /**  */
  'paymentSourceZipCode': string;

  /**  */
  'paymentSourceAccountNumberMasked': string;

  /**  */
  'paymentSourceAccountType': string;

  /**  */
  'paymentSourceAchAccountHolderType': string;

  /**  */
  'paymentSourceAccountHolderName': string;

  /**  */
  'paymentSourceCardExpirationMonth': string;

  /**  */
  'paymentSourceCardExpirationYear': string;

  /**  */
  'accountCreateMethod': string;

  /**  */
  'accountAddressStreetAddress': string;

  /**  */
  'paymentSourceAccountNumberLast4': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'createdByUserRole': string;

  /**  */
  'transactionExported': boolean;

  /**  */
  'isEmbeddedPayment': boolean;

  /**  */
  'createdByUserId': number;

  /**  */
  'modifiedByUserId': number;

  /**  */
  'deletedByUserId': number;

  /**  */
  'isDeleted': boolean;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'deletedDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['organizationId'] = data['organizationId'];
    this['paymentId'] = data['paymentId'];
    this['accountId'] = data['accountId'];
    this['adjustmentId'] = data['adjustmentId'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['paymentSessionId'] = data['paymentSessionId'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['paymentMetaJson'] = data['paymentMetaJson'];
    this['createdDateLocal'] = data['createdDateLocal'];
    this['type'] = data['type'];
    this['status'] = data['status'];
    this['amountMinor'] = data['amountMinor'];
    this['currencyCode'] = data['currencyCode'];
    this['gatewayId'] = data['gatewayId'];
    this['errorMessage'] = data['errorMessage'];
    this['successful'] = data['successful'];
    this['merchantNumber'] = data['merchantNumber'];
    this['authCode'] = data['authCode'];
    this['batchIdentifier'] = data['batchIdentifier'];
    this['businessUnitDisplay'] = data['businessUnitDisplay'];
    this['businessUnitState'] = data['businessUnitState'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['paymentCreatedDate'] = data['paymentCreatedDate'];
    this['paymentCreatedDateLocal'] = data['paymentCreatedDateLocal'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['paymentStatus'] = data['paymentStatus'];
    this['paymentChannel'] = data['paymentChannel'];
    this['paymentType'] = data['paymentType'];
    this['paymentServiceFeeAmountMinor'] = data['paymentServiceFeeAmountMinor'];
    this['paymentSessionStatus'] = data['paymentSessionStatus'];
    this['paymentSourcePaymentSourceType'] = data['paymentSourcePaymentSourceType'];
    this['paymentSourceNickname'] = data['paymentSourceNickname'];
    this['paymentSourceStreetAddress'] = data['paymentSourceStreetAddress'];
    this['paymentSourceCity'] = data['paymentSourceCity'];
    this['paymentSourceState'] = data['paymentSourceState'];
    this['paymentSourceZipCode'] = data['paymentSourceZipCode'];
    this['paymentSourceAccountNumberMasked'] = data['paymentSourceAccountNumberMasked'];
    this['paymentSourceAccountType'] = data['paymentSourceAccountType'];
    this['paymentSourceAchAccountHolderType'] = data['paymentSourceAchAccountHolderType'];
    this['paymentSourceAccountHolderName'] = data['paymentSourceAccountHolderName'];
    this['paymentSourceCardExpirationMonth'] = data['paymentSourceCardExpirationMonth'];
    this['paymentSourceCardExpirationYear'] = data['paymentSourceCardExpirationYear'];
    this['accountCreateMethod'] = data['accountCreateMethod'];
    this['accountAddressStreetAddress'] = data['accountAddressStreetAddress'];
    this['paymentSourceAccountNumberLast4'] = data['paymentSourceAccountNumberLast4'];
    this['createdByUserName'] = data['createdByUserName'];
    this['createdByUserRole'] = data['createdByUserRole'];
    this['transactionExported'] = data['transactionExported'];
    this['isEmbeddedPayment'] = data['isEmbeddedPayment'];
    this['createdByUserId'] = data['createdByUserId'];
    this['modifiedByUserId'] = data['modifiedByUserId'];
    this['deletedByUserId'] = data['deletedByUserId'];
    this['isDeleted'] = data['isDeleted'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['deletedDate'] = data['deletedDate'];
  }
}

export class ViewTransactionSummary {
  /**  */
  'id': number;

  /**  */
  'businessUnitId': number;

  /**  */
  'organizationId': number;

  /**  */
  'paymentId': number;

  /**  */
  'accountId': number;

  /**  */
  'paymentSourceId': number;

  /**  */
  'merchantAccountId': number;

  /**  */
  'paymentSessionId': number;

  /**  */
  'adjustmentId': number;

  /**  */
  'paymentDescriptor': string;

  /**  */
  'createdDateLocal': string;

  /**  */
  'type': string;

  /**  */
  'status': string;

  /**  */
  'amountMinor': number;

  /**  */
  'currencyCode': string;

  /**  */
  'gatewayId': string;

  /**  */
  'successful': boolean;

  /**  */
  'authCode': string;

  /**  */
  'batchIdentifier': string;

  /**  */
  'businessUnitTimezone': string;

  /**  */
  'businessUnitDisplay': string;

  /**  */
  'businessUnitState': string;

  /**  */
  'includeServiceFeeInPayment': boolean;

  /**  */
  'paymentCreatedDateLocal': string;

  /**  */
  'paymentReferenceNumber': string;

  /**  */
  'paymentAmountMinor': number;

  /**  */
  'paymentStatus': string;

  /**  */
  'paymentChannel': string;

  /**  */
  'paymentType': string;

  /**  */
  'paymentSessionStatus': string;

  /**  */
  'paymentSourcePaymentSourceType': string;

  /**  */
  'paymentSourceAccountNumberMasked': string;

  /**  */
  'paymentSourceAccountType': string;

  /**  */
  'paymentSourceAccountHolderName': string;

  /**  */
  'paymentSourceNickname': string;

  /**  */
  'paymentSourceStreetAddress': string;

  /**  */
  'paymentSourceCity': string;

  /**  */
  'paymentSourceState': string;

  /**  */
  'paymentSourceZipCode': string;

  /**  */
  'accountCreateMethod': string;

  /**  */
  'accountAddressStreetAddress': string;

  /**  */
  'paymentSourceAccountNumberLast4': string;

  /**  */
  'createdByUserName': string;

  /**  */
  'createdByUserRole': string;

  /**  */
  'transactionExported': boolean;

  /**  */
  'isEmbeddedPayment': boolean;

  /**  */
  'createdByUserId': number;

  /**  */
  'modifiedByUserId': number;

  /**  */
  'deletedByUserId': number;

  /**  */
  'isDeleted': boolean;

  /**  */
  'createdDate': Date;

  /**  */
  'modifiedDate': Date;

  /**  */
  'deletedDate': Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['businessUnitId'] = data['businessUnitId'];
    this['organizationId'] = data['organizationId'];
    this['paymentId'] = data['paymentId'];
    this['accountId'] = data['accountId'];
    this['paymentSourceId'] = data['paymentSourceId'];
    this['merchantAccountId'] = data['merchantAccountId'];
    this['paymentSessionId'] = data['paymentSessionId'];
    this['adjustmentId'] = data['adjustmentId'];
    this['paymentDescriptor'] = data['paymentDescriptor'];
    this['createdDateLocal'] = data['createdDateLocal'];
    this['type'] = data['type'];
    this['status'] = data['status'];
    this['amountMinor'] = data['amountMinor'];
    this['currencyCode'] = data['currencyCode'];
    this['gatewayId'] = data['gatewayId'];
    this['successful'] = data['successful'];
    this['authCode'] = data['authCode'];
    this['batchIdentifier'] = data['batchIdentifier'];
    this['businessUnitTimezone'] = data['businessUnitTimezone'];
    this['businessUnitDisplay'] = data['businessUnitDisplay'];
    this['businessUnitState'] = data['businessUnitState'];
    this['includeServiceFeeInPayment'] = data['includeServiceFeeInPayment'];
    this['paymentCreatedDateLocal'] = data['paymentCreatedDateLocal'];
    this['paymentReferenceNumber'] = data['paymentReferenceNumber'];
    this['paymentAmountMinor'] = data['paymentAmountMinor'];
    this['paymentStatus'] = data['paymentStatus'];
    this['paymentChannel'] = data['paymentChannel'];
    this['paymentType'] = data['paymentType'];
    this['paymentSessionStatus'] = data['paymentSessionStatus'];
    this['paymentSourcePaymentSourceType'] = data['paymentSourcePaymentSourceType'];
    this['paymentSourceAccountNumberMasked'] = data['paymentSourceAccountNumberMasked'];
    this['paymentSourceAccountType'] = data['paymentSourceAccountType'];
    this['paymentSourceAccountHolderName'] = data['paymentSourceAccountHolderName'];
    this['paymentSourceNickname'] = data['paymentSourceNickname'];
    this['paymentSourceStreetAddress'] = data['paymentSourceStreetAddress'];
    this['paymentSourceCity'] = data['paymentSourceCity'];
    this['paymentSourceState'] = data['paymentSourceState'];
    this['paymentSourceZipCode'] = data['paymentSourceZipCode'];
    this['accountCreateMethod'] = data['accountCreateMethod'];
    this['accountAddressStreetAddress'] = data['accountAddressStreetAddress'];
    this['paymentSourceAccountNumberLast4'] = data['paymentSourceAccountNumberLast4'];
    this['createdByUserName'] = data['createdByUserName'];
    this['createdByUserRole'] = data['createdByUserRole'];
    this['transactionExported'] = data['transactionExported'];
    this['isEmbeddedPayment'] = data['isEmbeddedPayment'];
    this['createdByUserId'] = data['createdByUserId'];
    this['modifiedByUserId'] = data['modifiedByUserId'];
    this['deletedByUserId'] = data['deletedByUserId'];
    this['isDeleted'] = data['isDeleted'];
    this['createdDate'] = data['createdDate'];
    this['modifiedDate'] = data['modifiedDate'];
    this['deletedDate'] = data['deletedDate'];
  }
}

export class VoidPaymentRequest {
  /**  */
  'paymentId': number;

  /**  */
  'newBalance': number;

  /**  */
  'reason': string;

  constructor(data: undefined | any = {}) {
    this['paymentId'] = data['paymentId'];
    this['newBalance'] = data['newBalance'];
    this['reason'] = data['reason'];
  }
}
